import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActionArea,
  IconButton,
  useTheme,
  BoxProps,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";

interface ColumnFieldCollapsibleProps extends BoxProps {
  className?: string;
  columnValuesIdx: number;
  tableName: string | undefined;
  columnName: string | undefined;
  value: string | undefined;
  handleValue: (idx: number, value: string) => void;
  handleDuplicate: (idx: number, value: string) => void;
  handleDelete: (idx: number) => void;
}

const ColumnFieldCollapsible = ({
  tableName,
  className,
  columnName,
  value,
  columnValuesIdx,
  handleValue,
  handleDuplicate,
  handleDelete,
  sx,
  ...props
}: ColumnFieldCollapsibleProps): JSX.Element => {
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <Box {...(className && { className })} sx={{ width: "48%", mt: 2, ...sx }} {...props}>
      <Card
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.custom?.tabLineColor?.main,
          border: 1,
          borderColor: theme.custom?.lineColor?.main,
          padding: "8px",
        }}
      >
        <CardContent sx={{ height: "100%", py: 0 }}>
          <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: "40%",
              }}
            >
              <Typography variant="lineHeader" sx={{ textTransform: "uppercase", letterSpacing: "0.04em" }}>
                {tableName}
              </Typography>
              <Typography variant="backButton" noWrap={true} sx={{ fontSize: "14px" }}>
                {columnName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1vw",
              }}
            >
              <TextFieldData
                key="column-value"
                value={value}
                onChange={(val: string) => {
                  handleValue(columnValuesIdx, val);
                }}
                placeholder={t("placeholder.requested_value")}
              />
              <IconButton sx={{ padding: 0 }} onClick={() => handleDuplicate(columnValuesIdx, "")}>
                <AddCircleIcon
                  sx={{
                    color: theme.custom?.tertiary?.main,
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    border: 0,
                    height: "30px",
                    width: "auto",
                  }}
                  viewBox="3 3 18 18"
                />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton sx={{ padding: 0 }} onClick={() => handleDelete(columnValuesIdx)}>
                <CancelIcon
                  sx={{
                    color: "#ffffff",
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#7098A7",
                    borderRadius: "15px",
                    border: 0,
                    height: "30px",
                    width: "auto",
                  }}
                  viewBox="1 1 22 22"
                />
              </IconButton>
            </Box>
          </Grid>
        </CardContent>
        <CardActionArea />
      </Card>
    </Box>
  );
};

export default ColumnFieldCollapsible;
