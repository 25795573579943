import { Button, ButtonProps, Typography, useTheme } from "@mui/material";

interface IButtonProps extends ButtonProps {
  text?: string;
}

const TotalButton = (props: IButtonProps): JSX.Element => {
  const theme = useTheme();
  const { text, onClick, ...otherButtonsProps } = props;

  return (
    <Button
      sx={{
        height: "32px",
        borderRadius: "43px",
        background: "white",
        color: theme.palette?.primary?.main,
        border: 1,
        borderColor: "#7098A7",
        px: 2,
        boxShadow: "0px 6px 8px 0px rgba(183, 203, 211, 0.32), 0px 1px 1px 0px rgba(183, 203, 211, 0.80)",
      }}
      onClick={onClick}
      {...otherButtonsProps}
    >
      <Typography variant="button" noWrap={true}>
        {text}
      </Typography>
    </Button>
  );
};

export default TotalButton;
