import { Box, Card, CardContent, Grid, Collapse, Typography, Divider, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useState } from "react";
import { ScopeCollapsibleRoleItem } from "@domain/Interfaces/Data.d";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { RoleByDataset } from "@presentation/views/ViewScope.view";
import ExpandButton from "@presentation/components/ExpandButton";
import { useTranslation } from "react-i18next";
import TotalCheckbox from "@presentation/components/TotalCheckbox";

interface ScopeCollapsibleTableProps extends BoxProps {
  datasetId: number;
  title: string | undefined;
  items: ScopeCollapsibleRoleItem[];
  checkedItems: number[];
  parentRoles?: RoleByDataset[];
  toggleCheckColumn: (tableId: number, columnId: number) => void;
  toggleCheckAll: (tableId: number) => void;
}

const ScopesCollapsibleRolesTable = (props: ScopeCollapsibleTableProps): JSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { title, items, datasetId, checkedItems, parentRoles, toggleCheckColumn, toggleCheckAll } = props;

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const isChecked = (value: ScopeCollapsibleRoleItem): boolean => {
    if (checkedItems.length > 0 && checkedItems.includes(value.id)) return true;
    const parentRole = parentRoles?.find((pr: RoleByDataset) => pr.id === datasetId);
    if (parentRole) {
      return parentRole.roles.some((prs) => prs.id === value.id);
    }
    return false;
  };

  const isCheckedAll = (): boolean => {
    if (checkedItems.length > 0 && items.filter((i) => !checkedItems.includes(i.id)).length === 0) return true;
    const parentRole = parentRoles?.find((pr: RoleByDataset) => pr.id === datasetId);
    if (parentRole) {
      return parentRole.roles.every((prs) => items.some((i: ScopeCollapsibleRoleItem) => prs.id === i.id));
    }
    return false;
  };

  const isCheckedAllIndeterminate = (): boolean => {
    if (
      checkedItems.length > 0 &&
      items.filter((i) => checkedItems.includes(i.id)).length > 0 &&
      items.filter((i) => checkedItems.includes(i.id)).length !== items.length
    )
      return true;
    const parentRole = parentRoles?.find((pr: RoleByDataset) => pr.id === datasetId);
    if (parentRole && parentRole.roles.length !== items.length) {
      return parentRole.roles.some((prs) => items.some((i: ScopeCollapsibleRoleItem) => prs.id === i.id));
    }
    return false;
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ width: "48%" }}>
      <Card
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.custom?.tabLineColor?.main,
          border: 1,
          borderColor: theme.custom?.lineColor?.main,
        }}
      >
        <CardContent sx={{ height: "100%", p: 0 }}>
          <Grid container item>
            <Grid container item alignItems="center" direction="row">
              <TotalCheckbox
                sx={{ padding: "12px" }}
                checked={isCheckedAll()}
                onClick={() => toggleCheckAll(datasetId)}
                indeterminate={isCheckedAllIndeterminate()}
                disabled={
                  items.length === 0 ||
                  parentRoles
                    ?.find((pr: RoleByDataset) => pr.id === datasetId)
                    ?.roles.every((prs) => items.every((i: ScopeCollapsibleRoleItem) => i.id === prs.id))
                }
              />
              <Typography variant="h6">{t("scope_collapsible.from", { title })}</Typography>
              <ExpandButton expand={expanded} onClick={handleExpandClick}>
                <ExpandCircleDownIcon
                  sx={{
                    color: "#ffffff",
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#7098A7",
                    borderRadius: "15px",
                    border: 0,
                    height: "25px",
                    width: "auto",
                  }}
                  viewBox="1 1 22 22"
                />
              </ExpandButton>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded}>
          <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main }} variant="middle" />
          <CardContent>
            {items.map((value: ScopeCollapsibleRoleItem) => {
              const labelId = `transfer-list-all-item-${value.id}-label`;

              return (
                <Grid container direction="row" key={value.id + "-" + value.roleName}>
                  <Grid
                    container
                    direction="row"
                    item
                    alignItems="center"
                    role="listitem"
                    onClick={() => {
                      if (
                        !parentRoles
                          ?.find((pr: RoleByDataset) => pr.id === datasetId)
                          ?.roles.some((prs) => prs.id === value.id)
                      )
                        toggleCheckColumn(datasetId, value.id);
                    }}
                  >
                    <Box>
                      <TotalCheckbox
                        checked={isChecked(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        disabled={parentRoles
                          ?.find((pr: RoleByDataset) => pr.id === datasetId)
                          ?.roles.some((prs) => prs.id === value.id)}
                      />
                    </Box>
                    <Typography id={labelId} variant="body2">
                      {value.roleName}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default ScopesCollapsibleRolesTable;
