import { getActualMaintenance, getMaintenancesIsPending } from "@adapters/store/maintenances/slice";
import { actualMaintenance } from "@adapters/store/maintenances/thunk";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import ErrorPage from "@presentation/components/ErrorPage";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const InMaintenance = (): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [mounted, setMounted] = React.useState(false);

  const pending = useAppSelector(getMaintenancesIsPending);
  const actual = useAppSelector(getActualMaintenance);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actualMaintenance());
    setMounted(true);
  }, [dispatch]);

  useEffect(() => {
    if (!mounted || pending) return;
    if (actual === null) navigate("/");
  }, [mounted, pending, actual, navigate]);

  const getDateFormat = (date?: dayjs.Dayjs | null): string => {
    if (!date) date = dayjs().add(4, "hour");
    return date.format("DD/MM/YYYY HH:mm");
  };

  return (
    <ErrorPage
      message={t("error_view.in_maintenance", {
        endOfMaintenance: getDateFormat(actual?.endDate),
      })}
    />
  );
};

export default InMaintenance;
