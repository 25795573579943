import { Divider } from "@mui/material";

const TableDivider = (): React.JSX.Element => {
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{ backgroundColor: "rgba(183, 203, 211, 0.7)", height: "24px", my: "auto" }}
    />
  );
};

export default TableDivider;
