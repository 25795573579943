import { Box, BoxProps, SxProps, Theme, useTheme } from "@mui/material";
import React, { Children, cloneElement, isValidElement, useState } from "react";

interface IButtonProps extends BoxProps {
  children: React.ReactNode;
  secondary?: boolean;
  size?: "small" | "medium" | "large";
}

const TotalZoomIconButton = ({ children, sx, size, secondary = false, ...other }: IButtonProps): React.JSX.Element => {
  const theme = useTheme();
  const sxSize: SxProps<Theme> = { width: "48px", height: "48px" };
  switch (size ?? "large") {
    case "small":
      sxSize.width = "16px";
      sxSize.height = "16px";
      break;
    case "medium":
      sxSize.width = "22px";
      sxSize.height = "22px";
      break;
  }

  const [isHover, setIsHover] = useState(false);

  const getTextColor = (hover: boolean, isSecondary: boolean): string => {
    if (isSecondary) {
      return hover ? theme.custom.primary.white : theme.custom.colors.brand.primary[500];
    } else {
      return theme.custom.primary.white;
    }
  };

  const getBackgroundColor = (hover: boolean, isSecondary: boolean): string => {
    if (isSecondary) {
      return hover ? theme.custom.colors.brand.primary[500] : theme.custom.primary.white;
    } else {
      return hover ? theme.custom.colors.hover.brand.primary[500] : theme.custom.colors.brand.primary[500];
    }
  };

  const getBorderColor = (hover: boolean, isSecondary: boolean): string => {
    if (isSecondary) {
      return hover ? theme.custom.colors.hover.brand.primary[500] : theme.custom.select.border.color;
    } else {
      return hover ? theme.custom.colors.hover.brand.primary[500] : theme.custom.colors.brand.primary[500];
    }
  };

  const enhancedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        color: getTextColor(isHover, secondary),
      } as never);
    }
    return child;
  });
  return (
    <Box
      {...other}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        ...sxSize,
        ...sx,
        backgroundColor: getBackgroundColor(isHover, secondary),
        display: "flex",
        "& svg": {
          margin: "auto",
        },
        cursor: "pointer",
        borderRadius: "8px",
        border: `1px solid ${getBorderColor(isHover, secondary)}`,
        padding: "7px",
        boxShadow: isHover ? undefined : theme.custom.shadow.button,
      }}
    >
      {enhancedChildren}
    </Box>
  );
};

export default TotalZoomIconButton;
