import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import React from "react";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface DatePickerProps extends BoxProps {
  date?: dayjs.Dayjs | null;
  onDateChange?: (date: dayjs.Dayjs | null) => void;
  minDate?: dayjs.Dayjs | null;
  maxDate?: dayjs.Dayjs | null;
  label: string;
  disabled?: boolean;
  error?: boolean;
  DateInputProps?: DateTimePickerProps<dayjs.Dayjs>;
}

const DatePicker = ({
  date,
  onDateChange,
  disabled,
  error,
  width,
  label,
  DateInputProps,
  minDate,
  maxDate,
  ...props
}: DatePickerProps): React.JSX.Element => {
  const theme = useTheme();
  const color = "white";
  return (
    <Box {...props} sx={{ width: width, ...props?.sx }}>
      {label && (
        <Typography
          variant="body2"
          sx={{
            mb: "8px",
            cursor: "default",
            ...(error && { color: theme.palette.secondary.main, fontWeight: 600 }),
            ...(disabled && { color: theme.custom?.disabled, fontStyle: "italic", cursor: "not-allowed" }),
          }}
        >
          {label}
        </Typography>
      )}
      <DateTimePicker
        ampm={false}
        value={date}
        minDateTime={minDate ?? undefined}
        maxDateTime={maxDate ?? undefined}
        format="DD/MM/YYYY HH:mm" // TODO: Change format with translation file ?
        onChange={(newValue) => {
          if (onDateChange) onDateChange(newValue);
        }}
        disabled={disabled}
        {...DateInputProps}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "& .MuiInputBase-input": {
            padding: "4px 0 5px",
          },
          cursor: "default",
          backgroundColor: color,
          borderRadius: theme.custom?.select?.border?.radius,
          border: !error ? theme.custom?.select?.border?.size : 2,
          borderColor: !error ? theme.custom?.select?.border?.color : theme.palette.secondary.main,
          width: "calc(100% - 18px)", // 18px = 8px padding-left + 8px padding-right + 2px border
          px: "8px",
          height: "32px",
          ...(error && { color: theme.palette.secondary.main }),
          ...(disabled && { backgroundColor: theme.custom?.disabled, cursor: "not-allowed" }),
          ...DateInputProps?.sx,
        }}
      />
    </Box>
  );
};

export default DatePicker;
