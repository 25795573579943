import { SvgIconProps } from "@mui/material";

type CountriesIcons = {
  [key: string]: JSX.Element;
};

interface CountryIconProps extends SvgIconProps {
  countryCode: string;
  width?: string;
  height?: string;
}

const CountryIcon = ({ countryCode, width = "21px", height = "15px", ...props }: CountryIconProps): JSX.Element => {
  const countriesIcons: CountriesIcons = {
    fr: (
      <>
        <g clipPath="url(#clip0_1821_2851)">
          <rect width={width} height={height} fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0H7V15H0V0Z" fill="#1A47B8" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14 0H21V15H14V0Z" fill="#F93939" />
        </g>
        <defs>
          <clipPath id="clip0_1821_2851">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </>
    ),
    de: (
      <>
        <g clipPath="url(#clip0_1821_2837)">
          <rect width={width} height={height} fill="#F93939" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0 10H21V15H0V10Z" fill="#FFDA2C" />
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0H21V5H0V0Z" fill="#151515" />
        </g>
        <defs>
          <clipPath id="clip0_1821_2837">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </>
    ),
    en: (
      <>
        <g clipPath="url(#clip0_1821_2284)">
          <rect width={width} height={height} fill="#1A47B8" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.23397 0H0V2.5L18.7539 15L21 15V12.5L2.23397 0Z"
            fill="white"
          />
          <path d="M0.745098 0L21 13.5354V15H20.2724L0 1.45056V0H0.745098Z" fill="#F93939" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 0H21V2.5C21 2.5 8.0098 10.8281 2 15H0V12.5L19 0Z"
            fill="white"
          />
          <path d="M21 0H20.3218L0 13.5471V15H0.745098L21 1.46151V0Z" fill="#F93939" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.63708 0H13.3804V4.62682H21V10.3701H13.3804V15H7.63708V10.3701H0V4.62682H7.63708V0Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8421 0H12.1579V5.76923H21V9.23077H12.1579V15H8.8421V9.23077H0V5.76923H8.8421V0Z"
            fill="#F93939"
          />
        </g>
        <defs>
          <clipPath id="clip0_1821_2284">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </>
    ),
  };

  return (
    <svg width={width} height={height} viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {countriesIcons[countryCode ?? "en"] ?? countriesIcons["en"]}
    </svg>
  );
};

export default CountryIcon;
