import styled from "@emotion/styled";
import { Grid, Pagination, TablePaginationBaseProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent } from "react";
import TotalSelect from "@presentation/components/TotalSelect";

interface ITablePaginationProps extends TablePaginationBaseProps {
  page: number;
  pageNumber: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  nbItems: number;
  onPageChange: (event: ChangeEvent<unknown> | null, newPage: number) => void;
  onRowsPerPageChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const StyledPagination = styled(Pagination)(() => {
  const theme = useTheme();
  return {
    "& .MuiPagination-ul > li:first-of-type > .MuiPaginationItem-outlinedPrimary::after": {
      marginLeft: "12px",
      content: '"Previous"',
      color: theme.palette.text.primary,
    },
    "& .MuiPagination-ul > li:last-of-type > .MuiPaginationItem-outlinedPrimary::before": {
      marginRight: "12px",
      content: '"Next"',
      color: theme.palette.text.primary,
    },
    "& .MuiPaginationItem-outlinedPrimary": {
      margin: "0 6px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "32px",
      minWidth: "32px",
      userSelect: "none",
      cursor: "pointer",
      border: theme.custom.select.border.default,
      backgroundColor: "white",
      boxShadow: "0px 1px 1px #b7cbd3cc, 0px 6px 8px #b7cbd352",
      "&:hover": {
        backgroundColor: theme.custom?.borderColor?.main,
      },
      "&.Mui-selected": {
        color: "white",
        border: 0,
        backgroundColor: theme.custom.tertiary.main,
        boxShadow: "none",
      },
    },
    "& .MuiPaginationItem-previousNext": {
      border: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
      color: theme.palette.secondary.main,
      "&.Mui-disabled": {
        color: theme.custom?.disabled?.textColor,
      },
    },
  };
});

// Function who get start number and end number, and return an array of number between start and end
const range = (start: number, end: number): number[] => {
  if (start > end) return range(end, start).reverse();
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

const TotalTablePagination = (props: ITablePaginationProps): React.JSX.Element => {
  const { t } = useTranslation();
  const { page, pageNumber, rowsPerPageOptions, rowsPerPage, onRowsPerPageChange, onPageChange } = props;

  return (
    <Grid container direction="row" columns={12} sx={{ px: "22px" }}>
      <Grid item xs={3}>
        <TotalSelect
          label={t("pagination.go_to_page")}
          sx={{ minWidth: "50px", my: "auto" }}
          selectSx={{ maxWidth: "60px" }}
          value={page + 1}
          inline
          items={range(1, pageNumber).map((i) => ({ id: i, optionName: i.toString() }))}
          onChange={(e: number) => {
            onPageChange(null, e);
          }}
        />
      </Grid>
      <Grid container item xs={6} direction="row" alignItems="center" justifyContent="center">
        <StyledPagination
          count={pageNumber}
          variant="outlined"
          color="primary"
          onChange={onPageChange}
          page={page + 1}
          siblingCount={1}
        />
      </Grid>
      <Grid item xs={3} sx={{ ml: "auto" }}>
        <TotalSelect
          label={t("pagination.row_per_page")}
          sx={{ minWidth: "50px", my: "auto", ml: "auto", float: "right" }}
          selectSx={{ maxWidth: "80px" }}
          value={rowsPerPage}
          inline
          items={(rowsPerPageOptions ?? [5, 10, 25, 50]).map((i) => ({ id: i, optionName: i.toString() }))}
          onChange={(e: number) => {
            const target = document.createElement("input");
            target.value = e.toString();
            const dummyEvent = {
              target: target,
              currentTarget: target,
              nativeEvent: new Event("change"),
              bubbles: true,
              cancelable: true,
              defaultPrevented: false,
              eventPhase: Event.NONE,
              isTrusted: false,
              preventDefault: () => false,
              isDefaultPrevented: () => false,
              stopPropagation: () => false,
              isPropagationStopped: () => false,
              persist: () => false,
              timeStamp: Date.now(),
              type: "change",
            } as React.ChangeEvent<HTMLInputElement>;

            onRowsPerPageChange(dummyEvent);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TotalTablePagination;
