import { httpClient } from "@adapters/api/HttpClient";
import { RoleRequest } from "@domain/entities/Roles";

export const rolesServices = {
  listRoles: async (args?: { datasetId: number; roleName: string }) => {
    return httpClient.get(`/v1/powerbi/datasets/${args?.datasetId || "all"}/roles`, {
      params: { roleName: args?.roleName },
    });
  },
  deleteRole: async (roleId: number) => {
    return httpClient.delete(`/v1/powerbi/roles/${roleId}`);
  },
  createRole: async (role: RoleRequest) => {
    return httpClient.post("/v1/powerbi/roles", role);
  },
  updateRole: async (role: RoleRequest) => {
    return httpClient.put(`/v1/powerbi/roles/${role.id}`, role);
  },
  getRoleById: async (roleId: number) => {
    return httpClient.get(`/v1/powerbi/roles/${roleId}`);
  },
};
