import { Report } from "@domain/entities/Reports";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState } from "react";

interface SummarySubscriptionProps {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  regularity: string;
  selectedReports: number[];
  reportsList: Report[];
}

const SummarySubscription = (props: SummarySubscriptionProps): JSX.Element => {
  const theme = useTheme();
  const { startDate, endDate, regularity, selectedReports, reportsList } = props;

  const { t } = useTranslation();

  const [timeFormat] = useState("DD/MM/YYYY");

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 1, py: "15px", justifyContent: "space-between", gap: "10%" }}>
        <Box
          sx={{
            minWidth: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("subscription_create_update.summary.details_title")}</Typography>
            {startDate && endDate && (
              <Typography sx={{ fontSize: "16px" }}>
                {t("subscription_create_update.summary.months_count", { count: endDate.diff(startDate, "month") })}
              </Typography>
            )}
          </Box>
          <Box sx={{ padding: "15px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("subscription_create_update.summary.start_date_title")}</b> {startDate.format(timeFormat) || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("subscription_create_update.summary.end_date_title")}</b> {endDate.format(timeFormat) || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("subscription_create_update.summary.regularity_title")}</b>{" "}
              {regularity === "EVERY_WEEK"
                ? t("subscription_create_update.regularity_every_week")
                : regularity === "EVERY_OTHER_WEEK"
                ? t("subscription_create_update.regularity_every_other_week")
                : regularity === "EVERY_MONTH"
                ? t("subscription_create_update.regularity_every_month")
                : "--"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "15px", width: "100%" }}>
        <Box
          sx={{
            minWidth: "45%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("subscription_create_update.summary.reports_title")}</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {t("subscription_create_update.summary.reports_count", { count: selectedReports.length })}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {selectedReports.map((x: number, idx: number) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "50%",
                    height: "42px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link
                    key={`user_report_${x}`}
                    to={`/reports/${x}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        display: "inline-flex",
                        alignContent: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {reportsList.find((r) => r.id === x)?.reportName}
                      <CallMadeOutlinedIcon
                        sx={{
                          color: theme.palette.secondary.main,
                          height: "14px",
                          translate: "-2px 5px",
                        }}
                      />
                    </Typography>
                  </Link>
                  {(idx + 1) % 2 !== 0 && (
                    <Divider
                      sx={{ bgcolor: theme?.custom?.lineColor?.main, height: "42px", mx: "24px" }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummarySubscription;
