import { Box, Card, Chip, List, ListItem, Typography } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";
import TotalButton from "@presentation/components/TotalButton";
import { useEffect } from "react";
import { useAppDispatch } from "@core/store/hook";
import { deleteAuthUser } from "@adapters/store/users/slice";
import { useTranslation } from "react-i18next";

const Authenticate = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(deleteAuthUser());
  }, [dispatch]);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          minWidth: "425px",
          borderRadius: "16px",
          mt: "20px",
        }}
        elevation={10}
      >
        <Box sx={{ mx: "40px", py: "40px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "40px" }}>
            <TotalIcon />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: "40px", gap: "8px" }}>
            <Typography align="center" variant="h3" sx={{ mb: "8px" }}>
              {t("authentication.welcome")}
            </Typography>
            <Typography align="center" variant="h6">
              {t("authentication.message_authentication_system")}
            </Typography>
            <Typography align="justify" variant="h6">
              <List sx={{ listStyleType: "disc", pl: 2 }}>
                <ListItem sx={{ display: "list-item", pl: 1, pb: 0 }}>
                  {t("authentication.external_directive")}{" "}
                  <Chip label={"GigyaB2BP"} color="info" size="small" variant="outlined" />
                </ListItem>
                <ListItem sx={{ display: "list-item", pl: 1 }}>
                  {t("authentication.internal_directive")}{" "}
                  <Chip label={"DigitalPass"} color="info" size="small" variant="outlined" />
                </ListItem>
              </List>
            </Typography>
            <Typography align="center" variant="h6">
              {t("authentication.once_logged_message")}
            </Typography>
            <Typography align="center" variant="h6">
              {t("authentication.thank_you")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "24px", columnGap: "16px" }}>
            <a
              href={`${process.env.REACT_APP_CGN_AUTH_URL}&client_id=${process.env.REACT_APP_CGN_CLIENT_ID}&scope=${process.env.REACT_APP_CGN_SCOPE}&redirect_uri=${process.env.REACT_APP_CGN_REDIRECT_URI}`}
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <TotalButton
                id="login-button"
                text={t("authentication.login")}
                icon="logIn"
                height={22}
                disabled={false}
              ></TotalButton>
            </a>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Authenticate;
