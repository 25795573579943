import { ReactComponent as User } from "@assets/icons/user_icon.svg";

const UserIcon = (props: any): JSX.Element => {
  return (
    <User
      fill={props?.sx?.color || props?.sx?.fill}
      height={props?.sx?.height || "16px"}
      width={props?.sx?.width || props?.sx?.height || "16px"}
      {...props}
    />
  );
};

export default UserIcon;
