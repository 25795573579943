import { Alert, Box, Breadcrumbs, Button, Divider, Grid, Link, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DatasetData } from "@domain/Interfaces/Data.d";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import DatasetCollapsibleTable from "@presentation/components/DatasetCollapsibleTable";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TotalSelect from "@presentation/components/TotalSelect";
import ColumnFieldCollapsible from "@presentation/components/ColumnFieldCollapsible";
import { listDatasets, listDatasetTables } from "@adapters/store/datasets/thunk";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import {
  getDatasetIsPending,
  getDatasets,
  getDatasetTableIsPending,
  getDatasetTables,
} from "@adapters/store/datasets/slice";
import { clearRolesError, getRolesError, getRolesIsPending, roleAlreadyExists } from "@adapters/store/roles/slice";
import { createRole, listRoles, searchRole } from "@adapters/store/roles/thunk";
import { throttle } from "throttle-debounce";
import SummaryRole from "@presentation/components/roles/SummaryRoleCreateUpdate";
import { useLocation, useNavigate } from "react-router-dom";
import { Dataset } from "@domain/entities/Datasets";
import { Filter } from "@domain/entities/Roles";
import { uniq } from "@core/utils/ArrayTools";
import { useTranslation } from "react-i18next";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";

export interface ColumnChecked {
  tableId: number;
  tableName: string;
  columnId: number;
  columnName: string | undefined;
  value?: string;
}

const CreateRole = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [datasetDisabled, setDatasetDisabled] = useState<boolean>(false);
  const [selectedDataset, setSelectedDataset] = useState<number | null>(null);
  const [roleName, setRoleName] = useState<string>("");
  const [checkedColumns, setCheckedColumns] = useState<ColumnChecked[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getRolesError);
  const datasetList = useAppSelector(getDatasets);
  const alreadyExists = useAppSelector(roleAlreadyExists(selectedDataset || -1, roleName.trim()));
  const rolesIsPending = useAppSelector(getRolesIsPending);
  const datasetTables = useAppSelector(getDatasetTables);
  const datasetIsPending = useAppSelector(getDatasetIsPending);
  const datasetTablesIsPending = useAppSelector(getDatasetTableIsPending);

  const path = "/roles";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("role_create_update.manage_roles")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("role_create_update.create_title")}
    </Link>,
  ];

  const handleDatasetChange = (value: number): void => {
    roleAlreadyExists(value, roleName);
    setSelectedDataset(value);
    if (value !== -1) {
      listSelectedDatasetTables(value);
    }
  };

  const handleNameChange = (value: string): void => {
    if (selectedDataset !== null) {
      roleAlreadyExists(selectedDataset, roleName);
    }
    setRoleName(value);
  };

  const toggleCheckSingleColumn = (tableId: number, columnId: number): void => {
    const table = datasetTables.find((datasetTable) => datasetTable.id === tableId);
    if (table) {
      let selectedColumns;
      if (checkedColumns.some((column) => column.tableId === tableId && column.columnId === columnId)) {
        selectedColumns = checkedColumns.filter((column) => column.columnId !== columnId);
      } else {
        selectedColumns = [...checkedColumns];
        selectedColumns.push({
          tableId: table.id,
          tableName: table.tableName,
          columnId: columnId,
          columnName: table.columns.find((c) => c.id === columnId)?.columnName,
          value: "",
        });
      }
      setCheckedColumns(uniq(selectedColumns));
    }
  };

  const toggleCheckAllColumns = (tableId: number): void => {
    const table = datasetTables.find((datasetTable) => datasetTable.id === tableId);
    if (table) {
      let newArr;
      if (checkedColumns.some((column) => column.tableId === table.id)) {
        newArr = checkedColumns.filter((column) => !table.columns.map((col) => col.id).includes(column.columnId));
      } else {
        newArr = [...checkedColumns];
        table.columns.forEach((c) => {
          newArr.push({
            tableId: table.id,
            tableName: table.tableName,
            columnId: c.id,
            columnName: c.columnName,
            value: "",
          });
        });
      }
      setCheckedColumns(uniq(newArr));
    }
  };

  const handleColumnValueChange = (idx: number, value: string, isDuplicate?: boolean): void => {
    if (isBusy) return;
    setIsBusy(true);
    let newArr;
    if (isDuplicate) {
      newArr = [...checkedColumns];
      newArr.splice(idx, 0, { ...checkedColumns[idx] });
      newArr[idx + 1].value = value;
    } else {
      if (value !== "") {
        newArr = [...checkedColumns];
        newArr[idx].value = value;
      } else if (value === "") {
        newArr = [...checkedColumns];
        newArr[idx].value = "";
      } else {
        newArr = checkedColumns.filter((x: ColumnChecked, xId: number) => xId !== idx);
      }
    }
    setCheckedColumns(uniq(newArr));
    setIsBusy(false);
  };

  const handleColumnRemove = (idx: number): void => {
    const newArr = [...checkedColumns];
    newArr.splice(idx, 1);
    setCheckedColumns(uniq(newArr));
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    if (selectedDataset !== null && roleName !== null) {
      roleAlreadyExists(selectedDataset, roleName);
    }
    setIsBusy(true);
    const result =
      roleName !== null &&
      roleName !== undefined &&
      roleName !== "" &&
      roleName.length > 2 &&
      selectedDataset !== null &&
      !alreadyExists;
    setIsBusy(false);
    return result;
  };

  const handleSubmitForm = async (): Promise<void> => {
    if (rolesIsPending) return;
    dispatch(clearRolesError());
    let filters: Filter[] = [];
    if (checkedColumns && checkedColumns.length > 0) {
      filters = checkedColumns.map((c) => ({
        columnId: c.columnId,
        value: c.value || "",
      }));
    }
    await dispatch(
      createRole({
        roleName: roleName.trim(),
        datasetId: selectedDataset || -1,
        filters: uniq(filters),
      })
    );
    dispatch(listRoles());
    if (!error) navigate(path);
  };

  const listSelectedDatasetTables = (datasetId: number): void => {
    if (datasetTablesIsPending !== false) return;
    dispatch(listDatasetTables(datasetId));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchThrottled = useCallback(
    throttle(1000, (value: string) => {
      if (selectedDataset === null) return;
      dispatch(searchRole({ datasetId: selectedDataset, roleName: value }));
    }),
    [dispatch]
  );

  useEffect(() => {
    if (datasetIsPending || datasetTablesIsPending) return;
    dispatch(listDatasets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (rolesIsPending || datasetIsPending || datasetTablesIsPending) return;
    if (selectedDataset === null) {
      const datasetId = Number(queryParams.get("datasetId") || -1);
      if (datasetId > 0) {
        setSelectedDataset(datasetId);
        setDatasetDisabled(true);
      } else {
        setDatasetDisabled(false);
      }
    }
    dispatch(listRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetIsPending, selectedDataset, dispatch]);

  useEffect(() => {
    if (rolesIsPending || selectedDataset === null) return;
    listSelectedDatasetTables(selectedDataset);
    setCheckedColumns([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataset, dispatch]);

  useEffect(() => {
    if (selectedDataset === null) return;
    dispatch(listDatasetTables(selectedDataset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataset, dispatch]);

  useEffect(() => {
    if (roleName.length < 3) return;
    searchThrottled(roleName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName, selectedDataset]);

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataset, roleName]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {error && (
        <Alert severity="error" onClose={() => dispatch(clearRolesError())} sx={{ my: 2 }}>
          {error?.message}
        </Alert>
      )}
      <Typography sx={{ my: "28px", color: "#374649" }} variant="h2">
        {t("role_create_update.create_title")}
      </Typography>
      <Grid container item direction="row" justifyContent="space-between" alignItems="end">
        <Grid container item direction="row" md={8}>
          <TotalSelect
            label={t("role_create_update.dataset")}
            value={selectedDataset}
            items={datasetList.map((x: Dataset) => ({ id: x.id, optionName: x.datasetName }))}
            onChange={handleDatasetChange}
            sx={{ mr: "40px", minWidth: "200px" }}
            error={alreadyExists}
            disabled={datasetDisabled}
          />
          <TextFieldData
            id="role-name"
            label={t("role_create_update.role_name")}
            value={roleName}
            disabled={selectedDataset === null}
            error={alreadyExists}
            onChange={handleNameChange}
          />
        </Grid>
        <TotalButton
          id="save-new-role-button"
          text={t("role_create_update.save_new_role")}
          icon=""
          height={22}
          disabled={!isValid}
          onClick={handleSubmitForm}
        />
      </Grid>
      <CollapsibleCard
        id="related-column-collapsible"
        title={t("role_create_update.1_related_column")}
        sx={{ mt: "30px" }}
        isExpanded={true}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          {datasetTables && datasetTables.length > 0 ? (
            datasetTables.map((x: DatasetData) => {
              return (
                <DatasetCollapsibleTable
                  key={`dataset-table-${x.id}`}
                  id={`dataset-table-${x.id}-clickable-collapsible`}
                  title={x.tableName}
                  tableId={x.id}
                  items={x.columns}
                  checkedItems={checkedColumns
                    .filter((column) => column.tableId === x.id)
                    .map((column) => column.columnId)}
                  toggleCheckColumn={(tableId, columnId) => toggleCheckSingleColumn(tableId, columnId)}
                  toggleCheckAll={(tableId) => toggleCheckAllColumns(tableId)}
                />
              );
            })
          ) : (
            <Typography variant="body2" sx={{ fontStyle: "italic" }}>
              {t("role_create_update.select_dataset")}
            </Typography>
          )}
        </Box>
      </CollapsibleCard>
      <CollapsibleCard
        id="requested-value-collapsible"
        title={t("role_create_update.2_requested_value")}
        sx={{ mt: "8px" }}
      >
        <Typography variant="backButton" noWrap={true}>
          {t("role_create_update.request_value_description")}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          {datasetTables && datasetTables.length > 0 && checkedColumns && checkedColumns.length > 0 ? (
            checkedColumns.map((x: ColumnChecked, idx: number) => {
              return (
                <ColumnFieldCollapsible
                  key={`${x.columnId}${x.columnName}${idx}`}
                  className={`${x.columnId}${x.columnName}${idx}`}
                  tableName={x.tableName}
                  columnName={x.columnName}
                  columnValuesIdx={idx}
                  value={x.value}
                  handleValue={(idxa: number, e: string) => handleColumnValueChange(idxa, e)}
                  handleDuplicate={(idxa: number, e: string) => handleColumnValueChange(idxa, e, true)}
                  handleDelete={(idxa: number) => handleColumnRemove(idxa)}
                />
              );
            })
          ) : (
            <Typography variant="body2" sx={{ fontStyle: "italic" }}>
              {t("role_create_update.select_column")}
            </Typography>
          )}
        </Box>
      </CollapsibleCard>
      <CollapsibleCard id="role-summary-collapsible" title={t("role_create_update.3_role_summary")} sx={{ mt: "8px" }}>
        <SummaryRole
          selectedDataset={datasetList.find((d) => d.id === selectedDataset)}
          roleName={roleName}
          values={checkedColumns}
          tables={datasetTables.filter((table) => checkedColumns.map((column) => column.tableId).includes(table.id))}
        />
      </CollapsibleCard>
    </Grid>
  );
};

export default CreateRole;
