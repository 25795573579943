import { usersServices } from "@adapters/api/services/UsersServices";
import { inProduction } from "@core/utils/Tools";
import { User, UserRedirection, UserRequest, UserToken } from "@domain/entities/Users";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listUsers = createAsyncThunk("users/list", async (_, { rejectWithValue }) => {
  try {
    const response = await usersServices.listUsers();
    return response.data as User[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
export const createUser = createAsyncThunk("users/create", async (user: UserRequest, { rejectWithValue }) => {
  try {
    const response = await usersServices.createUser(user);
    return response.data as User;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteUser = createAsyncThunk("users/delete", async (userId: number, { rejectWithValue }) => {
  try {
    await usersServices.deleteUser(userId);
    return userId;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateUser = createAsyncThunk("users/update", async (user: UserRequest, { rejectWithValue }) => {
  try {
    const response = await usersServices.updateUser(user);
    return response.data as User;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const validateUser = createAsyncThunk("users/validate", async (token: string, { rejectWithValue }) => {
  try {
    const response = await usersServices.validateUser(token);
    return response.data as UserRedirection;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const rejectUser = createAsyncThunk("users/reject", async (token: string, { rejectWithValue }) => {
  try {
    const response = await usersServices.rejectUser(token);
    return response.data as UserRedirection;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const authenticateUser = createAsyncThunk("users/authenticate", async (code: string, { rejectWithValue }) => {
  try {
    const response = await usersServices.authenticateUser(code);
    return response.data as UserToken;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const existInCustomerPortal = createAsyncThunk("users/existInCustomerPortal", async (_, { rejectWithValue }) => {
  try {
    // Remove this line to activate Switch to Customer portal feature in production
    if (inProduction()) return false;
    const response = await usersServices.existInCustomerPortal();
    return response.data.response;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const exportUsers = createAsyncThunk("users/export", async (_, { rejectWithValue }) => {
  try {
    const response = await usersServices.exportUsers();
    return response.data as { url: string; fileName: string };
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
