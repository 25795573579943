import { scopesServices } from "@adapters/api/services/ScopesServices";
import { Scope, ScopeRequest } from "@domain/entities/Scopes";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listScopes = createAsyncThunk("scopes/list", async (_, { rejectWithValue }) => {
  try {
    const response = await scopesServices.listScopes();
    return response.data as Scope[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteScope = createAsyncThunk("scopes/delete", async (scopeId: number, { rejectWithValue }) => {
  try {
    const response = await scopesServices.deleteScope(scopeId);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createScope = createAsyncThunk("scopes/create", async (scope: ScopeRequest, { rejectWithValue }) => {
  try {
    const response = await scopesServices.createScope(scope);
    return response.data as Scope;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateScope = createAsyncThunk("scopes/update", async (scope: ScopeRequest, { rejectWithValue }) => {
  try {
    const response = await scopesServices.updateScope(scope);
    return response.data as Scope;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
