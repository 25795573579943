import { useEffect, useState } from "react";
import { GroupHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import TotalGroupsTable from "@presentation/components/groups/TotalGroupsTable";
import { getGroups, getGroupsIsPending } from "@adapters/store/groups/slice";
import { listGroups } from "@adapters/store/groups/thunk";
import TotalTooltip from "@presentation/components/TotalTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";

const Groups = (): JSX.Element => {
  const { t } = useTranslation();
  const headCells: GroupHeadCell[] = [
    {
      id: "groupName",
      numeric: false,
      disablePadding: true,
      label: t("manage_groups.headers.name"),
      enableSorting: true,
    },
    {
      id: "usersLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_groups.headers.users"),
      enableSorting: true,
    },
    {
      id: "scopesLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_groups.headers.scopes"),
      enableSorting: true,
    },
    {
      id: "reportsLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_groups.headers.reports"),
      enableSorting: true,
    },
  ];

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getGroups);
  const groupsPending = useAppSelector(getGroupsIsPending);
  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    dispatch(listGroups());
  }, [dispatch]);

  useEffect(() => {
    if (groupsPending === false) {
      setIsPending(false);
    }
  }, [dispatch, rows, groupsPending]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      {!isPending && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
              {t("manage_groups.title", { count: rows.length })}
            </Typography>
            <TotalTooltip title={t("manage_groups.description")} setOpenState={setInfoTooltipState}>
              <Box>
                <TooltipIconTrigger tooltipState={infoTooltipState}>
                  <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
                </TooltipIconTrigger>
              </Box>
            </TotalTooltip>
          </Box>
          <TotalGroupsTable headCells={headCells} rows={rows}></TotalGroupsTable>
        </Box>
      )}
    </Grid>
  );
};

export default Groups;
