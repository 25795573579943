import styled from "@emotion/styled";
import { Select, Box, FormControl, MenuItem, Typography, useTheme, SxProps, Theme } from "@mui/material";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";

interface ListItems {
  id: number;
  optionName: string;
}

interface TotalSelectProps {
  items: ListItems[];
  label?: string;
  value?: string | number | null;
  onChange?: (value: number) => void;
  sx?: SxProps<Theme> & { minWidth?: string };
  selectSx?: SxProps<Theme>;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  inline?: boolean;
}

const StyledSelect = styled(Select)(() => {
  const theme = useTheme();
  return {
    "& .MuiSelect-icon": {
      color: theme.palette.secondary.main,
      display: "inline-flex",
      justifyItems: "center",
      margin: 0,
      padding: 0,
      top: "unset",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  };
});

const StyledArrowDownIcon = styled(ArrowDownIcon)(() => ({
  class: "MuiSelect-icon",
}));

const TotalSelect = ({
  label,
  value,
  items,
  onChange,
  sx,
  selectSx,
  disabled = false,
  error = false,
  inline = false,
  placeholder,
}: TotalSelectProps): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const color = "white";

  return (
    <Box sx={sx}>
      <FormControl size="small" error={error} sx={{ flexDirection: inline ? "row" : "column" }}>
        {label && (
          <Typography variant="body2" sx={inline ? { my: "auto", mr: "8px" } : { mb: "8px" }}>
            {label}
          </Typography>
        )}
        <StyledSelect
          error={error}
          label={label}
          value={value ?? t("total_select.select")}
          IconComponent={StyledArrowDownIcon}
          sx={{
            backgroundColor: color,
            borderRadius: theme.custom.select.border.radius,
            border: theme.custom.select.border.default,
            minWidth: sx?.minWidth ?? "200px",
            textAlign: "left",
            height: "32px",
            zIndex: "0",
            ...selectSx,
          }}
          onChange={(event) => {
            if (onChange) onChange(event.target.value as number);
          }}
          disabled={disabled}
        >
          <MenuItem sx={{ display: "none" }} key="Select" value="Select">
            <em>{placeholder ?? t("total_select.select_placeholder")}</em>
          </MenuItem>
          {items.map((x: ListItems) => {
            return (
              <MenuItem key={x.id} value={x.id}>
                {x.optionName}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

export default TotalSelect;
