import { Typography, TypographyProps, useTheme } from "@mui/material";

interface InputLabelProps extends TypographyProps {
  label?: string;
  error?: boolean;
  disabled?: boolean;
}

/**
 * InputLabel Component
 *
 * This component displays a label for an input field. It supports error and disabled states.
 *
 * @param {string} label - The text of the label to be displayed.
 * @param {boolean} [error=false] - If true, the label is displayed with a style indicating an error.
 * @param {boolean} [disabled=false] - If true, the label is displayed as disabled.
 * @param {string} [variant="body2"] - The typography variant for the label.
 * @param {object} [sx] - Additional styles to apply to the label.
 * @param {TypographyProps} - Any properties of Typography component can be found [here](https://mui.com/material-ui/api/typography/).
 * @returns {JSX.Element} A JSX element representing the label.
 */
export const InputLabel = ({
  label,
  error = false,
  disabled = false,
  variant = "body2",
  sx,
  ...props
}: InputLabelProps): JSX.Element => {
  const theme = useTheme();

  return label ? (
    <Typography
      variant={variant}
      sx={{
        mb: "8px",
        cursor: "default",
        ...(error && { color: theme.palette.secondary.main, fontWeight: 600 }),
        ...(disabled && { color: theme.custom?.disabled, fontStyle: "italic", cursor: "not-allowed" }),
        ...sx,
      }}
      {...props}
    >
      {label}
    </Typography>
  ) : (
    <></>
  );
};
