import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import ProfileIcon from "@presentation/components/Icons/ProfileIcon";
import { Button, ButtonProps, Typography, useTheme } from "@mui/material";

interface IButtonProps extends ButtonProps {
  text?: string;
  menuOpen?: boolean;
}

const TotalButton = (props: IButtonProps): JSX.Element => {
  const theme = useTheme();
  const { text, menuOpen, onClick } = props;

  return (
    <Button
      sx={{
        height: "32px",
        borderRadius: "43px",
        background: "white",
        color: theme.palette?.primary?.main,
        border: 1,
        boxSizing: "border-box",
        borderWidth: menuOpen ? "2px" : "1px",
        mx: menuOpen ? 0 : "1px",
        borderColor: menuOpen ? theme.custom.neutral[100] : theme.custom.neutral[80],
        px: 2,
        ":hover": {
          borderColor: theme.custom.neutral[100],
        },
      }}
      startIcon={<ProfileIcon sx={{ color: theme.palette.primary.main }} />}
      endIcon={
        <ArrowDownIcon
          sx={{ color: theme.palette.secondary.main }}
          style={{ marginLeft: "12px", transition: "200ms", transform: menuOpen ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      }
      onClick={onClick}
    >
      <Typography variant="button" noWrap={true}>
        {text}
      </Typography>
    </Button>
  );
};

export default TotalButton;
