import { groupsServices } from "@adapters/api/services/GroupsServices";
import { Group, GroupRequest } from "@domain/entities/Groups";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listGroups = createAsyncThunk("groups/list", async (_, { rejectWithValue }) => {
  try {
    const response = await groupsServices.listGroups();
    return response.data as Group[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteGroup = createAsyncThunk("groups/delete", async (groupId: number, { rejectWithValue }) => {
  try {
    const response = await groupsServices.deleteGroup(groupId);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createGroup = createAsyncThunk("groups/create", async (group: GroupRequest, { rejectWithValue }) => {
  try {
    const response = await groupsServices.createGroup(group);
    return response.data as Group;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateGroup = createAsyncThunk("groups/update", async (group: GroupRequest, { rejectWithValue }) => {
  try {
    const response = await groupsServices.updateGroup(group);
    return response.data as Group;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
