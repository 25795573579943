import { listReports } from "@adapters/store/reports/thunk";
import { useAppDispatch } from "@core/store/hook";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Home = (): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listReports());
  }, [dispatch]);

  return (
    <Box>
      <Grid container direction="column" alignItems="start">
        <Typography sx={{ my: "17px", ml: "22px" }} variant="h2">
          {t("home_view.title")}
        </Typography>
      </Grid>
    </Box>
  );
};

export default Home;
