import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Collapse,
  IconButton,
  Menu,
  Typography,
  Divider,
  MenuItem,
  useTheme,
} from "@mui/material";
import { BoxProps } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalButton from "@presentation/components/TotalButton";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { createSearchParams, useNavigate } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Dataset } from "@domain/entities/Datasets";
import TotalTooltip from "@presentation/components/TotalTooltip";
import ExpandButton from "@presentation/components/ExpandButton";
import { isAdmin } from "@core/right/Right";
import { useAppSelector } from "@core/store/hook";
import { getAuthUser } from "@adapters/store/users/slice";
import { useTranslation } from "react-i18next";

interface CollapsibleCardProps extends BoxProps {
  title: string | undefined;
  dataset: Dataset;
}

const DatasetsListCollapsibleCard = (props: CollapsibleCardProps): JSX.Element => {
  const theme = useTheme();
  const { title, dataset, sx } = props;

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const editOpen = !!anchorEl;

  const authUser = useAppSelector(getAuthUser);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Card
        sx={{
          minHeight: "56px",
          width: "100%",
          borderRadius: "8px",
          border: !expanded ? "1px solid #B7CBD3" : "1px solid #374649",
          boxShadow: expanded ? "" : "0px 1px 1px rgba(183, 203, 211, 0.8), 0px 6px 8px rgba(183, 203, 211, 0.32);",
        }}
      >
        <CardContent sx={{ height: "100%" }} onClick={handleExpandClick}>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{title}</Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "24px" }}>
              {dataset?.newReportUrl ? (
                <a
                  href={dataset.newReportUrl}
                  style={{ textDecoration: "none", color: theme.palette.primary.main }}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  <TotalButton
                    id="create-report-button"
                    text={t("dataset_card.create_report_button")}
                    icon="chart"
                    height={15}
                    iconColor="white"
                  ></TotalButton>
                </a>
              ) : (
                <>
                  <TotalTooltip title={t("dataset_card.not_allowed_to_create_report")}>
                    <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
                  </TotalTooltip>
                  <TotalButton
                    id="create-report-button"
                    text={t("dataset_card.create_report_button")}
                    icon="chart"
                    height={15}
                    iconColor={theme.custom?.disabled?.main}
                    disabled={true}
                  ></TotalButton>
                </>
              )}
              {(isAdmin(authUser?.userRight) || dataset?.redirectUrl) && (
                <>
                  <IconButton
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.secondary.main,
                      height: "22px",
                      "&:hover": {
                        background: "none",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreHorizIcon sx={{ color: theme.palette.secondary.main, cursor: "pointer" }} />
                  </IconButton>
                  <Menu
                    open={editOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                      sx: { borderRadius: "8px", border: 1, borderColor: theme.custom?.lineColor?.main },
                    }}
                    MenuListProps={{ sx: { py: 0 } }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    {dataset && dataset.redirectUrl && (
                      <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
                        <a
                          href={dataset.redirectUrl}
                          style={{ textDecoration: "none", color: theme.palette.primary.main }}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <OpenInNewOutlinedIcon
                              sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }}
                            />
                            <Typography variant="h6">{t("dataset_card.open_dataset_in_powerbi_button")}</Typography>
                          </Box>
                        </a>
                      </MenuItem>
                    )}
                    {isAdmin(authUser?.userRight) && (
                      <MenuItem
                        sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          navigate({
                            pathname: "/roles/create",
                            search: `?${createSearchParams({ datasetId: dataset.id.toString() })}`,
                          });
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <TuneOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }} />
                          <Typography variant="h6">{t("dataset_card.create_role_button")}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}

              <Divider
                sx={{ bgcolor: theme?.custom?.lineColor?.main, height: "18px" }}
                orientation="vertical"
                variant="middle"
              />
              <ExpandButton
                sx={{ display: "flex", alignItems: "center" }}
                expand={expanded}
                onClick={handleExpandClick}
              >
                <ArrowDownIcon sx={{ color: theme.palette.secondary.main }} style={{ height: "16px", width: "16px" }} />
              </ExpandButton>
            </Box>
          </Grid>
        </CardContent>
        <Collapse in={expanded}>
          <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main, borderBottomWidth: "1px" }} variant="middle" />
          <CardContent sx={{ pt: "12px" }}>{props.children}</CardContent>
          <CardActions></CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default DatasetsListCollapsibleCard;
