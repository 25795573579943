import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ArrowDownIcon = ({ sx, ...props }: SvgIconProps): React.JSX.Element => {
  sx = { height: "16px", width: "16px", ...sx };
  return (
    <SvgIcon sx={sx} {...props}>
      <svg fill="currentColor" width="12" height="6" viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.969671 1.28033C0.676777 0.987436 0.676777 0.512562 0.969671 0.219669C1.26256 -0.0732235 1.73744 -0.0732235 2.03033 0.219669L6 4.18934L9.96967 0.21967C10.2626 -0.0732227 10.7374 -0.0732227 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033Z"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowDownIcon;
