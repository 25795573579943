import { TableCell, tableCellClasses, TableCellProps } from "@mui/material";
import React from "react";

const StyledTableCell = ({ children, sx }: TableCellProps): React.JSX.Element => {
  return (
    <TableCell
      sx={{
        [`&.${tableCellClasses.root}`]: {
          borderColor: "red",
          fontSize: "14px",
          border: "none",
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: "14px",
          paddingLeft: "16px",
          borderTop: "1px solid #C0CCD8",
          borderBottom: "1px solid #C0CCD8",
        },
        "&:first-of-type": {
          borderRadius: "8px 0 0 8px",
          borderLeft: "1px solid #C0CCD8",
        },
        "&:last-of-type": {
          borderRadius: "0 8px 8px 0",
          borderRight: "1px solid #C0CCD8",
        },
        ...sx,
      }}
    >
      {children}
    </TableCell>
  );
};

export default StyledTableCell;
