import {
  Box,
  Typography,
  CircularProgress,
  BoxProps,
  useTheme,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Report } from "@domain/entities/Reports";
import GroupIcon from "@presentation/components/Icons/GroupIcon";
import RemoveUsersReportModal from "@presentation/components/RemoveUsersReportModal";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { removeUsersToReport } from "@adapters/store/reports/thunk";
import { getReportsUserIsPending } from "@adapters/store/reports/slice";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { TotalModal } from "@presentation/components/TotalModal";

interface ReportUsersProps extends BoxProps {
  report: Report | null;
  onClose?: () => void;
}

const ReportUsersListMenuModal = (props: ReportUsersProps): JSX.Element => {
  const theme = useTheme();
  const { report, ref, onClose } = props;

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const nbUsers = report?.users?.length || 0;
  const usersLength = report?.users?.length || -1;
  const isReportUserPending = useAppSelector(getReportsUserIsPending);
  const [requestMade, setRequestMade] = useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = useCallback((): void => {
    setOpen(false);
    if (onClose) onClose();
  }, [setOpen, onClose]);

  useEffect(() => {
    if (isReportUserPending) {
      setRequestMade(true);
    }
  }, [handleClose, isReportUserPending, requestMade]);

  useEffect(() => {
    setRequestMade(false);
  }, []);

  const { t } = useTranslation();

  return (
    <Box>
      <Box
        ref={ref}
        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
        onClick={handleClickOpen}
      >
        <GroupIcon
          sx={{ color: theme.palette.secondary.main }}
          style={{ height: "16px", width: "18px", marginRight: "12px", alignItems: "flex-start" }}
        />
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          {t("report_list_users_modal.title")}
        </Typography>
      </Box>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h1" sx={{ color: theme.palette.primary.main, my: "40px" }}>
              {t("report_list_users_modal.shared_with", { count: nbUsers })}
            </Typography>
          </DialogContent>

          {report?.users && usersLength > 0 && (
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: "60%",
                  maxHeight: "200px",
                  display: "flex",
                  borderRadius: "15px",
                  flexDirection: "column",
                  overflow: "auto",
                  mb: "40px",
                  border: "1px solid #B7CBD3",
                }}
              >
                {report.users.map((user, idx) => {
                  return (
                    <Box
                      key={`report-modal-${idx}${user.id}`}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: idx !== usersLength - 1 ? 1 : 0,
                        borderColor: theme.custom?.lineColor?.main,
                        p: 1,
                      }}
                    >
                      <Typography variant="summaryTableHead" sx={{ overflow: "auto" }}>
                        {user.firstName} {user.lastName}
                      </Typography>
                      {isReportUserPending === true ? <CircularProgress size={20} /> : <></>}
                      {report?.users && usersLength > 0 && isReportUserPending === false && (
                        <RemoveUsersReportModal
                          user={user}
                          handleClick={async () => {
                            await dispatch(removeUsersToReport({ reportId: report?.id, users: [user.id] }));
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </DialogActions>
          )}
        </Box>
      </TotalModal>
    </Box>
  );
};

export default ReportUsersListMenuModal;
