import { Box, Button, Menu, MenuItem, MenuProps, Typography, useTheme } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import { AcceptableChildren } from "@core/utils/CommonTypes";

const StyledButton = styled(Button)(() => {
  const theme = useTheme();
  return {
    "&.MuiButton-text": {
      color: theme.palette.primary.main,
      textTransform: "none",
      "&:hover": {
        color: "red",
        backgroundColor: "white",
      },
    },
  };
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(() => {
  const theme = useTheme();
  return {
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 180,
      color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.secondary.main,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  };
});

const NavbarButton = (props: {
  label: string;
  route: string;
  selected?: string;
  id?: string;
  parentId?: string;
  children?: AcceptableChildren;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}): React.JSX.Element => {
  const theme = useTheme();
  const { label, route, selected, children, onClick } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hover, setHover] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isSelectedOrHover = (): boolean => {
    return selected === label.toLocaleLowerCase() || hover || !!(open && children);
  };

  const button = (
    <StyledButton
      value={label}
      key={`headers-${label}`}
      sx={{
        borderRadius: 0,
        borderBottom: 3,
        borderColor: isSelectedOrHover() ? theme.palette.secondary.main : "white",
        mx: 1,
        pl: "1px",
        [theme.breakpoints.down("md")]: {
          pb: "16px",
        },
        [theme.breakpoints.up("md")]: {
          pb: "14px",
        },
        lineHeight: "14px",
        flexDirection: "row",
        textDecoration: "none",
        justifyContent: "flex-start",
      }}
      id={`button-${label}`}
      aria-controls={open ? `menu-${label}` : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      {...props}
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          maring: 0,
          [theme.breakpoints.down("md")]: {
            fontSize: "16px",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "14px",
          },
          textDecoration: hover ? "underline" : "none",
        }}
        variant={isSelectedOrHover() ? "navButtonSelected" : "menu1"}
      >
        {label}
      </Typography>
      {children && (
        <ArrowDownIcon
          sx={{ color: theme.palette.secondary.main }}
          style={{ marginLeft: "16px", transition: "200ms", transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      )}
    </StyledButton>
  );

  const menu = (
    <StyledMenu
      id={`menu-${label}`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{ marginTop: "6px", borderRadius: "18px", width: "320px" }}
    >
      {React.Children.toArray(children).map((child: React.ReactNode) => {
        const childKey = (child as React.JSX.Element)?.key ?? "";
        return (
          <MenuItem
            key={`dropdown-child-${childKey}`}
            sx={{ width: "100%", padding: 0, "& a": { padding: "6px 16px 6px 16px" } }}
            onClick={() => {
              (child as React.JSX.Element)?.props?.onClick();
              handleClose();
            }}
            disableRipple
          >
            {child}
          </MenuItem>
        );
      })}
    </StyledMenu>
  );

  // Difference between a menu button and redirect button
  if (children) {
    return (
      <Box style={{ marginLeft: "1px" }}>
        {button}
        {menu}
      </Box>
    );
  } else {
    return (
      <Link key={route} to={route} style={{ textDecoration: "none", marginLeft: "1px" }} onClick={onClick}>
        {button}
      </Link>
    );
  }
};

export default NavbarButton;
