import { GroupData } from "@domain/entities/Groups";
import { Report } from "@domain/entities/Reports";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import UserRoleTag from "@presentation/components/UserRoleTag";
import { Link } from "react-router-dom";
import { ScopeColumn } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";

interface SummaryScopeProps {
  firstName: string;
  lastName: string;
  organisation: string;
  department: string;
  isInternal: boolean;
  selectedGroups: GroupData[];
  selectedScopes: ScopeColumn[];
  selectedReports: number[];
  reportsList: Report[];
}

const SummaryUser = (props: SummaryScopeProps): JSX.Element => {
  const theme = useTheme();
  const {
    firstName,
    lastName,
    organisation,
    department,
    isInternal,
    selectedGroups,
    selectedScopes,
    selectedReports,
    reportsList,
  } = props;

  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 1, py: "15px", justifyContent: "space-between" }}>
        <Box
          sx={{
            minWidth: "45%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("users_create_update.summary.profile_title")}</Typography>
          </Box>
          <Box sx={{ padding: "15px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("users_create_update.summary.last_name_field_title")}</b> {lastName || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("users_create_update.summary.first_name_field_title")}</b> {firstName || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("users_create_update.summary.organization_field_title")}</b> {organisation || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("users_create_update.summary.department_field_title")}</b> {department || "--"}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("users_create_update.summary.is_internal_field_title")}</b>{" "}
              {isInternal ? t("common.yes") : t("common.no")}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ minWidth: "49%", borderRadius: "15px", overflow: "hidden", border: "1px solid #B7CBD3" }}>
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("users_create_update.summary.member_of_groups")}</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {t("users_create_update.summary.member_of_groups_count", { count: selectedGroups.length })}
            </Typography>
          </Box>
          <Box sx={{ padding: "15px", display: "flex", flexDirection: "column" }}>
            {selectedGroups.map((x) => {
              return (
                <Link
                  key={`user_group_${x.id}`}
                  to={`/groups/${x.id}`}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    key={x.id}
                    sx={{ fontSize: "14px", fontWeight: 400, display: "inline-flex", alignContent: "center" }}
                  >
                    <b>{x.groupName}</b>
                    <CallMadeOutlinedIcon
                      sx={{
                        color: theme.palette.secondary.main,
                        height: "14px",
                        translate: "-2px 5px",
                      }}
                    />
                  </Typography>
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "15px", width: "100%" }}>
        <Box
          sx={{
            minWidth: "45%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("users_create_update.summary.scopes_access")}</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {t("users_create_update.summary.scopes_access_count", { count: selectedScopes.length })}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {selectedScopes.map((x: ScopeColumn, idx: number) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "50%",
                    height: "49px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    rowGap: "10px",
                  }}
                >
                  <Link
                    key={`user_scope_${x.scopeId}`}
                    to={`/scopes/${x.scopeId}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      key={idx}
                      variant="backButton"
                      sx={{
                        display: "inline-flex",
                        alignContent: "center",
                      }}
                    >
                      {x.scopeName}
                      <CallMadeOutlinedIcon
                        sx={{
                          color: theme.palette.secondary.main,
                          height: "14px",
                          translate: "-2px 5px",
                        }}
                      />
                    </Typography>
                  </Link>
                  {x.scopePermissionId && x.scopePermission && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <UserRoleTag
                        role={x.scopePermissionId}
                        user={x.scopePermission}
                        sx={{ font: theme.typography.summaryChip, height: "25px" }}
                      />
                      {(idx + 1) % 2 !== 0 && (
                        <Divider
                          sx={{ bgcolor: theme?.custom?.lineColor?.main, height: "39px", mx: "24px" }}
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "15px", width: "100%" }}>
        <Box
          sx={{
            minWidth: "45%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("users_create_update.summary.report_access")}</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {t("users_create_update.summary.report_access_count", { count: selectedReports.length })}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {selectedReports.map((x: number, idx: number) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "50%",
                    height: "42px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link
                    key={`user_report_${x}`}
                    to={`/reports/${x}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        display: "inline-flex",
                        alignContent: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {reportsList.find((r) => r.id === x)?.reportName}
                      <CallMadeOutlinedIcon
                        sx={{
                          color: theme.palette.secondary.main,
                          height: "14px",
                          translate: "-2px 5px",
                        }}
                      />
                    </Typography>
                  </Link>
                  {(idx + 1) % 2 !== 0 && (
                    <Divider
                      sx={{ bgcolor: theme?.custom?.lineColor?.main, height: "42px", mx: "24px" }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryUser;
