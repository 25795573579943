import { SvgIcon, SvgIconProps } from "@mui/material";

const DownloadIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  sx = { height: "16px", width: "16px", ...sx };
  return (
    <SvgIcon sx={sx} {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2071 10.7071L13.2071 15.7071C12.8166 16.0976 12.1834 16.0976 11.7929 15.7071L6.79289 10.7071C6.40237 10.3166 6.40237 9.68342 6.79289 9.29289C7.18342 8.90237 7.81658 8.90237 8.20711 9.29289L11.5 12.5858V3C11.5 2.44771 11.9477 2 12.5 2C13.0523 2 13.5 2.44771 13.5 3V12.5858L16.7929 9.29289C17.1834 8.90237 17.8166 8.90237 18.2071 9.29289C18.5976 9.68342 18.5976 10.3166 18.2071 10.7071ZM4 15C4 14.4477 3.55228 14 3 14C2.44772 14 2 14.4477 2 15V19C2 20.6569 3.34315 22 5 22H20C21.6569 22 23 20.6569 23 19V15C23 14.4477 22.5523 14 22 14C21.4477 14 21 14.4477 21 15V19C21 19.5523 20.5523 20 20 20H5C4.44772 20 4 19.5523 4 19V15Z"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIcon;
