import { Box, Typography, Chip, BoxProps, useTheme, IconButton, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { Report } from "@domain/entities/Reports";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getUsers, getUsersIsPending } from "@adapters/store/users/slice";
import { listUsers } from "@adapters/store/users/thunk";
import TotalSearchBar from "@presentation/components/TotalSearchBar";
import { User } from "@domain/entities/Users";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import TotalButton from "@presentation/components/TotalButton";
import { updateUsersToReport } from "@adapters/store/reports/thunk";
import { getReportsError } from "@adapters/store/reports/slice";
import { useTranslation } from "react-i18next";
import { TotalModal } from "@presentation/components/TotalModal";
import CloseIcon from "@mui/icons-material/Close";

interface ReportUsersProps extends BoxProps {
  report: Report | null;
  onClose?: () => void;
}

const ReportShareUsersModal = (props: ReportUsersProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { report, ref, onClose } = props;
  const [open, setOpen] = useState(false);
  const [userSelect, setUserSelect] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const usersList = useAppSelector(getUsers);
  const usersIsPending = useAppSelector(getUsersIsPending);
  const error = useAppSelector(getReportsError);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSelect = (event: React.SyntheticEvent, newValue: any): void => {
    setUserSelect("");
    if (!newValue) return;
    if (typeof newValue === "string") {
      return;
    }
    const newArr = [...selectedUsers];
    const valueArr = newArr.map(function (item: User) {
      return item.id;
    });
    const isDuplicate = valueArr.some((item: number) => {
      return item === newValue.id;
    });
    if (!isDuplicate) {
      newArr.push(newValue);
      setSelectedUsers(newArr);
    }
  };

  const handleUserRemove = (idx: number): void => {
    const newArr: User[] = [...selectedUsers];

    newArr.splice(idx, 1);
    setSelectedUsers(newArr);
  };

  useEffect(() => {
    if (!usersIsPending) {
      setSelectedUsers(usersList.filter((x: User) => report?.users?.find((z) => z.id === x.id)));
    }
  }, [usersIsPending, usersList, report]);

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <Box>
      <Box
        ref={ref}
        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer", verticalAlign: "middle" }}
        onClick={handleClickOpen}
      >
        <ShareIcon
          sx={{ color: theme.palette.secondary.main }}
          style={{ height: "16px", width: "18px", marginRight: "12px", alignItems: "flex-start" }}
        />
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          {t("share_report_modal.share")}
        </Typography>
      </Box>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h1" sx={{ color: theme.palette.primary.main, mt: "32px", mb: "24px" }}>
              {t("share_report_modal.share_report", { reportName: report?.reportName })}
            </Typography>
            <Box
              sx={{
                width: "80%",
                minHeight: "200px",
                maxHeight: "320px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: "16px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TotalSearchBar
                  value={userSelect}
                  blurOnSelect={true}
                  label={t("search_bar.user")}
                  options={usersList}
                  onChange={handleSelect}
                  sx={{ width: "100%", mb: "8px" }}
                />
                <Box sx={{ display: "flex", mb: "16px" }}>
                  <Typography variant="body2">
                    {t("share_report_modal.user_not_find_question")}{" "}
                    <Link to="/users/create" target="_blank" rel="noreferrer">
                      <b style={{ color: theme.palette.primary.main }}>{t("share_report_modal.create_user")}</b>
                    </Link>
                    <OpenInNewOutlinedIcon
                      sx={{ color: theme.palette.secondary.main, height: "14px", translate: "-2px 3px" }}
                    />
                  </Typography>
                </Box>
              </Box>
              {selectedUsers && (
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    borderRadius: "8px",
                    backgroundColor: theme.custom?.backgroundColor?.report,
                    flexWrap: "wrap",
                    p: 1,
                    gap: 1,
                    border: "1px solid #B7CBD3",
                  }}
                >
                  {selectedUsers.length > 0 &&
                    selectedUsers.map((x: User, idx: number) => {
                      return (
                        <Chip
                          sx={{ backgroundColor: "white", border: "1px solid #B7CBD3", height: "20px" }}
                          onDelete={() => handleUserRemove(idx)}
                          key={"chip" + idx}
                          deleteIcon={
                            <CloseOutlinedIcon
                              sx={{ color: theme.palette.secondary.main, height: "20px", cursor: "pointer", mb: 0.5 }}
                            />
                          }
                          label={x.firstName + " " + x.lastName}
                        />
                      );
                    })}
                </Box>
              )}
            </Box>
          </DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", mb: "40px" }}>
            <TotalButton
              id="validate-share-report-button"
              height={22}
              width="50%"
              text={t("share_report_modal.confirm")}
              onClick={() => {
                if (!report || selectedUsers.length < 1) return;
                dispatch(
                  updateUsersToReport({
                    reportId: report.id,
                    users: selectedUsers.map((x) => x.id),
                  })
                );
                if (!error) {
                  handleClose();
                }
              }}
              primary
            ></TotalButton>
          </Box>
        </Box>
      </TotalModal>
    </Box>
  );
};

export default ReportShareUsersModal;
