import { SvgIcon, SvgIconProps } from "@mui/material";

export const StarFilledIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.897 1.55743C12.7285 1.2161 12.3809 1 12.0002 1C11.6196 1 11.2719 1.2161 11.1035 1.55743L8.10334 7.6364L1.39481 8.61121C1.01812 8.66594 0.705172 8.92978 0.587547 9.2918C0.469922 9.65381 0.568019 10.0512 0.840592 10.3169L5.69493 15.0487L4.54898 21.7301C4.48463 22.1053 4.63886 22.4845 4.9468 22.7082C5.25475 22.9319 5.66301 22.9614 5.99993 22.7843L12.0002 19.6298L18.0005 22.7843C18.3374 22.9614 18.7457 22.9319 19.0536 22.7082C19.3616 22.4845 19.5158 22.1053 19.4515 21.7301L18.3055 15.0487L23.1599 10.3169C23.4324 10.0512 23.5305 9.65381 23.4129 9.2918C23.2953 8.92978 22.9823 8.66594 22.6056 8.61121L15.8971 7.6364L12.897 1.55743Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default StarFilledIcon;
