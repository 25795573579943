import { Alert, Box, Breadcrumbs, Button, Divider, Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TotalSelect from "@presentation/components/TotalSelect";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useNavigate } from "react-router-dom";
import TotalSearchBar from "@presentation/components/TotalSearchBar";
import { User } from "@domain/entities/Users";
import UserField from "@presentation/components/UserField";
import { listScopes } from "@adapters/store/scopes/thunk";
import { getAdminScopes } from "@adapters/store/scopes/slice";
import ScopePermissionField from "@presentation/components/ScopePermissionField";
import ReportCollapsibleTable from "@presentation/components/ReportCollapsibleTable";
import { getReports } from "@adapters/store/reports/slice";
import { getUsers } from "@adapters/store/users/slice";
import { listUsers } from "@adapters/store/users/thunk";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import SummaryGroup from "@presentation/components/groups/SummaryGroupCreateUpdate";
import { createGroup, listGroups } from "@adapters/store/groups/thunk";
import { Group, ScopesAndPermissionsRequest } from "@domain/entities/Groups";
import { Scope } from "@domain/entities/Scopes";
import { Report } from "@domain/entities/Reports";
import { clearGroupsError, getGroups, getGroupsError, getGroupsIsPending } from "@adapters/store/groups/slice";
import { uniq } from "@core/utils/ArrayTools";
import { ColumnChecked, ScopeColumn } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";

const CreateGroup = (): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>("");
  const [userSelect, setUserSelect] = useState<number | null>(-1);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [availableReports, setAvailableReports] = useState<Report[]>([]);
  const [selectedReports, setSelectedReports] = useState<ColumnChecked[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<ScopeColumn[]>([]);
  const [selectedScope, setSelectedScope] = useState<number>(-1);
  const [alreadyExists, setAlreadyExists] = useState<boolean>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getGroupsError);
  const groupsList = useAppSelector(getGroups);
  const usersList = useAppSelector(getUsers);
  const reportsList = useAppSelector(getReports);
  const scopesList = useAppSelector(getAdminScopes);
  const groupsIsPending = useAppSelector(getGroupsIsPending);
  const permissionNames = [t("rights.admin"), t("rights.read_and_edit"), t("rights.read_only")];

  const path = "/groups";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_groups")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("group_create_update.create_title")}
    </Link>,
  ];

  const handleNameChange = (value: string): void => {
    setGroupName(value);
  };

  const handleSelect = (event: React.SyntheticEvent, newValue: any): void => {
    setUserSelect(null);
    if (!newValue) return;
    if (typeof newValue === "string") {
      return;
    }
    const newArr = [...selectedUsers];
    const valueArr = newArr.map(function (item: User) {
      return item.id;
    });
    const isDuplicate = valueArr.some((item: number) => {
      return item === newValue.id;
    });
    if (!isDuplicate) {
      newArr.push(newValue);
      setSelectedUsers(newArr);
    }
  };

  const checkAlreadyExists = (value: string): boolean => {
    return groupsList.some((x: Group) => x.groupName === value.trim());
  };

  const handleUserRemove = (idx: number): void => {
    const newArr: User[] = [...selectedUsers];

    newArr.splice(idx, 1);
    setSelectedUsers(newArr);
  };

  const handleScopeValueChange = (idx: number, value: number, isDuplicate?: boolean): void => {
    let newArr;
    if (isDuplicate) {
      newArr = [...selectedScopes];
      newArr.splice(idx, 0, { ...selectedScopes[idx] });
      newArr[idx + 1].scopePermissionId = value;
      newArr[idx + 1].scopePermission = permissionNames[value - 1];
    } else {
      if (value !== -1) {
        newArr = [...selectedScopes];
        newArr[idx].scopePermissionId = value;
        newArr[idx].scopePermission = permissionNames[value - 1];
      } else {
        newArr = selectedScopes.filter((_x: ScopeColumn, xId: number) => xId !== idx);
      }
    }
    setSelectedScopes(newArr);
  };

  const toggleCheckAllReports = (scopeId: number): void => {
    const table = selectedScopes.find((s) => s.scopeId === scopeId);
    if (table) {
      let newArr;
      if (selectedReports.some((report) => report.scopeId === table.scopeId)) {
        newArr = selectedReports.filter((report) => report.scopeId !== scopeId);
      } else {
        newArr = [...selectedReports];
        availableReports
          .filter((c) => c.scopes?.includes(scopeId))
          .forEach((x: Report) => {
            newArr.push({
              scopeId: table.scopeId,
              scopeName: table.scopeName,
              reportId: x.id,
              reportName: x.reportName,
            });
          });
      }
      setSelectedReports(newArr);
    }
  };

  const toggleCheckSingleReport = (tableId: number, columnId: number): void => {
    const table = selectedScopes.find((s) => s.scopeId === tableId);
    if (table) {
      let newArr;
      if (selectedReports.some((report) => report.scopeId === tableId && report.reportId === columnId)) {
        newArr = selectedReports.filter((report) => report.reportId !== columnId);
      } else {
        newArr = [...selectedReports];
        newArr.push({
          scopeId: table.scopeId,
          scopeName: table.scopeName,
          reportId: columnId,
          reportName: availableReports.filter((c) => c.scopes?.includes(table.scopeId)).find((c) => c.id === columnId)
            ?.reportName,
        });
      }
      setSelectedReports(newArr);
    }
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result =
      !!groupName &&
      !alreadyExists &&
      selectedScopes.length > 0 &&
      selectedScopes.filter((c) => c.scopePermissionId <= 0).length === 0;
    setIsBusy(false);
    return result;
  };

  useEffect(() => {
    dispatch(listScopes());
    dispatch(listUsers());
    dispatch(listReports());
    dispatch(getFavorite());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setUserSelect(null);
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedScope === -1) return;
    const newArr: ScopeColumn[] = [...selectedScopes];
    const tmp = scopesList.find((x) => x.id === selectedScope);
    const isDuplicate = selectedScopes.some((x: ScopeColumn) => x.scopeId === tmp?.id);
    if (isDuplicate) return;
    newArr.push({
      scopeId: tmp?.id || 0,
      scopeName: tmp?.scopeName || "",
      scopePermissionId: 0,
      scopePermission: "",
    });
    setSelectedScopes(newArr);
    const tempReportsList: Report[] = [];
    reportsList.forEach((report) => {
      const tempReport: Report = { ...report };
      const tempReportScopes: number[] = [];
      report.scopes?.forEach((s) => tempReportScopes.push(s));
      scopesList.forEach((scope) => {
        if (tempReportScopes.includes(scope.id)) return;
        if (!scope.datasets.some((dataset) => dataset.id === report.datasetId)) return;
        tempReportScopes.push(scope.id);
      });
      tempReport.scopes = tempReportScopes;
      tempReportsList.push(tempReport);
    });
    setAvailableReports(tempReportsList);
    setSelectedScope(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope]);

  useEffect(() => {
    if (groupName.length < 2) return;
    setAlreadyExists(checkAlreadyExists(groupName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName, userSelect, selectedUsers, availableReports, selectedReports, selectedScopes, selectedScope]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>

      {error && (
        <Alert severity="error" onChange={() => dispatch(clearGroupsError())}>
          {error?.message}
        </Alert>
      )}

      <Typography sx={{ my: "28px", color: "#374649" }} variant="h2">
        {t("group_create_update.create_title")}
      </Typography>
      <Grid container item direction="row" justifyContent="space-between" alignItems="end">
        <Grid container item direction="row" md={8}>
          <TextFieldData
            id="group-name"
            label={t("group_create_update.group_name")}
            value={groupName}
            error={alreadyExists}
            onChange={handleNameChange}
          />
        </Grid>
        <TotalButton
          id="save-new-group-button"
          text={t("group_create_update.save_new_group")}
          icon=""
          height={22}
          disabled={!isValid}
          onClick={async () => {
            const sApArr: ScopesAndPermissionsRequest[] = [];
            const users: number[] = selectedUsers.map((x: User) => x.id);
            selectedScopes.forEach((x: ScopeColumn) =>
              sApArr.push({ scopeId: x.scopeId, permissionId: x.scopePermissionId })
            );
            if (groupsIsPending) return;
            dispatch(clearGroupsError());
            await dispatch(
              createGroup({
                groupName: groupName.trim(),
                users: uniq(users),
                scopesAndPermissions: uniq(sApArr),
                reports: uniq(selectedReports.map((x: ColumnChecked) => x.reportId)),
              })
            );
            dispatch(listGroups());
            if (!error) navigate(path);
          }}
        />
      </Grid>
      <CollapsibleCard title={t("group_create_update.1_users_in_group")} sx={{ mt: 3 }} isExpanded={true}>
        <TotalSearchBar
          value={userSelect}
          blurOnSelect={true}
          label={t("search_bar.user")}
          options={usersList}
          onChange={handleSelect}
          sx={{ mb: 2, width: "50%" }}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
          {selectedUsers.map((x: User, idx: number) => {
            return (
              <UserField
                key={x.id + "-" + x.profile.firstName + "-" + x.profile.lastName}
                className={x.id + "-" + x.profile.firstName + "-" + x.profile.lastName}
                user={x}
                identifier={idx}
                handleValue={handleUserRemove}
              />
            );
          })}
        </Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("group_create_update.2_scopes_and_permissions")} sx={{ mt: 1 }}>
        <TotalSelect
          label={t("group_create_update.select_scope")}
          items={scopesList.map((x: Scope) => ({ id: x.id, optionName: x.scopeName }))}
          sx={{ minWidth: "350px" }}
          onChange={(e: number) => setSelectedScope(e)}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
          {selectedScopes.map((x: ScopeColumn, idx: number) => {
            return (
              <ScopePermissionField
                key={x.scopeId + "-" + x.scopeName}
                className={x.scopeId + "-" + x.scopeName}
                scope={x}
                scopeIdx={idx}
                permissionId={x.scopePermissionId}
                handleValue={(idxa: number, e: number) => handleScopeValueChange(idxa, e)}
              />
            );
          })}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}></Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("group_create_update.3_report_access")} sx={{ mt: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
          {selectedScopes.map((x: ScopeColumn, idx: number) => {
            return (
              <ReportCollapsibleTable
                key={"report-table-" + idx}
                title={x.scopeName}
                tableId={x.scopeId}
                items={availableReports.filter((c) => c.scopes?.includes(x.scopeId))}
                checkedItems={selectedReports.filter((c) => c.scopeId === x.scopeId).map((c) => c.reportId)}
                toggleCheckColumn={(tableId: number, columnId: number) => toggleCheckSingleReport(tableId, columnId)}
                toggleCheckAll={(tableId: number) => toggleCheckAllReports(tableId)}
              />
            );
          })}
        </Box>
      </CollapsibleCard>
      <CollapsibleCard title={t("group_create_update.4_summary")} sx={{ mt: 1 }}>
        <SummaryGroup users={selectedUsers} scopes={selectedScopes} reports={selectedReports}></SummaryGroup>
      </CollapsibleCard>
    </Grid>
  );
};

export default CreateGroup;
