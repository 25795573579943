import {
  createMaintenance,
  deleteMaintenance,
  listMaintenances,
  updateMaintenance,
  actualMaintenance,
} from "@adapters/store/maintenances/thunk";
import { toaster } from "@core/Toaster";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { message, sortAsc } from "@core/utils/StringTools";
import { Maintenance } from "@domain/entities/Maintenance";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface MaintenancesState {
  list: Maintenance[];
  pending: boolean;
  error: ErrorState | null;
  actual: Maintenance | null;
}

// Define the initial state using that type
const initialState: MaintenancesState = {
  list: [],
  pending: false,
  error: null,
  actual: null,
};

const subject = "The maintenance";

export const maintenancesSlice = createSlice({
  name: "maintenances",
  initialState,
  reducers: {
    clearMaintenancesError: (state: MaintenancesState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST MAINTENANCES
    builder.addCase(listMaintenances.fulfilled, (state, action) => {
      state.list = action.payload;
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listMaintenances.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listMaintenances");
      state.pending = false;
    });
    builder.addCase(listMaintenances.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE MAINTENANCE
    builder.addCase(deleteMaintenance.fulfilled, (state, action) => {
      toaster.success(message.success.deleted(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteMaintenance.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteMaintenance");
      state.pending = false;
    });
    builder.addCase(deleteMaintenance.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE MAINTENANCE
    builder.addCase(createMaintenance.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list.sort((a, b) => sortAsc(a.title, b.title));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createMaintenance.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createMaintenance");
      state.pending = false;
    });
    builder.addCase(createMaintenance.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE MAINTENANCE
    builder.addCase(updateMaintenance.fulfilled, (state, action) => {
      toaster.success(message.success.updated(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg.id);
      state.list.push(action.payload);
      state.list.sort((a, b) => sortAsc(a.title, b.title));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateMaintenance.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateMaintenance");
      state.pending = false;
    });
    builder.addCase(updateMaintenance.pending, (state, _action) => {
      state.pending = true;
    });
    // ACTUAL MAINTENANCE
    builder.addCase(actualMaintenance.fulfilled, (state, action) => {
      state.pending = false;
      state.actual = action.payload;
    });
    builder.addCase(actualMaintenance.rejected, (state, _action) => {
      state.pending = false;
      state.actual = null;
    });
    builder.addCase(actualMaintenance.pending, (state, _action) => {
      state.actual = null;
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
// export const { clearGroupsError } = groupsSlice.actions;

// Define selectors to be used in UI here
export const getMaintenancesCount = (state: RootState): number => state.maintenances.list.length;
export const getMaintenances = (state: RootState): Maintenance[] => state.maintenances.list;
export const getMaintenancesError = (state: RootState): ErrorState | null => state.maintenances.error;
export const getMaintenancesIsPending = (state: RootState): boolean => state.maintenances.pending;
export const getActualMaintenance = (state: RootState): Maintenance | null => state.maintenances.actual;
