import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import DeleteModal from "@presentation/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import TableDivider from "@presentation/components/table/TableDivider";
import { Subscription } from "@domain/entities/Subscriptions";
import { deleteSubscription } from "@adapters/store/subscriptions/thunk";
import { getSubscriptionsIsPending } from "@adapters/store/subscriptions/slice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SubscriptionReportsListModal from "@presentation/components/SubscriptionReportsListModal";

interface ITotalSubscriptionsRow extends TableRowProps {
  row: Subscription;
}

const TotalSubscriptionRow = ({ row }: ITotalSubscriptionsRow): React.JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [timeFormat] = useState("DD/MM/YYYY");

  const isPending = useAppSelector(getSubscriptionsIsPending);

  const navigate = useNavigate();

  return (
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <b>{row.subscriptionName}</b>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <b>{row.reports.length}</b>
          {row?.reports?.length > 0 && <SubscriptionReportsListModal reports={row.reports} />}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <b>
            {(() => {
              switch (row.regularity) {
                case "EVERY_WEEK":
                  return t("subscription_create_update.regularity_every_week");
                case "EVERY_OTHER_WEEK":
                  return t("subscription_create_update.regularity_every_other_week");
                case "EVERY_MONTH":
                  return t("subscription_create_update.regularity_every_month");
                default:
                  return null;
              }
            })()}
          </b>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.startDate.format(timeFormat)}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.endDate.format(timeFormat)}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.createdAt.format(timeFormat)}</b>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 20px 5px 20px",
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.secondary.main,
                height: "22px",
                "&:hover": {
                  background: "none",
                },
              }}
              onClick={() => navigate("/subscriptions/" + row.id)}
            >
              <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </IconButton>
          </Box>
          <TableDivider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 5px 5px 20px",
            }}
          >
            <DeleteModal
              type="subscription"
              handleClick={async () => {
                await dispatch(deleteSubscription(row.id));
              }}
              pending={isPending}
            />
          </Box>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TotalSubscriptionRow;
