import { SvgIcon, SvgIconProps } from "@mui/material";

const LoopIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 7.50016C3.33333 5.10693 5.27343 3.16683 7.66667 3.16683C10.0599 3.16683 12 5.10693 12 7.50016C12 9.8934 10.0599 11.8335 7.66667 11.8335C5.27343 11.8335 3.33333 9.8934 3.33333 7.50016ZM7.66667 1.8335C4.53705 1.8335 2 4.37055 2 7.50016C2 10.6298 4.53705 13.1668 7.66667 13.1668C8.99125 13.1668 10.2097 12.7124 11.1745 11.9508L13.5286 14.3049C13.7889 14.5653 14.2111 14.5653 14.4714 14.3049C14.7318 14.0446 14.7318 13.6224 14.4714 13.3621L12.1174 11.008C12.8789 10.0432 13.3333 8.82474 13.3333 7.50016C13.3333 4.37055 10.7963 1.8335 7.66667 1.8335Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default LoopIcon;
