import { Subscription, SubscriptionResponse } from "@domain/entities/Subscriptions";
import dayjs from "dayjs";

export class SubscriptionMapper {
  static toEntity(subscriptionResponse: SubscriptionResponse): Subscription {
    return {
      id: subscriptionResponse.id,
      userId: subscriptionResponse.userId,
      subscriptionName: subscriptionResponse.subscriptionName,
      startDate: dayjs(subscriptionResponse.startDate),
      endDate: dayjs(subscriptionResponse.endDate),
      regularity: subscriptionResponse.regularity,
      createdAt: dayjs(subscriptionResponse.createdAt),
      updatedAt: dayjs(subscriptionResponse.updatedAt),
      reports: subscriptionResponse.reports,
      reportsLength: subscriptionResponse.reports.length,
    };
  }
}
