import { useTheme } from "@mui/material";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import ArrowSortIcon from "@presentation/components/Icons/ArrowSortIcon";
import ArrowUpIcon from "@presentation/components/Icons/ArrowUpIcon";

export const StyledArrowUpIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <ArrowUpIcon
      sx={{ fill: theme.palette.secondary.main }}
      style={{ height: "12px", width: "12px", marginLeft: "12px" }}
    />
  );
};

export const StyledArrowDownIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <ArrowDownIcon
      sx={{ fill: theme.palette.secondary.main }}
      style={{ height: "12px", width: "12px", marginLeft: "12px" }}
    />
  );
};

export const StyledArrowSortIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <ArrowSortIcon
      sx={{ fill: theme.palette.primary.main }}
      style={{ height: "12px", width: "12px", marginLeft: "12px" }}
    />
  );
};
