import { Dataset } from "@domain/entities/Datasets";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Chip,
  useTheme,
} from "@mui/material";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { ColumnChecked } from "@presentation/views/CreateScope.view";
import { ParentRole, RoleByDataset } from "@presentation/views/ViewScope.view";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SummaryScopeProps {
  scopeType?: string;
  providerType?: string;
  parentRoles?: RoleByDataset[];
  parentScope?: string;
  scopeName: string;
  datasets: Dataset[];
  values: ColumnChecked[];
}

const SummaryScope = (props: SummaryScopeProps): JSX.Element => {
  const theme = useTheme();
  const { providerType, values, datasets, scopeName, parentRoles } = props;

  const { t } = useTranslation();

  const getRolesTotalLength = (table: RoleByDataset): number => {
    if (parentRoles) {
      const pRoles = parentRoles.find((x: RoleByDataset) => x.datasetName === table.datasetName);
      if (pRoles)
        return (
          pRoles.roles.length +
          values.filter(
            (col) => col.datasetName === table.datasetName && !pRoles.roles.some((r) => r.id === col.roleId)
          ).length
        );
    }
    return values.filter((col) => col.datasetName === table.datasetName).length;
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 1, py: "15px", justifyContent: "space-between", gap: "10%" }}>
        <Box
          sx={{
            minWidth: "100%",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              padding: "15px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("scope_create_update.summary.scope_title")}</Typography>
          </Box>
          <Box sx={{ padding: "15px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("scope_create_update.summary.scope_name_field_title")}</b> {scopeName}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("scope_create_update.summary.provider_type_field_title")}</b> {providerType}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "15px" }}>
        <Box
          sx={{
            minWidth: "30%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              padding: "15px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "16px" }}>{t("scope_create_update.summary.roles_summary_title")}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {parentRoles?.map((table, idx) => {
              return (
                <TableContainer
                  key={idx}
                  sx={{
                    width: "48%",
                    my: "15px",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.custom?.lineColor?.main,
                  }}
                >
                  <Table key={idx + "-table"}>
                    <TableHead>
                      <TableRow
                        key={table.id + "-row"}
                        sx={{
                          background: theme.custom?.backgroundColor?.title,
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        <TableCell colSpan={2}>
                          <Typography sx={{ fontSize: "16px" }}>{table.datasetName}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: "14px" }}>
                            <Chip
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "16px",
                                color: "white",
                                height: "18px",
                              }}
                              label={getRolesTotalLength(table)}
                              component="span"
                            />{" "}
                            {t("scope_create_update.summary.roles_included", {
                              count: getRolesTotalLength(table),
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table?.roles?.map((role: ParentRole, idxa: number) => {
                        return (
                          <TableRow key={idx + "-" + idxa}>
                            <TableCell colSpan={3}>
                              <Link
                                key={`scope_parentrole_${role.id}`}
                                to={`/roles/${role.id}`}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    display: "inline-flex",
                                    alignContent: "center",
                                  }}
                                >
                                  {role.roleName}
                                  <CallMadeOutlinedIcon
                                    sx={{
                                      color: theme.palette.secondary.main,
                                      height: "14px",
                                      translate: "-2px 5px",
                                    }}
                                  />
                                </Typography>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {values &&
                        values.filter(() => datasets.map((c: Dataset) => c.id).includes(table.id)) &&
                        values
                          .filter((col) => col.datasetId === table.id)
                          .map((col, idxa) => {
                            if (table.roles.some((r) => r.id === col.roleId)) return <></>;
                            return (
                              <TableRow key={idx + "-" + idxa + 1}>
                                <TableCell
                                  colSpan={3}
                                  sx={{
                                    border: 0,
                                    borderTopWidth: "1px",
                                    borderTopStyle: "solid",
                                    borderTopColor: theme.custom?.lineColor?.main,
                                  }}
                                >
                                  <Link
                                    key={`scope_role_${col.roleId}`}
                                    to={`/roles/${col.roleId}`}
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        display: "inline-flex",
                                        alignContent: "center",
                                      }}
                                    >
                                      {col.roleName}
                                      <CallMadeOutlinedIcon
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          height: "14px",
                                          translate: "-2px 5px",
                                        }}
                                      />
                                    </Typography>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })}
            {values
              ?.filter((v: ColumnChecked) => !parentRoles?.some((p: RoleByDataset) => p.datasetName === v.datasetName))
              .filter((e, idx, array) => array.findIndex((b) => b.datasetName === e.datasetName) === idx)
              .map((table, idx) => {
                const nbRoles = values.filter((col) => col.datasetId === table.datasetId).length;
                return (
                  <TableContainer
                    key={idx}
                    sx={{
                      width: "48%",
                      my: "15px",
                      borderRadius: "8px",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: theme.custom?.lineColor?.main,
                    }}
                  >
                    <Table key={idx + "-table"}>
                      <TableHead>
                        <TableRow
                          key={table.datasetId + "-row-" + table.roleId}
                          sx={{
                            background: theme.custom?.backgroundColor?.title,
                            borderRadius: "8px 8px 0 0",
                          }}
                        >
                          <TableCell colSpan={2}>
                            <Typography sx={{ fontSize: "16px" }}>{table.datasetName}</Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ fontSize: "14px" }}>
                            <Typography sx={{ fontSize: "14px" }}>
                              <Chip
                                sx={{
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: "16px",
                                  color: "white",
                                  height: "18px",
                                }}
                                label={nbRoles}
                                component="span"
                              />{" "}
                              {t("scope_create_update.summary.selected_roles_count", { count: nbRoles })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values &&
                          values.filter(() => datasets.map((c: Dataset) => c.id).includes(table.datasetId)) &&
                          values
                            .filter((col) => col.datasetId === table.datasetId)
                            .map((col, idxa) => {
                              return (
                                <TableRow key={idx + "-" + idxa + 1}>
                                  <TableCell
                                    colSpan={3}
                                    sx={{
                                      border: 0,
                                      borderTopWidth: "1px",
                                      borderTopStyle: "solid",
                                      borderTopColor: theme.custom?.lineColor?.main,
                                    }}
                                  >
                                    <Link
                                      key={`scope_valuerole_${col.roleId}`}
                                      to={`/roles/${col.roleId}`}
                                      style={{ textDecoration: "none" }}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                          display: "inline-flex",
                                          alignContent: "center",
                                        }}
                                      >
                                        {col.roleName}
                                        <CallMadeOutlinedIcon
                                          sx={{
                                            color: theme.palette.secondary.main,
                                            height: "14px",
                                            translate: "-2px 5px",
                                          }}
                                        />
                                      </Typography>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryScope;
