import Order from "@domain/Interfaces/Order.d";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import dayjs from "dayjs";

/**
 * A generic function that compares two objects of the same type based on a common property.
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 * @param orderBy - The property name to order by.
 * @returns A number indicating the order: -1 if `b` should come before `a`, 1 if `a` should come before `b`, and 0 if they are equivalent.
 */
export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T): number => {
  if (dayjs.isDayjs(a[orderBy]) && dayjs.isDayjs(b[orderBy])) {
    const aDate = a[orderBy] as dayjs.Dayjs;
    const bDate = b[orderBy] as dayjs.Dayjs;
    if (aDate.isBefore(bDate)) {
      return -1;
    } else {
      return 1;
    }
  }
  if (typeof b[orderBy] === "string" && typeof a[orderBy] === "string") {
    const bStr = b[orderBy] as string;
    const aStr = a[orderBy] as string;
    return bStr.toLowerCase() < aStr.toLowerCase() ? -1 : 1;
  }
  return b[orderBy] < a[orderBy] ? -1 : 1;
};

type TComparatorAB<Key extends keyof any> = { [key in Key]: number | string | dayjs.Dayjs };

/**
 * A higher-order function that creates a comparator function based on the order and property name.
 * @param order - The order direction (ascending or descending).
 * @param orderBy - The property name to order by.
 * @returns A comparator function that can be used to sort objects based on the specified property and order.
 */
export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((a: TComparatorAB<Key>, b: TComparatorAB<Key>) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

/**
 * Sorts an array of items stably using a provided comparator function. This method provides stability even
 * if the native JavaScript sort does not guarantee it (as in some older browsers).
 * @param array - The array of items to be sorted.
 * @param comparator - A function that defines the sort order.
 * @returns A new array containing the sorted items.
 */
export const stableSort = <T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

/**
 * Calculates the number of empty rows that should be displayed on a table page.
 * @param page - The current table page.
 * @param rowsPerPage - The number of rows to display per page.
 * @param rowCount - The total number of rows/items.
 * @returns The number of empty rows to be rendered for maintaining the layout.
 */
export const calculateNumberOfEmptyRows = (page: number, rowsPerPage: number, rowCount: number): number => {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowCount) : 0;
};

/**
 * Calculates the total number of pages based on the number of rows and the specified number of rows per page.
 * @param rowsPerPage - The number of rows to display on each page.
 * @param rowCount - The total number of rows/items.
 * @returns The total number of pages.
 */
export const getPageNumber = (rowsPerPage: number, rowCount: number): number => {
  return Math.ceil(rowCount / rowsPerPage);
};

export const defaultTableSx: SxProps<Theme> = {
  width: "100%",
  px: "22px",
  borderSpacing: "0px 16px",
  borderCollapse: "separate",
  "& .MuiTableCell-root": {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  "& .MuiTableRow-root": {
    minHeight: "60px",
    maxHeight: "60px",
    height: "60px",
  },
};
