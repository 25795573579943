import { Box, Card, Typography, useTheme } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";
import TotalButton from "@presentation/components/TotalButton";
import TextField from "@presentation/components/inputs/TextField";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Login = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleEmail = (value: string): void => {
    setEmail(value);
  };

  const handlePassword = (value: string): void => {
    setPassword(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#1D3136",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ width: "425px", borderRadius: "16px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", m: "40px" }}>
          <TotalIcon />
          <Typography
            variant="login"
            sx={{
              color: theme.palette.secondary.main,
              paddingLeft: "16px",
            }}
          >
            {t("login_view.title")}
          </Typography>
        </Box>
        <Box sx={{ mx: "40px", mb: "40px" }}>
          <Box sx={{ mb: "24px" }}>
            <TextField
              value={email}
              onChange={handleEmail}
              label={t("login_view.email")}
              placeholder={t("login_view.placeholder.email")}
            ></TextField>
          </Box>
          <Box>
            <TextField
              value={password}
              onChange={handlePassword}
              label={t("login_view.password")}
              type="password"
              placeholder={t("login_view.placeholder.password")}
            ></TextField>
          </Box>
        </Box>
        <Box sx={{ mx: "40px", mb: "40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <TotalButton
            id="custom-login-button"
            text={t("login_view.login")}
            height={22}
            width="100%"
            buttonColor={theme.palette.secondary.main}
          ></TotalButton>
          <Typography variant="menu1" sx={{ mt: "16px" }}>
            {t("login_view.forgot_password")}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default Login;
