import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubscriptionCreateRequest, SubscriptionUpdateRequest } from "@domain/entities/Subscriptions";
import { subscriptionsServices } from "@adapters/api/services/SubscriptionsServices";
import { SubscriptionMapper } from "@adapters/store/subscriptions/mapper";

export const listSubscriptions = createAsyncThunk("subscriptions/list", async (_, { rejectWithValue }) => {
  try {
    const response = await subscriptionsServices.listSubscriptions();
    return response.data.map((m) => SubscriptionMapper.toEntity(m));
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getSubscription = createAsyncThunk(
  "subscriptions/get",
  async (subscriptionId: number, { rejectWithValue }) => {
    try {
      const response = await subscriptionsServices.getSubscription(subscriptionId);
      return SubscriptionMapper.toEntity(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscriptions/create",
  async (subscription: SubscriptionCreateRequest, { rejectWithValue }) => {
    try {
      const response = await subscriptionsServices.createSubscription(subscription);
      return SubscriptionMapper.toEntity(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscriptions/update",
  async (subscription: SubscriptionUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await subscriptionsServices.updateSubscription(subscription);
      return SubscriptionMapper.toEntity(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscriptions/delete",
  async (subscriptionId: number, { rejectWithValue }) => {
    try {
      const response = await subscriptionsServices.deleteSubscription(subscriptionId);
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportLinkedSubscriptions = createAsyncThunk(
  "subscriptions/updateListLinkedReport",
  async ({ reportId, subscriptionIds }: { reportId: number; subscriptionIds: number[] }, { rejectWithValue }) => {
    try {
      const response = await subscriptionsServices.updateReportLinkedSubscriptions(reportId, subscriptionIds);
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
