import { Checkbox, CheckboxProps, SxProps, Theme, useTheme } from "@mui/material";
import CheckboxCheckedIcon from "@presentation/components/Icons/CheckboxCheckedIcon";
import CheckboxPartialIcon from "@presentation/components/Icons/CheckboxPartialIcon";
import CheckboxUncheckedIcon from "@presentation/components/Icons/CheckboxUncheckedIcon";

const TotalCheckbox = ({ sx, ...props }: CheckboxProps): React.JSX.Element => {
  const theme = useTheme();
  const iconSx: SxProps<Theme> = { width: "18px", height: "18px" };
  return (
    <Checkbox
      sx={{
        color: `${props.disabled ? theme.custom.disabled?.main : theme.palette.secondary.main} !important`,
        ...sx,
      }}
      checkedIcon={<CheckboxCheckedIcon sx={iconSx} />}
      icon={<CheckboxUncheckedIcon sx={iconSx} />}
      indeterminateIcon={<CheckboxPartialIcon sx={iconSx} />}
      {...props}
    />
  );
};

export default TotalCheckbox;
