import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TotalTooltip from "@presentation/components/TotalTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";
import TotalSubscriptionsTable from "@presentation/components/subscriptions/TotalSubscriptionsTable";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { listSubscriptions } from "@adapters/store/subscriptions/thunk";
import { getSubscriptions } from "@adapters/store/subscriptions/slice";
import { SubscriptionHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Subscription } from "@domain/entities/Subscriptions";
import dayjs from "dayjs";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import TotalIconButtonSecondary from "@presentation/components/TotalIconButtonSecondary";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";

const Subscriptions = (): JSX.Element => {
  const { t } = useTranslation();
  const headCells: SubscriptionHeadCell[] = [
    {
      id: "subscriptionName",
      numeric: false,
      disablePadding: false,
      label: t("see_subscriptions.headers.subscription"),
      enableSorting: true,
    },
    {
      id: "reportsLength",
      numeric: true,
      disablePadding: false,
      label: t("see_subscriptions.headers.reports"),
      enableSorting: true,
    },
    {
      id: "regularity",
      numeric: false,
      disablePadding: false,
      label: t("see_subscriptions.headers.regularity"),
      enableSorting: true,
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: t("see_subscriptions.headers.startDate"),
      enableSorting: true,
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: t("see_subscriptions.headers.endDate"),
      enableSorting: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: t("see_subscriptions.headers.creationDate"),
      enableSorting: true,
    },
  ];

  function separateRowsWithCurrentDate(rows: Subscription[]): [Subscription[], Subscription[]] {
    const currentDate = dayjs();
    const inferiorDatesRows: Subscription[] = [];
    const superiorDatesRows: Subscription[] = [];

    rows.forEach((row) => {
      if (row.endDate.isBefore(currentDate)) {
        inferiorDatesRows.push(row);
      } else {
        superiorDatesRows.push(row);
      }
    });

    return [inferiorDatesRows, superiorDatesRows];
  }

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getSubscriptions);
  const [pastRows, currentRows] = separateRowsWithCurrentDate(rows);

  useEffect(() => {
    dispatch(listSubscriptions());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("see_subscriptions.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("see_subscriptions.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
      </Box>
      <TotalSubscriptionsTable headCells={headCells} rows={currentRows}></TotalSubscriptionsTable>
      {pastRows.length > 0 && (
        <CollapsibleCard
          title={t("see_subscriptions.past_subscriptions")}
          sx={{ mt: 3 }}
          isExpanded={false}
          cardSx={{ backgroundColor: "transparent", boxShadow: "none" }}
          titleCardSx={{ cursor: "pointer" }}
          contentSx={{ padding: "0px" }}
          dividerAlwaysVisible
          dividerSx={{ mx: 0 }}
          icon={
            <TotalIconButtonSecondary>
              <ArrowDownIcon sx={{ height: "15px", width: "15px" }} />
            </TotalIconButtonSecondary>
          }
        >
          <TotalSubscriptionsTable headCells={headCells} rows={pastRows}></TotalSubscriptionsTable>
        </CollapsibleCard>
      )}
    </Grid>
  );
};

export default Subscriptions;
