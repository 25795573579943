import {
  Box,
  Typography,
  CircularProgress,
  BoxProps,
  useTheme,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useAppSelector } from "@core/store/hook";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { TotalModal } from "@presentation/components/TotalModal";
import { getSubscriptionsIsPending } from "@adapters/store/subscriptions/slice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ReportUsersProps extends BoxProps {
  reports: {
    id: number;
    reportName: string;
  }[];
}

const SubscriptionReportsListModal = (props: ReportUsersProps): JSX.Element => {
  const theme = useTheme();
  const { reports, ref } = props;

  const [open, setOpen] = useState(false);
  const nbReports = reports.length || 0;
  const reportsLength = reports.length || -1;
  const isPending = useAppSelector(getSubscriptionsIsPending);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, [setOpen]);

  const { t } = useTranslation();

  return (
    <Box ref={ref}>
      <IconButton onClick={handleClickOpen}>
        <InfoOutlinedIcon sx={{ color: "red", height: "18px", cursor: "help" }} />
      </IconButton>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h1" sx={{ color: theme.palette.primary.main, my: "40px" }}>
              {t("subscription_list_reports_modal.title_count", { count: nbReports })}
            </Typography>
          </DialogContent>

          {reports && reportsLength > 0 && (
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: "60%",
                  maxHeight: "200px",
                  display: "flex",
                  borderRadius: "15px",
                  flexDirection: "column",
                  overflow: "auto",
                  mb: "40px",
                  border: "1px solid #B7CBD3",
                }}
              >
                {reports.map((report, idx) => {
                  return (
                    <Box
                      key={`report-modal-${idx}${report.id}`}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: idx !== reportsLength - 1 ? 1 : 0,
                        borderColor: theme.custom?.lineColor?.main,
                        p: 1,
                      }}
                    >
                      <Typography variant="summaryTableHead" sx={{ overflow: "auto" }}>
                        {report.reportName}
                      </Typography>
                      {isPending === true ? <CircularProgress size={20} /> : <></>}
                      {/*reports && reportsLength > 0 && isPending === false && (
                        <RemoveReportSubscriptionModal
                          report={report}
                          handleClick={async () => {
                            await dispatch(remove());
                          }}
                        />
                      )*/}
                    </Box>
                  );
                })}
              </Box>
            </DialogActions>
          )}
        </Box>
      </TotalModal>
    </Box>
  );
};

export default SubscriptionReportsListModal;
