import { TItem } from "@presentation/components/TotalSearchSelect";

/**
 * Atlas of all countries codes (3 digits)
 * Can be extended to indicate the country flag
 */
export const atlas: TItem[] = [
  { label: "ABW", value: "ABW" },
  { label: "AFG", value: "AFG" },
  { label: "AGO", value: "AGO" },
  { label: "AIA", value: "AIA" },
  { label: "ALA", value: "ALA" },
  { label: "ALB", value: "ALB" },
  { label: "AND", value: "AND" },
  { label: "ARE", value: "ARE" },
  { label: "ARG", value: "ARG" },
  { label: "ARM", value: "ARM" },
  { label: "ASM", value: "ASM" },
  { label: "ATA", value: "ATA" },
  { label: "ATF", value: "ATF" },
  { label: "ATG", value: "ATG" },
  { label: "AUS", value: "AUS" },
  { label: "AUT", value: "AUT" },
  { label: "AZE", value: "AZE" },
  { label: "BDI", value: "BDI" },
  { label: "BEL", value: "BEL" },
  { label: "BEN", value: "BEN" },
  { label: "BES", value: "BES" },
  { label: "BFA", value: "BFA" },
  { label: "BGD", value: "BGD" },
  { label: "BGR", value: "BGR" },
  { label: "BHR", value: "BHR" },
  { label: "BHS", value: "BHS" },
  { label: "BIH", value: "BIH" },
  { label: "BLM", value: "BLM" },
  { label: "BLR", value: "BLR" },
  { label: "BLZ", value: "BLZ" },
  { label: "BMU", value: "BMU" },
  { label: "BOL", value: "BOL" },
  { label: "BRA", value: "BRA" },
  { label: "BRB", value: "BRB" },
  { label: "BRN", value: "BRN" },
  { label: "BTN", value: "BTN" },
  { label: "BVT", value: "BVT" },
  { label: "BWA", value: "BWA" },
  { label: "CAF", value: "CAF" },
  { label: "CAN", value: "CAN" },
  { label: "CCK", value: "CCK" },
  { label: "CHE", value: "CHE" },
  { label: "CHL", value: "CHL" },
  { label: "CHN", value: "CHN" },
  { label: "CIV", value: "CIV" },
  { label: "CMR", value: "CMR" },
  { label: "COD", value: "COD" },
  { label: "COG", value: "COG" },
  { label: "COK", value: "COK" },
  { label: "COL", value: "COL" },
  { label: "COM", value: "COM" },
  { label: "CPV", value: "CPV" },
  { label: "CRI", value: "CRI" },
  { label: "CUB", value: "CUB" },
  { label: "CUW", value: "CUW" },
  { label: "CXR", value: "CXR" },
  { label: "CYM", value: "CYM" },
  { label: "CYP", value: "CYP" },
  { label: "CZE", value: "CZE" },
  { label: "DEU", value: "DEU" },
  { label: "DJI", value: "DJI" },
  { label: "DMA", value: "DMA" },
  { label: "DNK", value: "DNK" },
  { label: "DOM", value: "DOM" },
  { label: "DZA", value: "DZA" },
  { label: "ECU", value: "ECU" },
  { label: "EGY", value: "EGY" },
  { label: "ERI", value: "ERI" },
  { label: "ESH", value: "ESH" },
  { label: "ESP", value: "ESP" },
  { label: "EST", value: "EST" },
  { label: "ETH", value: "ETH" },
  { label: "FIN", value: "FIN" },
  { label: "FJI", value: "FJI" },
  { label: "FLK", value: "FLK" },
  { label: "FRA", value: "FRA" },
  { label: "FRO", value: "FRO" },
  { label: "FSM", value: "FSM" },
  { label: "GAB", value: "GAB" },
  { label: "GBR", value: "GBR" },
  { label: "GEO", value: "GEO" },
  { label: "GGY", value: "GGY" },
  { label: "GHA", value: "GHA" },
  { label: "GIB", value: "GIB" },
  { label: "GIN", value: "GIN" },
  { label: "GLP", value: "GLP" },
  { label: "GMB", value: "GMB" },
  { label: "GNB", value: "GNB" },
  { label: "GNQ", value: "GNQ" },
  { label: "GRC", value: "GRC" },
  { label: "GRD", value: "GRD" },
  { label: "GRL", value: "GRL" },
  { label: "GTM", value: "GTM" },
  { label: "GUF", value: "GUF" },
  { label: "GUM", value: "GUM" },
  { label: "GUY", value: "GUY" },
  { label: "HKG", value: "HKG" },
  { label: "HMD", value: "HMD" },
  { label: "HND", value: "HND" },
  { label: "HRV", value: "HRV" },
  { label: "HTI", value: "HTI" },
  { label: "HUN", value: "HUN" },
  { label: "IDN", value: "IDN" },
  { label: "IMN", value: "IMN" },
  { label: "IND", value: "IND" },
  { label: "IOT", value: "IOT" },
  { label: "IRL", value: "IRL" },
  { label: "IRN", value: "IRN" },
  { label: "IRQ", value: "IRQ" },
  { label: "ISL", value: "ISL" },
  { label: "ISR", value: "ISR" },
  { label: "ITA", value: "ITA" },
  { label: "JAM", value: "JAM" },
  { label: "JEY", value: "JEY" },
  { label: "JOR", value: "JOR" },
  { label: "JPN", value: "JPN" },
  { label: "KAZ", value: "KAZ" },
  { label: "KEN", value: "KEN" },
  { label: "KGZ", value: "KGZ" },
  { label: "KHM", value: "KHM" },
  { label: "KIR", value: "KIR" },
  { label: "KNA", value: "KNA" },
  { label: "KOR", value: "KOR" },
  { label: "KWT", value: "KWT" },
  { label: "LAO", value: "LAO" },
  { label: "LBN", value: "LBN" },
  { label: "LBR", value: "LBR" },
  { label: "LBY", value: "LBY" },
  { label: "LCA", value: "LCA" },
  { label: "LIE", value: "LIE" },
  { label: "LKA", value: "LKA" },
  { label: "LSO", value: "LSO" },
  { label: "LTU", value: "LTU" },
  { label: "LUX", value: "LUX" },
  { label: "LVA", value: "LVA" },
  { label: "MAC", value: "MAC" },
  { label: "MAF", value: "MAF" },
  { label: "MAR", value: "MAR" },
  { label: "MCO", value: "MCO" },
  { label: "MDA", value: "MDA" },
  { label: "MDG", value: "MDG" },
  { label: "MDV", value: "MDV" },
  { label: "MEX", value: "MEX" },
  { label: "MHL", value: "MHL" },
  { label: "MKD", value: "MKD" },
  { label: "MLI", value: "MLI" },
  { label: "MLT", value: "MLT" },
  { label: "MMR", value: "MMR" },
  { label: "MNE", value: "MNE" },
  { label: "MNG", value: "MNG" },
  { label: "MNP", value: "MNP" },
  { label: "MOZ", value: "MOZ" },
  { label: "MRT", value: "MRT" },
  { label: "MSR", value: "MSR" },
  { label: "MTQ", value: "MTQ" },
  { label: "MUS", value: "MUS" },
  { label: "MWI", value: "MWI" },
  { label: "MYS", value: "MYS" },
  { label: "MYT", value: "MYT" },
  { label: "NAM", value: "NAM" },
  { label: "NCL", value: "NCL" },
  { label: "NER", value: "NER" },
  { label: "NFK", value: "NFK" },
  { label: "NGA", value: "NGA" },
  { label: "NIC", value: "NIC" },
  { label: "NIU", value: "NIU" },
  { label: "NLD", value: "NLD" },
  { label: "NOR", value: "NOR" },
  { label: "NPL", value: "NPL" },
  { label: "NRU", value: "NRU" },
  { label: "NZL", value: "NZL" },
  { label: "OMN", value: "OMN" },
  { label: "PAK", value: "PAK" },
  { label: "PAN", value: "PAN" },
  { label: "PCN", value: "PCN" },
  { label: "PER", value: "PER" },
  { label: "PHL", value: "PHL" },
  { label: "PLW", value: "PLW" },
  { label: "PNG", value: "PNG" },
  { label: "POL", value: "POL" },
  { label: "PRI", value: "PRI" },
  { label: "PRK", value: "PRK" },
  { label: "PRT", value: "PRT" },
  { label: "PRY", value: "PRY" },
  { label: "PSE", value: "PSE" },
  { label: "PYF", value: "PYF" },
  { label: "QAT", value: "QAT" },
  { label: "REU", value: "REU" },
  { label: "ROU", value: "ROU" },
  { label: "RUS", value: "RUS" },
  { label: "RWA", value: "RWA" },
  { label: "SAU", value: "SAU" },
  { label: "SDN", value: "SDN" },
  { label: "SEN", value: "SEN" },
  { label: "SGP", value: "SGP" },
  { label: "SGS", value: "SGS" },
  { label: "SHN", value: "SHN" },
  { label: "SJM", value: "SJM" },
  { label: "SLB", value: "SLB" },
  { label: "SLE", value: "SLE" },
  { label: "SLV", value: "SLV" },
  { label: "SMR", value: "SMR" },
  { label: "SOM", value: "SOM" },
  { label: "SPM", value: "SPM" },
  { label: "SRB", value: "SRB" },
  { label: "SSD", value: "SSD" },
  { label: "STP", value: "STP" },
  { label: "SUR", value: "SUR" },
  { label: "SVK", value: "SVK" },
  { label: "SVN", value: "SVN" },
  { label: "SWE", value: "SWE" },
  { label: "SWZ", value: "SWZ" },
  { label: "SXM", value: "SXM" },
  { label: "SYC", value: "SYC" },
  { label: "SYR", value: "SYR" },
  { label: "TCA", value: "TCA" },
  { label: "TCD", value: "TCD" },
  { label: "TGO", value: "TGO" },
  { label: "THA", value: "THA" },
  { label: "TJK", value: "TJK" },
  { label: "TKL", value: "TKL" },
  { label: "TKM", value: "TKM" },
  { label: "TLS", value: "TLS" },
  { label: "TON", value: "TON" },
  { label: "TTO", value: "TTO" },
  { label: "TUN", value: "TUN" },
  { label: "TUR", value: "TUR" },
  { label: "TUV", value: "TUV" },
  { label: "TWN", value: "TWN" },
  { label: "TZA", value: "TZA" },
  { label: "UGA", value: "UGA" },
  { label: "UKR", value: "UKR" },
  { label: "UMI", value: "UMI" },
  { label: "URY", value: "URY" },
  { label: "USA", value: "USA" },
  { label: "UZB", value: "UZB" },
  { label: "VAT", value: "VAT" },
  { label: "VCT", value: "VCT" },
  { label: "VEN", value: "VEN" },
  { label: "VGB", value: "VGB" },
  { label: "VIR", value: "VIR" },
  { label: "VNM", value: "VNM" },
  { label: "VUT", value: "VUT" },
  { label: "WLF", value: "WLF" },
  { label: "WSM", value: "WSM" },
  { label: "YEM", value: "YEM" },
  { label: "ZAF", value: "ZAF" },
  { label: "ZMB", value: "ZMB" },
  { label: "ZWE", value: "ZWE" },
];
