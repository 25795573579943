import { Box, Card, CardActions, CardContent, Grid, Collapse, Typography, Divider, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useState } from "react";
import { CollapsibleTableItem } from "@domain/Interfaces/Data.d";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExpandButton from "@presentation/components/ExpandButton";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import { getId } from "@core/utils/Tools";

interface DatasetCollapsibleTableProps extends BoxProps {
  tableId: number;
  title: string | undefined;
  items: CollapsibleTableItem[];
  checkedItems: number[];
  toggleCheckColumn: (tableId: number, columnId: number) => void;
  toggleCheckAll: (tableId: number) => void;
}

const DatasetCollapsibleTable = ({
  title,
  items,
  tableId,
  checkedItems,
  toggleCheckColumn,
  toggleCheckAll,
  sx,
  id,
  ...props
}: DatasetCollapsibleTableProps): React.JSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box id={id} sx={{ width: "48%", marginBottom: "20px", ...sx }} {...props}>
      <Card
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.custom?.tabLineColor?.main,
          border: 1,
          borderColor: theme.custom?.lineColor?.main,
        }}
      >
        <CardContent sx={{ height: "100%", p: 0 }}>
          <Grid container item>
            <Grid container item alignItems="center" direction="row">
              <TotalCheckbox
                id={getId("checkbox-overall", id)}
                sx={{ padding: "12px" }}
                checked={checkedItems.length > 0 && items.filter((i) => !checkedItems.includes(i.id)).length === 0}
                onClick={() => toggleCheckAll(tableId)}
                indeterminate={
                  checkedItems.length > 0 &&
                  items.filter((i) => checkedItems.includes(i.id)).length > 0 &&
                  items.filter((i) => checkedItems.includes(i.id)).length !== items.length
                }
                disabled={items.length === 0}
              />
              <Typography variant="h6">{title}</Typography>
              <ExpandButton id={getId("expand-button", id)} expand={expanded} onClick={() => setExpanded(!expanded)}>
                <ExpandCircleDownIcon
                  sx={{
                    color: "#ffffff",
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#7098A7",
                    borderRadius: "15px",
                    border: 0,
                    height: "25px",
                    width: "auto",
                  }}
                  viewBox="1 1 22 22"
                />
              </ExpandButton>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded}>
          <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main }} variant="middle" />
          <CardContent>
            {items.map((value: CollapsibleTableItem) => {
              const labelId = `transfer-list-all-item-${value.id}-label`;

              return (
                <Grid container direction="row" key={value.id + "-" + value.columnName}>
                  <Grid
                    container
                    direction="row"
                    item
                    alignItems="center"
                    role="listitem"
                    onClick={() => toggleCheckColumn(tableId, value.id)}
                  >
                    <Box>
                      <TotalCheckbox
                        id={getId(`checkbox-${value.id}`, id)}
                        checked={checkedItems.length > 0 && checkedItems.includes(value.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </Box>
                    <Typography id={labelId} variant="body2" sx={{ cursor: "pointer" }}>
                      {value.columnName}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
          <CardActions></CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default DatasetCollapsibleTable;
