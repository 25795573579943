import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#374649",
    },
    secondary: {
      main: "#ED0000",
    },
    text: {
      primary: "#374649",
      secondary: "#7F898B",
    },
  },
  typography: {
    fontFamily: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`,
    body1: {
      fontSize: 16,
      fontWeight: 600,
    },
    menu1: {
      fontSize: 14,
      fontWeight: 400,
    },
    login: {
      fontSize: 16,
      fontWeight: 500,
    },
    button: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: "none",
    },
    chip: {
      fontSize: 12,
      fontWeight: 700,
      textTransform: "none",
    },
    navButtonSelected: {
      fontSize: 14,
      fontWeight: 700,
    },
    summaryTableHead: {
      fontSize: 14,
      fontWeight: 700,
    },
    summaryChip: {
      fontSize: 11,
      fontWeight: 400,
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
    },
    h2: {
      fontSize: 18,
      fontWeight: 500,
    },
    h1: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
    },
    backButton: {
      fontSize: 14,
      fontWeight: 500,
    },
    lineHeader: {
      fontSize: 12,
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "0.04em",
      color: "#374649",
      opacity: "0.8",
    },
  },
  custom: {
    modal: {
      border: {
        destructive: "4px solid #ED0000",
        neutral: "1px solid #7098A7",
        flagLangage: "1px solid #D1DEE3",
      },
    },
    tooltip: {
      border: {
        default: "1px solid #7098A7",
        warning: "1px solid #ED0000",
      },
    },
    disabled: {
      main: "#00000042",
      textColor: "#7098A7",
    },
    tertiary: {
      main: "#0075E3",
    },
    tagPrimary: {
      main: "#DDE5FE",
    },
    tagSecondary: {
      main: "#D6EFFE",
    },
    borderColor: {
      main: "#C0CCD8",
    },
    lineColor: {
      main: "#B7CBD3",
    },
    tabLineColor: {
      main: "#EFF4F5",
    },
    backgroundColor: {
      main: "#F5F5F5",
      report: "#F7F9FA",
      title: "#F7F9FA",
    },
    button: {
      textColor: "white",
      background: "linear-gradient(100.46deg, #0098EA -8.29%, #0075DB 29.54%, #0452C8 84.82%);",
    },
    gradient: {
      main: "linear-gradient(100.46deg, #0098EA -8.29%, #0075DB 29.54%, #0452C8 84.82%);",
    },
    chip: {
      shared: {
        backgroundColor: "#7098A7",
        textColor: "white",
      },
      admin: {
        color: "#DDE5FE",
      },
      readOnly: {
        color: "#D9F2CA",
      },
      readAndEdit: {
        color: "#D6EFFE",
      },
      user: {
        color: "#D6EFFE",
      },
    },
    select: {
      border: {
        default: "1px solid #7098A7",
        size: "1px",
        color: "#7098A7",
        radius: "8px",
      },
    },
    neutral: {
      80: "#7098A7",
      100: "#374649",
    },
    shadow: {
      button: "0px 6px 8px 0px rgba(183, 203, 211, 0.32), 0px 1px 1px 0px rgba(112, 152, 167, 0.64)",
      modal: "0px 6px 8px 0px rgba(55, 70, 73, 0.32), 0px 1px 1px 0px rgba(55, 70, 73, 0.80)",
      tooltip: "0px 6px 8px 0px rgba(183, 203, 211, 0.32), 0px 1px 1px 0px rgba(183, 203, 211, 0.80)",
      buttonInset: "0px 6px 8px 0px rgba(183, 203, 211, 0.32) inset, 0px 1px 1px 0px rgba(112, 152, 167, 0.64) inset",
    },
    primary: {
      white: "#FFFFFF",
    },
    colors: {
      brand: {
        primary: {
          500: "#ED0000",
        },
      },
      hover: {
        lightRed: "#FEEBEB",
        brand: {
          primary: {
            300: "#0452C8",
            500: "#DB3900",
          },
        },
      },
      primary: {
        neutral: {
          10: "#F7F9FA",
        },
      },
    },
  },
});

export default theme;
