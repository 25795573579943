import { getUsersError, getUsersRedirection } from "@adapters/store/users/slice";
import { rejectUser, validateUser } from "@adapters/store/users/thunk";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { Box, Card, Typography, useTheme } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";
import LegalFilesList from "@presentation/components/LegalFilesList";
import TotalButton from "@presentation/components/TotalButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Authorize = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string | null>("");
  const redirectionUrl = useAppSelector(getUsersRedirection);
  const error = useAppSelector(getUsersError);

  const handleRefuse = (): void => {
    if (token) {
      dispatch(rejectUser(token));
    }
  };

  const handleValidate = (): void => {
    if (token) {
      dispatch(validateUser(token));
    }
  };

  useEffect(() => {
    if (redirectionUrl) {
      window.location.href = redirectionUrl;
    } else if (error) {
      navigate("/rejected");
    }
  }, [navigate, error, redirectionUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tkn = queryParams.get("token");
    setToken(tkn);
  }, [location, setToken]);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          width: "460px",
          borderRadius: "16px",
          mt: "20px",
        }}
        elevation={10}
      >
        <Box sx={{ mx: "40px", py: "40px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "60px" }}>
            <TotalIcon />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography align="center" variant="menu1" sx={{ mb: "5px" }}>
              {t("account_validation.welcome")}
            </Typography>
            <Typography align="center" variant="menu1" sx={{ mb: 4 }}>
              {t("account_validation.activate_explanation", {
                confirmAccount: t("account_validation.confirm_account"),
              })}
            </Typography>
            <Typography variant="caption">{t("account_validation.agree_the_following_documents")}</Typography>
            <LegalFilesList fontSize={"12px"} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "24px", columnGap: "16px" }}>
            <TotalButton
              id="reject-cgu-button"
              text={t("account_validation.reject_account")}
              icon="arrowLeft"
              height={12}
              iconColor={theme.palette.secondary.main}
              textColor={theme.palette.primary.main}
              buttonColor="white"
              borderColor={theme.custom?.lineColor?.main}
              onClick={handleRefuse}
              disabled={false}
            />
            <TotalButton
              id="accept-cgu-button"
              text={t("account_validation.confirm_account")}
              icon="check"
              height={18}
              onClick={handleValidate}
              disabled={false}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Authorize;
