import { AutocompleteProps, Box, BoxProps, SxProps, TextField, Theme, styled, useTheme } from "@mui/material";
import LoopIcon from "@presentation/components/Icons/LoopIcon";
import { AutocompleteOptionType, InputAutocomplete } from "@presentation/components/inputs/atoms/InputAutocomplete";
import { InputLabel } from "@presentation/components/inputs/atoms/InputLabel";

type AutocompletePropsComputed = AutocompleteProps<
  AutocompleteOptionType,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
>;

type TextFieldProps = Omit<BoxProps, "onChange"> &
  Pick<AutocompletePropsComputed, "disabled" | "value"> & {
    label?: string;
    width?: string;
    color?: string;
    onChange: (e: string) => void;
    inputProps?: AutocompletePropsComputed;
    textPlace?: "top" | "left";
    options?: AutocompleteOptionType[];
  };

const StyledTextField = styled(TextField)(() => {
  const theme = useTheme();
  return {
    ".MuiAutocomplete-root": {
      height: "32px",
      borderRadius: "8px",
      border: theme.custom.select.border,
    },
  };
});

const TextFieldAutocomplete = ({
  disabled,
  label,
  placeholder,
  onChange,
  width,
  color = "white",
  sx,
  value,
  inputProps,
  textPlace = "top",
  options = [],
  ...props
}: TextFieldProps): React.JSX.Element => {
  const sxLeft: SxProps<Theme> = {
    my: "auto",
    mr: "10px",
  };
  return (
    <Box
      sx={{ width: width, display: "flex", flexDirection: textPlace === "top" ? "column" : "row", ...sx }}
      {...props}
    >
      <InputLabel label={label} disabled={disabled} sx={{ ...(textPlace === "top" ? undefined : sxLeft) }} />
      <InputAutocomplete
        sx={{ width: "400px" }}
        onChange={(_event, newValue) => {
          const opts = newValue as Partial<AutocompleteOptionType>;
          onChange(opts?.title ?? "");
        }}
        onInputChange={(_event, newInputValue) => {
          onChange(newInputValue);
        }}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.title)}
        placeholder={placeholder}
        disabled={disabled}
        color={color}
        clearOnBlur={false}
        value={value}
        renderOption={(params, option) => (
          <li
            {...params}
            onClick={(e) => {
              if (option.onClick) option.onClick();
              if (params.onClick) params.onClick(e);
            }}
          >
            {option.title}
          </li>
        )}
        filterOptions={(opts, state) =>
          opts.filter((option) => option.title.toLocaleLowerCase().includes(state.inputValue.toLocaleLowerCase()))
        }
        renderInput={(params: any) => <StyledTextField {...params} label={placeholder} size="small" />}
        options={options}
        popupIcon={<LoopIcon />}
        {...inputProps}
      />
    </Box>
  );
};

export default TextFieldAutocomplete;
