import { Box, Card, Typography } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";

type Props = {
  message: string;
};

const ErrorPage = ({ message }: Props): React.JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          width: "425px",
          borderRadius: "16px",
          mt: "20px",
        }}
        elevation={10}
      >
        <Box sx={{ mx: "40px", py: "40px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "40px" }}>
            <TotalIcon />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: "40px", gap: "8px" }}>
            <Typography align="left" variant="menu1" sx={{ mb: "8px", whiteSpace: "pre-line" }}>
              {message}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ErrorPage;
