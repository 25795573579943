/**
 * Apply trim, lowercase and remove accent, used for better comparison
 * @param value value to format
 * @returns value formated
 */
export const formatToSearchable = (value: string): string =>
  value
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

/**
 * Test if toMatch is inside {toTest}, will apply "formatToSeachable" on both entry
 * @param toTest
 * @param toMatch
 * @returns true if toMatch is inside toTest
 */
export const stringComparatorWithFormatter = (toTest: string, toMatch: string): boolean =>
  formatToSearchable(toTest).includes(formatToSearchable(toMatch));

export const message = {
  success: {
    updated: (subject: string): string => `${subject} has been successfully saved`,
    created: (subject: string): string => `${subject} has been successfully created`,
    deleted: (subject: string): string => `${subject} has been successfully deleted`,
  },
};

export const sortAsc = (a: string, b: string): number => {
  return a.localeCompare(b);
};
