import { Box, BoxProps, CheckboxProps, Typography, TypographyProps } from "@mui/material";
import TotalCheckbox from "@presentation/components/TotalCheckbox";

interface CheckboxRowProps extends BoxProps {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  label: string;
  checkBoxProps?: CheckboxProps;
  typographyProps?: TypographyProps;
}

export const CheckboxRow = ({
  checked,
  onCheckedChange,
  checkBoxProps,
  label,
  typographyProps,
  ...props
}: CheckboxRowProps): React.JSX.Element => {
  return (
    <Box
      {...props}
      sx={{
        width: "31%",
        flexDirection: "row",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        ...props?.sx,
      }}
    >
      <TotalCheckbox onChange={(_event, value) => onCheckedChange(value)} checked={checked} {...checkBoxProps} />
      <Typography
        variant="h6"
        {...typographyProps}
        sx={{ cursor: "pointer", userSelect: "none", ...typographyProps?.sx }}
        onClick={(event) => {
          onCheckedChange(!checked);
          if (typographyProps?.onClick) typographyProps.onClick(event);
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
