import { useTheme } from "@mui/material/styles";
import { Alert, Box, Breadcrumbs, Button, Divider, Grid, InputAdornment, Link, Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TextField from "@presentation/components/inputs/TextField";
import TotalSelect from "@presentation/components/TotalSelect";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useParams, useNavigate } from "react-router-dom";
import { User } from "@domain/entities/Users";
import UserField from "@presentation/components/UserField";
import { listScopes } from "@adapters/store/scopes/thunk";
import { getAdminScopes, getScopesIsPending } from "@adapters/store/scopes/slice";
import ScopePermissionField from "@presentation/components/ScopePermissionField";
import { getFavoriteReports, getReports, getReportsIsPending } from "@adapters/store/reports/slice";
import { clearUsersError, getAuthUser, getUsers, getUsersError, getUsersIsPending } from "@adapters/store/users/slice";
import { listUsers, updateUser } from "@adapters/store/users/thunk";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import { listGroups } from "@adapters/store/groups/thunk";
import { Group, GroupData, ScopesAndPermissionsRequest } from "@domain/entities/Groups";
import { Scope } from "@domain/entities/Scopes";
import { Report } from "@domain/entities/Reports";
import { getGroups, getGroupsIsPending } from "@adapters/store/groups/slice";
import SummaryUser from "@presentation/components/users/SummaryUserCreateUpdate";
import { sortAsc, stringComparatorWithFormatter } from "@core/utils/StringTools";
import TotalToggle from "@presentation/components/TotalToggle";
import { uniq } from "@core/utils/ArrayTools";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { ScopeColumn, getReportsWithoutDuplicates, getSimulatedScopesRights } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";
import { isAdmin } from "@core/right/Right";
import TotalSearchSelect from "@presentation/components/TotalSearchSelect";
import { atlas } from "@assets/atlas";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";
import { StarFilledIcon } from "@presentation/components/Icons/StartFilledIcon";

const ViewUser = (): React.JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchParams = useParams();

  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isInternal, setIsInternal] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const [searchReport, setSearchReport] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<number[]>([]);

  const [selectedScopes, setRawSelectedScopes] = useState<ScopeColumn[]>([]);
  const [selectedScope, setSelectedScope] = useState<number>(-1);

  const [selectedGroups, setRawSelectedGroups] = useState<GroupData[]>([]);

  const [alreadyExists, setAlreadyExists] = useState<boolean>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>();
  const [mounted, setMounted] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getUsersError);
  const scopesList = useAppSelector(getAdminScopes);
  const reportsList = useAppSelector(getReports);
  const usersList = useAppSelector(getUsers);
  const groupsList = useAppSelector(getGroups);
  const groupsIsPending = useAppSelector(getGroupsIsPending);
  const usersIsPending = useAppSelector(getUsersIsPending);
  const scopesIsPending = useAppSelector(getScopesIsPending);
  const reportsIsPending = useAppSelector(getReportsIsPending);
  const favorite = useAppSelector(getFavoriteReports);
  const permissionNames = [t("rights.admin"), t("rights.read_and_edit"), t("rights.read_only")];

  const [filteredReports, setFilteredReports] = useState<Report[]>(reportsList);
  const authUser = useAppSelector(getAuthUser);

  const path = "/users";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_users")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("users_create_update.update_title")}
    </Link>,
  ];

  const setSelectedScopes = (scopes: ScopeColumn[]): void => {
    scopes.sort((scopeA, scopeB) => sortAsc(scopeA.scopeName, scopeB.scopeName));
    setRawSelectedScopes(scopes);
  };

  const setSelectedGroups = (groups: GroupData[]): void => {
    groups.sort((groupA, groupB) => sortAsc(groupA.groupName, groupB.groupName));
    setRawSelectedGroups(groups);
  };

  const handleFirstNameChange = (value: string): void => {
    setFirstName(value);
  };
  const handleLastNameChange = (value: string): void => {
    setLastName(value);
  };
  const handleEmailChange = (value: string): void => {
    setEmail(value);
  };
  const handleOrgChange = (value: string): void => {
    setOrganisation(value);
  };
  const handleDeptChange = (value: string): void => {
    setDepartment(value);
  };
  const handleSearchReportChange = (value: string): void => {
    setSearchReport(value);
  };
  const handleCountryChange = (value: string): void => {
    setCountry(value);
  };

  const validateEmail = /^[\w.+-]+@[\w-]+\.[\w.-]+$/i;

  const handleSelectGroup = (groupId: number): void => {
    const newArr = [...selectedGroups];
    const isDuplicate = newArr.some(({ id }: GroupData) => {
      return id === groupId;
    });
    if (!isDuplicate) {
      const groupToAdd = groupsList.find(({ id }) => id === groupId);
      if (groupToAdd) {
        newArr.push({ id: groupToAdd.id, groupName: groupToAdd.groupName });
        setSelectedGroups(newArr);
      }
    }
  };

  const handleGroupRemove = (groupId: number): void => {
    setSelectedGroups(selectedGroups.filter(({ id }) => id !== groupId));
  };

  const handleScopeValueChange = (idx: number, value: number, isDuplicate?: boolean): void => {
    let newArr;
    if (isDuplicate) {
      newArr = [...selectedScopes];
      newArr.splice(idx, 0, { ...selectedScopes[idx] });
      newArr[idx + 1].scopePermissionId = value;
      newArr[idx + 1].scopePermission = permissionNames[value - 1];
    } else {
      if (value !== -1) {
        newArr = [...selectedScopes];
        newArr[idx].scopePermissionId = value;
        newArr[idx].scopePermission = permissionNames[value - 1];
      } else {
        newArr = selectedScopes.filter((x: ScopeColumn, xId: number) => xId !== idx);
      }
    }
    setSelectedScopes(newArr);
  };

  const checkAlreadyExists = (value: string): boolean => {
    return usersList.some((x: User) => x.email === value.trim() && x.id !== Number(searchParams.id));
  };

  const setFilteredSelectedReports = (selectedReportsToFilter: number[]): void => {
    const newArr = selectedReportsToFilter
      .map((report) => {
        return reportsList.filter((r) => !!r.reportName).find((r) => r.id === report)?.id;
      })
      .filter((report) => report !== undefined) as number[];
    setSelectedReports(newArr);
  };

  const toggleCheckSingleColumn = (tableId: number[], columnId: number): void => {
    let newArr;
    if (tableId.includes(columnId)) {
      newArr = tableId.filter((id) => id !== columnId);
    } else {
      newArr = [...tableId];
      newArr.push(columnId);
    }
    setFilteredSelectedReports(newArr);
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result =
      !!firstName.trim() &&
      !!lastName.trim() &&
      !!organisation.trim() &&
      !!email.trim() &&
      !alreadyExists &&
      !!isEmailValid &&
      !selectedScopes.some((scope) => scope.scopePermissionId === 0) &&
      !!country.trim();
    setIsBusy(false);
    return result;
  };

  const getOnlySelectedGroupsAdministrable = (): Group[] =>
    groupsList.filter(({ id }) => selectedGroups.find((g) => g.id === id));

  const reportIsInGroup = (reportId: number): boolean =>
    getOnlySelectedGroupsAdministrable().some((group) => group.reports.map((report) => report.id).includes(reportId));

  const getGroupCanAccessToReport = (reportId: number): Group[] =>
    getOnlySelectedGroupsAdministrable().filter((group) => group.reports.map((r) => r.id).includes(reportId));

  useEffect(() => {
    if (mounted) return;
    setIsBusy(true);
    dispatch(listUsers());
    dispatch(listScopes());
    dispatch(listGroups());
    dispatch(listReports());
    dispatch(getFavorite());
    setFilteredReports(reportsList);
    setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, dispatch]);

  useEffect(() => {
    if (usersIsPending || groupsIsPending || scopesIsPending || reportsIsPending || isBusy || mounted) return;
    setIsBusy(true);
    const tmpUser = usersList?.find((usr: User) => usr.id === Number(searchParams.id));
    if (!tmpUser) return;
    setFirstName(tmpUser?.firstName);
    setLastName(tmpUser?.lastName);
    setEmail(tmpUser?.email);
    setIsInternal(tmpUser?.isInternal);
    setOrganisation(tmpUser?.organisation);
    setCountry(tmpUser?.profile.country || "");
    setDepartment(tmpUser?.department || "");
    const groupsArray: GroupData[] = [...tmpUser.groups];
    setSelectedGroups(groupsArray);
    const scopesArray: ScopeColumn[] = [];
    tmpUser.scopes.forEach((scopeAndPermission) => {
      scopesArray.push({
        scopeId: scopeAndPermission.scope.id,
        scopeName: scopeAndPermission.scope.scopeName,
        scopePermission: scopeAndPermission.permission.permissionName,
        scopePermissionId: scopeAndPermission.permission.id,
      });
    });
    setSelectedScopes(scopesArray);
    setFilteredSelectedReports(tmpUser.reports.map((x) => x.id));
    setUser(tmpUser);
    setMounted(true);
    setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersIsPending, groupsIsPending, scopesIsPending, reportsIsPending]);

  useEffect(() => {
    if (selectedScope === -1) return;
    const tmpScope = scopesList.find((scope) => scope.id === selectedScope);
    const isDuplicate = selectedScopes.some((scope: ScopeColumn) => scope.scopeId === tmpScope?.id);
    if (isDuplicate) return;
    const scopesArray = [...selectedScopes];
    scopesArray.push({
      scopeId: tmpScope?.id || 0,
      scopeName: tmpScope?.scopeName || "",
      scopePermissionId: 0,
      scopePermission: "",
    });
    setSelectedScopes(scopesArray);
    setSelectedScope(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope]);

  useEffect(() => {
    if (reportsIsPending) return;
    const filteredRows = reportsList.filter((row) => stringComparatorWithFormatter(row.reportName, searchReport));
    setFilteredReports(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsList, searchReport]);

  useEffect(() => {
    setAlreadyExists(checkAlreadyExists(email));
    setIsEmailValid(validateEmail.test(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, validateEmail]);

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    email,
    isInternal,
    organisation,
    country,
    department,
    searchReport,
    selectedReports,
    selectedScopes,
    selectedScope,
    selectedGroups,
  ]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {mounted && (
        <Box sx={{ width: "100%" }}>
          {error && (
            <Alert severity="error" onClose={() => dispatch(clearUsersError())} sx={{ my: 2 }}>
              {error?.message}
            </Alert>
          )}
          <Grid container item direction="row" justifyContent="end" alignItems="end">
            <TotalButton
              id="update-user-button"
              text={t("users_create_update.update_user")}
              icon=""
              height={22}
              disabled={!isValid}
              onClick={async () => {
                if (usersIsPending) return;
                const sApArr: ScopesAndPermissionsRequest[] = [];
                selectedScopes.forEach((x: ScopeColumn) =>
                  sApArr.push({ scopeId: x.scopeId, permissionId: x.scopePermissionId })
                );
                await dispatch(
                  updateUser({
                    id: user?.id,
                    email: email.trim(),
                    profile: {
                      firstName: firstName.trim(),
                      lastName: lastName.trim(),
                      organisation: organisation.trim(),
                      country: country.trim(),
                      department: department?.trim(),
                    },
                    scopes: uniq(sApArr),
                    reports: uniq(selectedReports),
                    groups: uniq(selectedGroups.map((x) => x.id)),
                  })
                );
                dispatch(listUsers());
                if (!error) navigate(path);
              }}
            />
          </Grid>
          <CollapsibleCard title={t("users_create_update.1_profile_information")} sx={{ mt: "30px" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
              <TextFieldData
                id="first-name"
                label={t("users_create_update.first_name_input")}
                value={firstName}
                error={firstName.trim().length === 0 && firstName.length > 0}
                onChange={handleFirstNameChange}
                width="30%"
              />
              <TextFieldData
                id="last-name"
                label={t("users_create_update.last_name_input")}
                value={lastName}
                error={lastName.trim().length === 0 && lastName.length > 0}
                onChange={handleLastNameChange}
                width="30%"
              />
              <TotalToggle
                id="isInternal"
                label={t("users_create_update.user_type_input")}
                buttons={[
                  { value: "true", label: t("users_create_update.user_type_internal"), selected: isInternal },
                  { value: "false", label: t("users_create_update.user_type_external"), selected: !isInternal },
                ]}
                value={isInternal}
                disabled={true}
                helpText={t("users_create_update.user_type_tooltip")}
              />
              <TextFieldData
                id="email"
                label={t("users_create_update.email_input")}
                value={email}
                error={alreadyExists || (email.length > 0 && !validateEmail.test(email))}
                onChange={handleEmailChange}
                width="30%"
              />
              <TextFieldData
                id="organisation"
                label={t("users_create_update.organization_input")}
                value={organisation}
                error={organisation.trim().length === 0 && organisation.length > 0}
                onChange={handleOrgChange}
                width="30%"
              />
              <TotalSearchSelect
                label={t("users_create_update.country_input")}
                sx={{ width: "30%" }}
                items={atlas}
                value={country}
                onValueChange={handleCountryChange}
                error={false}
              />
              <TextFieldData
                id="department"
                label={t("users_create_update.department_input")}
                value={department}
                onChange={handleDeptChange}
                width="30%"
              />
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("users_create_update.2_add_user_to_groups")} sx={{ mt: "8px" }}>
            <TotalSelect
              label={t("users_create_update.select_group")}
              items={groupsList.map((group: GroupData) => ({ id: group.id, optionName: group.groupName }))}
              sx={{ minWidth: "350px" }}
              onChange={handleSelectGroup}
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              {selectedGroups
                .filter(({ id }) => groupsList.find((g) => g.id === id) || isAdmin(authUser?.userRight))
                .map((x: GroupData) => {
                  return (
                    <UserField
                      key={x.id + "-" + x.groupName}
                      className={x.id + "-" + x.groupName}
                      group={x}
                      identifier={x.id}
                      width="49%"
                      handleValue={handleGroupRemove}
                      sx={{ mx: 1 }}
                    />
                  );
                })}
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}></Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("users_create_update.3_scopes_and_permissions")} sx={{ mt: "8px" }}>
            <TotalSelect
              label={t("users_create_update.select_scopes")}
              items={scopesList.map((x: Scope) => ({ id: x.id, optionName: x.scopeName }))}
              sx={{ minWidth: "350px" }}
              onChange={(e: number) => setSelectedScope(e)}
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              {selectedScopes
                .filter(({ scopeId }) => scopesList.find(({ id }) => id === scopeId) || isAdmin(authUser?.userRight))
                .map((x: ScopeColumn, idx: number) => {
                  return (
                    <ScopePermissionField
                      key={x.scopeId + "-" + x.scopeName}
                      className={x.scopeId + "-" + x.scopeName}
                      scope={x}
                      scopeIdx={idx}
                      permissionId={x.scopePermissionId}
                      handleValue={(idxa: number, e: number) => handleScopeValueChange(idxa, e)}
                    />
                  );
                })}
              {getOnlySelectedGroupsAdministrable()
                .flatMap((g) =>
                  g.scopesAndPermissions.map((s) => ({
                    group: g,
                    scope: {
                      scopeId: s.scope.id,
                      scopeName: s.scope.scopeName,
                      scopePermission: s.permission.permissionName,
                      scopePermissionId: s.permission.id,
                    },
                  }))
                )
                .map((s, idx) => {
                  return (
                    <ScopePermissionField
                      key={s.scope.scopeId + "-" + s.scope.scopeName + "-" + s.group.id}
                      className={s.scope.scopeId + "-" + s.scope.scopeName + "-" + s.group.id}
                      scope={s.scope}
                      group={s.group}
                      scopeIdx={idx}
                      permissionId={s.scope.scopePermissionId}
                      handleValue={(idxa: number, e: number) => handleScopeValueChange(idxa, e)}
                    />
                  );
                })}
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}></Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("users_create_update.4_report_access")} sx={{ mt: "8px" }}>
            <TextField
              id="search-report"
              label={t("users_create_update.4_description")}
              value={searchReport}
              onChange={handleSearchReportChange}
              width="30%"
              endAdornment={
                <InputAdornment position="end">
                  <SearchRoundedIcon color="secondary" />
                </InputAdornment>
              }
            />
            {filteredReports && (
              <Box sx={{ display: "flex", flexWrap: "wrap", maxHeight: "300px", justifyContent: "space-between" }}>
                {filteredReports.map((report: Report) => {
                  return (
                    <TotalTooltip
                      key={report.id}
                      title={
                        reportIsInGroup(report.id)
                          ? (() => {
                              const groupNames = getGroupCanAccessToReport(report.id).map((g) => g.groupName);
                              return t("users_create_update.user_already_access_with_group", {
                                count: groupNames.length,
                                groupName: groupNames.join(", "),
                              });
                            })()
                          : ""
                      }
                      placement="bottom"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "48%",
                          mt: 2,
                          borderBottom: 1,
                          height: "39px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          !reportIsInGroup(report.id) && toggleCheckSingleColumn(selectedReports, report.id)
                        }
                      >
                        <Box>
                          <TotalCheckbox
                            checked={selectedReports.includes(report.id) || reportIsInGroup(report.id)}
                            disabled={reportIsInGroup(report.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": report.id + "" }}
                          />
                        </Box>
                        {favorite.includes(report.id) && (
                          <StarFilledIcon
                            sx={{
                              color: theme.custom.colors.brand.primary[500],
                              height: "14px",
                              my: "auto",
                              mr: "4px",
                              display: "flex",
                            }}
                          />
                        )}
                        <Typography variant="h6" sx={{ userSelect: "none", transform: "translateY(1px)" }}>
                          {report.reportName}
                        </Typography>
                      </Box>
                    </TotalTooltip>
                  );
                })}
              </Box>
            )}
          </CollapsibleCard>
          <CollapsibleCard title={t("users_create_update.5_user_summary")} sx={{ mt: "8px" }} isExpanded={true}>
            <SummaryUser
              firstName={firstName}
              lastName={lastName}
              organisation={organisation}
              department={department}
              isInternal={isInternal}
              selectedScopes={getSimulatedScopesRights([
                ...selectedScopes,
                ...getOnlySelectedGroupsAdministrable().flatMap((g) =>
                  g.scopesAndPermissions.map((s) => ({
                    scopeId: s.scope.id,
                    scopeName: s.scope.scopeName,
                    scopePermission: s.permission.permissionName,
                    scopePermissionId: s.permission.id,
                  }))
                ),
              ])}
              selectedGroups={selectedGroups.filter((g) => groupsList.find(({ id }) => id === g.id))}
              selectedReports={getReportsWithoutDuplicates([
                ...selectedReports,
                ...getOnlySelectedGroupsAdministrable()
                  .flatMap((g) => g.reports)
                  .map((r) => r.id),
              ])}
              reportsList={reportsList}
            />
          </CollapsibleCard>
        </Box>
      )}
    </Grid>
  );
};

export default ViewUser;
