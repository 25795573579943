import { ReactComponent as ArrowSort } from "@assets/icons/arrow_sort_icon.svg";

const ArrowSortIcon = (props: any): JSX.Element => {
  return (
    <ArrowSort
      fill={props?.sx?.color || props?.sx?.fill}
      height={props?.sx?.height || "16px"}
      width={props?.sx?.width || props?.sx?.height || "16px"}
      {...props}
    />
  );
};

export default ArrowSortIcon;
