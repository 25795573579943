import { createRole, deleteRole, getRoleById, listRoles, searchRole, updateRole } from "@adapters/store/roles/thunk";
import { toaster } from "@core/Toaster";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { message, sortAsc } from "@core/utils/StringTools";
import { Role } from "@domain/entities/Roles";
import { createSelector, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface RolesState {
  list: Role[];
  search: {
    roleName: string | null;
    datasetId: number | null;
    result: number;
  };
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: RolesState = {
  list: [],
  search: {
    roleName: null,
    datasetId: null,
    result: 0,
  },
  pending: false,
  error: null,
};

const subject = "The role";

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearRolesError: (state: RolesState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST ROLES
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.list = action.payload
        .map((role) => {
          return {
            ...role,
            datasetName: role.dataset?.datasetName,
            scopesLength: role.scopes.length,
            filtersLength: role.filters.length,
          } as Role;
        })
        .sort((a, b) => sortAsc(a.roleName, b.roleName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listRoles.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listRoles");
      state.pending = false;
    });
    builder.addCase(listRoles.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE ROLE
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      toaster.success(message.success.deleted(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteRole");
      state.pending = false;
    });
    builder.addCase(deleteRole.pending, (state, _action) => {
      state.pending = true;
    });
    // GET ROLE BY ID
    builder.addCase(getRoleById.fulfilled, (state, action) => {
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(getRoleById.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "getRoleById");
      state.pending = false;
    });
    builder.addCase(getRoleById.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE ROLE
    builder.addCase(createRole.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list = state.list.sort((a, b) => sortAsc(a.roleName, b.roleName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createRole");
      state.pending = false;
    });
    builder.addCase(createRole.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE ROLE
    builder.addCase(updateRole.fulfilled, (state, action) => {
      toaster.success(message.success.updated(subject));
      const list = state.list.filter((r) => r.id !== action.meta.arg.id);
      list.push(action.payload);
      state.list = list.sort((a, b) => sortAsc(a.roleName, b.roleName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateRole");
      state.pending = false;
    });
    builder.addCase(updateRole.pending, (state, _action) => {
      state.pending = true;
    });
    // SEARCH ROLE
    builder.addCase(searchRole.fulfilled, (state, action) => {
      state.search = {
        roleName: action.meta.arg.roleName,
        datasetId: action.meta.arg.datasetId,
        result: action.payload.length,
      };
      state.pending = false;
    });
    builder.addCase(searchRole.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "searchRole");
      state.pending = false;
    });
    builder.addCase(searchRole.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
// export const { roleAlreadyExists } = rolesSlice.actions;

// Define selectors to be used in UI here
export const { clearRolesError } = rolesSlice.actions;

export const getRolesCount = (state: RootState): number => state.roles.list.length;
export const getRoles = (state: RootState): Role[] => state.roles.list;
export const getRolesError = (state: RootState): ErrorState | null => state.roles.error;
export const getRolesIsPending = (state: RootState): boolean => state.roles.pending;

type Return = (state: RootState) => boolean;
export const roleAlreadyExists = (datasetId: number, roleName?: string): Return =>
  createSelector(
    [(state: RootState) => state.roles.search],
    (search) => search.datasetId === datasetId && search.roleName === roleName && search.result > 0
  );
