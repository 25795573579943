import { Box, Typography, CircularProgress, useTheme, DialogActions, DialogContent, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Report } from "@domain/entities/Reports";
import RemoveUsersReportModal from "@presentation/components/RemoveUsersReportModal";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { removeUsersToReport } from "@adapters/store/reports/thunk";
import { getReportsUserIsPending } from "@adapters/store/reports/slice";
import GroupIcon from "@presentation/components/Icons/GroupIcon";
import { useTranslation } from "react-i18next";
import { TotalModal } from "@presentation/components/TotalModal";
import CloseIcon from "@mui/icons-material/Close";

interface ReportUsersProps {
  report: Report | null;
}

const ReportUsersModal = (props: ReportUsersProps): React.JSX.Element => {
  const theme = useTheme();
  const { report } = props;

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const nbUsers = report?.users?.length || 0;
  const usersLength = report?.users?.length || -1;
  const isReportUserPending = useAppSelector(getReportsUserIsPending);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          margin: "0",
          padding: "8px",
          border: "1.5px solid #7098A7",
          borderRadius: "18px",
          backgroundColor: "white",
          boxShadow: "0px 1px 1px #b7cbd3cc, 0px 6px 8px #b7cbd352",
        }}
        onClick={handleClickOpen}
      >
        <GroupIcon
          sx={{ color: theme.palette.secondary.main, justifyContent: "center", alignItems: "center" }}
          style={{ height: "16px", width: "16px" }}
        />
      </Box>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h2" sx={{ color: theme.palette.primary.main, mt: "32px", mb: "24px" }}>
              {t("report_list_users_modal.shared_with", { count: nbUsers })}
            </Typography>
          </DialogContent>
          {report?.users && usersLength > 0 && (
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "320px",
                  display: "flex",
                  borderRadius: "15px",
                  flexDirection: "column",
                  overflowX: "auto",
                  mb: "32px",
                  border: "1px solid #B7CBD3",
                }}
              >
                {report?.users.map((x, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: idx !== usersLength - 1 ? 1 : 0,
                        borderColor: theme.custom?.lineColor?.main,
                        p: 1,
                      }}
                    >
                      <Typography variant="summaryTableHead" sx={{ overflow: "auto" }}>
                        {x.firstName} {x.lastName}
                      </Typography>
                      {isReportUserPending === true ? <CircularProgress size={20} /> : <></>}
                      {report?.users && usersLength > 0 && isReportUserPending === false && (
                        <RemoveUsersReportModal
                          user={x}
                          handleClick={async () => {
                            await dispatch(removeUsersToReport({ reportId: report?.id, users: [x.id] }));
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </DialogActions>
          )}
        </Box>
      </TotalModal>
    </Box>
  );
};

export default ReportUsersModal;
