import { isDownloadReportInfo } from "@adapters/store/reports/entities";
import {
  listReports,
  getReport,
  removeUsersToReport,
  initializeDownload,
  updateUsersToReport,
  loopDownloadReport,
  getFavorite,
  addFavorite,
  removeFavorite,
  getReportPolling,
} from "@adapters/store/reports/thunk";
import { toaster } from "@core/Toaster";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { sortAsc } from "@core/utils/StringTools";
import { Report } from "@domain/entities/Reports";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import i18n from "@core/I18n";
import { isUN1B } from "@core/right/Right";

const { t } = i18n;

export interface ReportDownloadRaw {
  downloadId: string;
  reportExternalId: string;
  status: string;
}

export interface ReportDownloadResponse {
  reportId: number;
  downloadId: string;
  reportExternalId: string;
  status: string;
}

// Define a type for the slice state
interface ReportsState {
  list: Report[];
  favorite: number[];
  downloadList: ReportDownloadResponse[];
  actualReport: Report | null;
  pending: boolean;
  pendingFavorite: boolean;
  pendingUser: boolean;
  error: ErrorState | null;
  pendingPolling: boolean;
}

// Define the initial state using that type
const initialState: ReportsState = {
  list: [],
  downloadList: [],
  favorite: [],
  actualReport: null,
  pending: false,
  pendingUser: false,
  pendingFavorite: false,
  pendingPolling: false,
  error: null,
};

const createOrUpdateDownaloadItem = (state: ReportsState, download: ReportDownloadResponse): void => {
  removeDownloadItem(state, download.reportId);
  state.downloadList.push(download);
};

const removeDownloadItem = (state: ReportsState, reportId: number): void => {
  state.downloadList = state.downloadList.filter((r) => r.reportId !== reportId);
};

const findDownloadItem = (state: ReportsState, reportId: number): ReportDownloadResponse | undefined => {
  return state.downloadList.find((r) => r.reportId === reportId);
};

const getReportName = (state: ReportsState, reportId: number): string => {
  return findReport(state, reportId)?.reportName ?? "the report";
};

const findReport = (state: ReportsState, reportId: number): Report | undefined | null => {
  return [...state.list, state.actualReport].find((r) => r?.id === reportId);
};

const errorInDownloadReport = (reportName: string): void => {
  toaster.error(t("error.cannot_download_report", { reportName }));
};

const successInDownloadReport = (reportName: string): void => {
  toaster.success(t("see_reports.view.success_download", { reportName }));
};

const pendingInDownloadReport = (reportName: string): void => {
  toaster.info(t("see_reports.view.pending_download", { reportName }));
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearReportsError: (state: ReportsState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST REPORTS
    builder.addCase(listReports.fulfilled, (state, action) => {
      action.payload.forEach((report) => {
        report.roles?.sort((a, b) => sortAsc(a.roleName, b.roleName));
        report.scopes?.sort((a, b) => a - b);
        report.users?.sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      });
      state.list = action.payload.sort((a, b) => sortAsc(a.reportName, b.reportName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listReports.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listReports");
      state.pending = false;
    });
    builder.addCase(listReports.pending, (state, _action) => {
      state.pending = true;
    });
    // GET FAVORITE REPORTS
    builder.addCase(getFavorite.fulfilled, (state, action) => {
      state.favorite = action.payload;
      state.error = null;
      state.pendingFavorite = false;
    });
    builder.addCase(getFavorite.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "getFavorite");
      state.pendingFavorite = false;
    });
    builder.addCase(getFavorite.pending, (state, _action) => {
      state.pendingFavorite = true;
    });
    // ADD FAVORITE REPORTS
    builder.addCase(addFavorite.fulfilled, (state) => {
      state.error = null;
      state.pendingFavorite = false;
    });
    builder.addCase(addFavorite.rejected, (state, action) => {
      const reportId = action.meta.arg;
      state.favorite = state.favorite.filter((r) => r !== reportId);
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "addFavorite");
      state.pendingFavorite = false;
    });
    builder.addCase(addFavorite.pending, (state, action) => {
      const reportId = action.meta.arg;
      state.favorite.push(reportId);
      state.pendingFavorite = true;
    });
    // REMOVE FAVORITE REPORTS
    builder.addCase(removeFavorite.fulfilled, (state, _action) => {
      state.error = null;
      state.pendingFavorite = false;
    });
    builder.addCase(removeFavorite.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      const reportId = action.meta.arg as unknown as number;
      state.favorite.push(reportId);
      state.error = error;
      errorHandler(error, "removeFavorite");
      state.pendingFavorite = false;
    });
    builder.addCase(removeFavorite.pending, (state, action) => {
      state.pendingFavorite = true;
      const reportId = action.meta.arg as unknown as number;
      state.favorite = state.favorite.filter((r) => r !== reportId);
    });
    // GET REPORT
    builder.addCase(getReport.fulfilled, (state, action) => {
      action.payload.roles?.sort((a, b) => sortAsc(a.roleName, b.roleName));
      action.payload.scopes?.sort((a, b) => a - b);
      action.payload.users?.sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      state.actualReport = action.payload;
      state.pending = false;
      state.error = null;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "getReport");
      state.pending = false;
    });
    builder.addCase(getReport.pending, (state) => {
      state.pending = true;
    });
    // POLLING REPORT
    builder.addCase(getReportPolling.fulfilled, (state, action) => {
      if (typeof action.payload !== "string" && state.actualReport !== null) {
        delete state.actualReport.pollingId;
        state.actualReport = {
          ...state.actualReport,
          ...action.payload,
        };
      }
      state.pendingPolling = false;
    });
    builder.addCase(getReportPolling.rejected, (state, action) => {
      if ([403, 404].includes(Number(action.error.code) ?? NaN) && state.actualReport !== null) {
        delete state.actualReport.pollingId;
      }
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "getReportPolling");
      state.pendingPolling = false;
    });
    builder.addCase(getReportPolling.pending, (state) => {
      state.pendingPolling = true;
    });
    // UPDATE USERS
    builder.addCase(updateUsersToReport.fulfilled, (state, action) => {
      const reportToUpdate = state.list.find((r) => r.id === action.meta.arg.reportId) ?? {}; // keep the old one, to not lost embed token
      if (state.actualReport?.id === action.meta.arg.reportId) {
        state.actualReport = { ...state.actualReport, ...action.payload };
      }
      const list = state.list.filter((r) => r.id !== action.meta.arg.reportId);
      action.payload.roles?.sort((a, b) => sortAsc(a.roleName, b.roleName));
      action.payload.scopes?.sort((a, b) => a - b);
      action.payload.users?.sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      list.push({ ...reportToUpdate, ...action.payload });
      state.list = list.sort((a, b) => sortAsc(a.reportName, b.reportName));
      state.error = null;
      state.pendingUser = false;
    });
    builder.addCase(updateUsersToReport.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateUsersToReport");
      state.pendingUser = false;
    });
    builder.addCase(updateUsersToReport.pending, (state, _action) => {
      state.pendingUser = true;
    });
    // REMOVE USERS
    builder.addCase(removeUsersToReport.fulfilled, (state, action) => {
      const reportToUpdate = state.list.find((r) => r.id === action.meta.arg.reportId) || {}; // keep the old one, to not lost embed token
      if (state.actualReport?.id === action.meta.arg.reportId) {
        state.actualReport = { ...state.actualReport, ...action.payload };
      }
      const list = state.list.filter((r) => r.id !== action.meta.arg.reportId);
      list.push({ ...reportToUpdate, ...action.payload });
      state.list = list;
      state.error = null;
      state.pendingUser = false;
    });
    builder.addCase(removeUsersToReport.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "removeUsersToReport");
      state.pendingUser = false;
    });
    builder.addCase(removeUsersToReport.pending, (state, _action) => {
      state.pendingUser = true;
    });

    // INIT DOWNLOAD
    builder.addCase(initializeDownload.fulfilled, (state, action) => {
      createOrUpdateDownaloadItem(state, { ...action.payload, status: "Preparing" });
    });
    builder.addCase(initializeDownload.pending, (state, action) => {
      const reportId = action.meta.arg;
      pendingInDownloadReport(getReportName(state, reportId));
      createOrUpdateDownaloadItem(state, {
        reportId,
        downloadId: "",
        reportExternalId: "",
        status: "Preparing",
      });
    });
    builder.addCase(initializeDownload.rejected, (state, action) => {
      const reportId = action.meta.arg;
      removeDownloadItem(state, reportId);
      errorHandler(action.payload as ErrorState, "initializeDownload");
    });
    builder.addCase(loopDownloadReport.pending, (state, action) => {
      const reportId = action.meta.arg.reportId;
      const dl = findDownloadItem(state, reportId);
      if (dl) {
        createOrUpdateDownaloadItem(state, { ...dl, status: "Downloading" });
      }
    });

    // LOOP DOWNLOAD
    builder.addCase(loopDownloadReport.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        const reportId = action.meta.arg.reportId;
        const dl = findDownloadItem(state, reportId);
        if (dl && isDownloadReportInfo(action.payload.data)) {
          const reportInfo = action.payload.data;
          const reportName = getReportName(state, reportId);
          fetch(reportInfo.link)
            .then((response) => {
              successInDownloadReport(reportName);
              return response.blob().then((b) => {
                const anchor = document.createElement("a");
                anchor.download = reportInfo.fileName ?? "report.pdf";
                anchor.href = URL.createObjectURL(b);
                anchor.click();
              });
            })
            .catch(() => {
              errorInDownloadReport(reportName);
            });
          removeDownloadItem(state, reportId);
        }
      }
    });
    builder.addCase(loopDownloadReport.rejected, (state, action) => {
      const reportId = action.meta.arg.reportId;
      const reportName = getReportName(state, reportId);
      removeDownloadItem(state, reportId);
      errorInDownloadReport(reportName);
    });
  },
});

// Define actions to be used in UI here
// export const { scopeAlreadyExists } = scopesSlice.actions;

// Define selectors to be used in UI here
export const { clearReportsError } = reportsSlice.actions;

export const getReports = (state: RootState): Report[] => state.reports.list;
const getUserRights = (state: RootState): string | undefined => state?.users?.authUser?.user?.userRight;
export const getViewableReports = createSelector([getReports, getUserRights], (reportsList, userRights) => {
  const onlyShowVisibleReport = isUN1B(userRights);
  if (onlyShowVisibleReport) {
    return reportsList.filter((r: Report) => r.roles?.length > 0);
  } else {
    return reportsList;
  }
});
export const getFavoriteReports = (state: RootState): number[] => state.reports.favorite;
export const getSingleReport = (state: RootState): Report | null => state.reports.actualReport;
export const getReportsError = (state: RootState): ErrorState | null => state.reports.error;
export const getReportsIsPending = (state: RootState): boolean => state.reports.pending;
export const getReportPollingPending = (state: RootState): boolean => state.reports.pendingPolling;
export const getReportsUserIsPending = (state: RootState): boolean => state.reports.pendingUser;
export const getReportsDownloadList = (state: RootState): ReportDownloadResponse[] => state.reports.downloadList;
