import { httpClient } from "@adapters/api/HttpClient";

export const datasetsServices = {
  listDatasets: async () => {
    return httpClient.get("/v1/datasets");
  },
  listDatasetTables: async (datasetId: number) => {
    return httpClient.get(`/v1/datasets/${datasetId}/tables`);
  },
};
