import { Alert, Box, Breadcrumbs, Button, Divider, Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";
import TotalSelect from "@presentation/components/TotalSelect";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useParams, useNavigate } from "react-router-dom";
import TotalSearchBar from "@presentation/components/TotalSearchBar";
import { User } from "@domain/entities/Users";
import UserField from "@presentation/components/UserField";
import { listScopes } from "@adapters/store/scopes/thunk";
import { getAdminScopes, getScopesIsPending } from "@adapters/store/scopes/slice";
import ScopePermissionField from "@presentation/components/ScopePermissionField";
import ReportCollapsibleTable from "@presentation/components/ReportCollapsibleTable";
import { getReports, getReportsIsPending } from "@adapters/store/reports/slice";
import { getUsers, getUsersIsPending } from "@adapters/store/users/slice";
import { listUsers } from "@adapters/store/users/thunk";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import SummaryGroup from "@presentation/components/groups/SummaryGroupCreateUpdate";
import { listGroups, updateGroup } from "@adapters/store/groups/thunk";
import { Group, ScopesAndPermissionsRequest } from "@domain/entities/Groups";
import { Scope } from "@domain/entities/Scopes";
import { Report } from "@domain/entities/Reports";
import { clearGroupsError, getGroups, getGroupsError, getGroupsIsPending } from "@adapters/store/groups/slice";
import { uniq } from "@core/utils/ArrayTools";
import { ColumnChecked, ScopeColumn } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";

const ViewGroup = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [groupName, setGroupName] = useState<string>("");
  const [userSelect, setUserSelect] = useState<number | null>(-1);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [availableReports, setAvailableReports] = useState<Report[]>([]);
  const [selectedReports, setSelectedReports] = useState<ColumnChecked[]>([]);
  const [selectedScopes, setSelectedScopes] = useState<ScopeColumn[]>([]);
  const [selectedScope, setSelectedScope] = useState<number>(-1);
  const [alreadyExists, setAlreadyExists] = useState<boolean>();

  const searchParams = useParams();
  const [group, setGroup] = useState<Group>();
  const [mounted, setMounted] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getGroupsError);
  const groupsList = useAppSelector(getGroups);
  const usersList = useAppSelector(getUsers);
  const reportsList = useAppSelector(getReports);
  const scopesList = useAppSelector(getAdminScopes);
  const usersIsPending = useAppSelector(getUsersIsPending);
  const groupsIsPending = useAppSelector(getGroupsIsPending);
  const reportsIsPending = useAppSelector(getReportsIsPending);
  const scopesIsPending = useAppSelector(getScopesIsPending);
  const permissionNames = [t("rights.admin"), t("rights.read_and_edit"), t("rights.read_only")];

  const path = "/groups";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_groups")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("group_create_update.update_title")}
    </Link>,
  ];

  const handleNameChange = (value: string): void => {
    setGroupName(value);
  };

  const handleSelect = (event: React.SyntheticEvent, newValue: any): void => {
    setUserSelect(null);
    if (!newValue) return;
    if (typeof newValue === "string") {
      return;
    }
    const newArr = [...selectedUsers];
    const valueArr = newArr.map(function (item: User) {
      return item.id;
    });
    const isDuplicate = valueArr.some((item: number) => {
      return item === newValue.id;
    });
    if (!isDuplicate) {
      newArr.push(newValue);
      setSelectedUsers(newArr);
    }
  };

  const checkAlreadyExists = (value: string): boolean => {
    return groupsList.some((x: Group) => x.groupName === value.trim() && x.id !== Number(searchParams.id));
  };

  const handleUserRemove = (idx: number): void => {
    const newArr = [...selectedUsers];
    newArr.splice(idx, 1);
    setSelectedUsers(newArr);
  };

  const handleScopeValueChange = (idx: number, value: number, isDuplicate?: boolean): void => {
    let newArr;
    if (isDuplicate) {
      newArr = [...selectedScopes];
      newArr.splice(idx, 0, { ...selectedScopes[idx] });
      newArr[idx + 1].scopePermissionId = value;
      newArr[idx + 1].scopePermission = permissionNames[value - 1];
    } else {
      if (value !== -1) {
        newArr = [...selectedScopes];
        newArr[idx].scopePermissionId = value;
        newArr[idx].scopePermission = permissionNames[value - 1];
      } else {
        newArr = selectedScopes.filter((x: ScopeColumn, xId: number) => xId !== idx);
      }
    }
    setSelectedScopes(newArr);
  };

  const toggleCheckAllReports = (scopeId: number): void => {
    const table = selectedScopes.find((s) => s.scopeId === scopeId);
    if (!table) return;
    let newArr;
    if (selectedReports.some((report) => report.scopeId === table.scopeId)) {
      newArr = selectedReports.filter((report) => report.scopeId !== scopeId);
    } else {
      newArr = [...selectedReports];
      availableReports
        .filter((c) => c.scopes?.includes(scopeId))
        .forEach((x: Report) => {
          newArr.push({
            scopeId: table.scopeId,
            scopeName: table.scopeName,
            reportId: x.id,
            reportName: x.reportName,
          });
        });
    }
    setSelectedReports(uniq(newArr));
  };

  const toggleCheckSingleReport = (tableId: number, columnId: number): void => {
    const table = selectedScopes.find((s) => s.scopeId === tableId);
    if (!table) return;
    let newArr: ColumnChecked[] = [];
    if (selectedReports.some((report) => report.scopeId === tableId && report.reportId === columnId)) {
      newArr = selectedReports.filter((report) => report.reportId !== columnId);
    } else {
      newArr = [...selectedReports];
      newArr.push({
        scopeId: table.scopeId,
        scopeName: table.scopeName,
        reportId: columnId,
        reportName: availableReports.filter((c) => c.scopes?.includes(table.scopeId)).find((c) => c.id === columnId)
          ?.reportName,
      });
    }
    setSelectedReports(uniq(newArr));
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result =
      !!groupName &&
      !alreadyExists &&
      selectedScopes.length > 0 &&
      selectedScopes.filter((c) => c.scopePermissionId <= 0).length === 0;
    setIsBusy(false);
    return result;
  };

  useEffect(() => {
    if (mounted) return;
    setIsBusy(true);
    dispatch(listGroups());
    dispatch(listUsers());
    dispatch(listScopes());
    dispatch(listReports());
    dispatch(getFavorite());
    setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mounted]);

  useEffect(() => {
    if (groupsIsPending || usersIsPending || scopesIsPending || reportsIsPending || isBusy || mounted) return;
    setIsBusy(true);
    const tmpGroup = groupsList?.find((grp: Group) => grp.id === Number(searchParams.id));
    if (!tmpGroup) return;
    setGroupName(tmpGroup?.groupName);
    setSelectedScopes(
      tmpGroup.scopesAndPermissions.map((scopeAndPermission) => {
        return {
          scopeId: scopeAndPermission.scope.id,
          scopeName: scopeAndPermission.scope.scopeName,
          scopePermission: scopeAndPermission.permission.permissionName,
          scopePermissionId: scopeAndPermission.permission.id,
        } as ScopeColumn;
      })
    );
    const tempReportsList: Report[] = [];
    reportsList.forEach((report) => {
      const tempReport: Report = { ...report };
      const tempReportScopes: number[] = [];
      report.scopes?.forEach((s) => tempReportScopes.push(s));
      scopesList.forEach((scope) => {
        if (tempReportScopes.includes(scope.id)) return;
        if (!scope.datasets.some((dataset) => dataset.id === report.datasetId)) return;
        tempReportScopes.push(scope.id);
      });
      tempReport.scopes = tempReportScopes;
      tempReportsList.push(tempReport);
    });
    setAvailableReports(tempReportsList);
    const reportsArray: ColumnChecked[] = [];
    tmpGroup.reports.forEach((report) => {
      const reportTmp = tempReportsList?.find((rpt: Report) => rpt.id === report.id);
      const scopes: Scope[] | undefined = scopesList?.filter((scope: Scope) => reportTmp?.scopes?.includes(scope.id));
      if (!scopes) return;
      scopes.forEach((scope: Scope) => {
        if (reportsArray.find((rpt) => rpt.scopeId === scope.id && report.id === rpt.reportId)) return;
        reportsArray.push({
          scopeId: scope?.id,
          scopeName: scope?.scopeName,
          reportId: report.id,
          reportName: report.reportName,
        });
      });
    });
    setSelectedReports(uniq(reportsArray));
    setSelectedUsers(tmpGroup.users.filter((user) => usersList.find((usr) => usr.id === user.id)) as User[]);
    setGroup(tmpGroup);
    setMounted(true);
    setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsIsPending, usersIsPending, scopesIsPending, reportsIsPending]);

  useEffect(() => {
    if (selectedScope === -1) return;
    const scopesArray = [...selectedScopes];
    const tmpScope = scopesList.find((scope) => scope.id === selectedScope);
    const isDuplicate = selectedScopes.some((scope: ScopeColumn) => scope.scopeId === tmpScope?.id);
    if (isDuplicate) return;
    scopesArray.push({
      scopeId: tmpScope?.id || 0,
      scopeName: tmpScope?.scopeName || "",
      scopePermissionId: 0,
      scopePermission: "",
    });
    setSelectedScopes(scopesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScope]);

  useEffect(() => {
    setUserSelect(null);
  }, [selectedUsers]);

  useEffect(() => {
    if (groupName.length < 2) return;
    setAlreadyExists(checkAlreadyExists(groupName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName, userSelect, selectedUsers, availableReports, selectedReports, selectedScopes, selectedScope]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>

      {error && (
        <Alert severity="error" onChange={() => dispatch(clearGroupsError())}>
          {error?.message}
        </Alert>
      )}

      {mounted && (
        <Box sx={{ width: "100%", mt: 2 }}>
          {error && (
            <Alert severity="error" onChange={() => dispatch(clearGroupsError())}>
              {error?.message}
            </Alert>
          )}
          <Grid container item direction="row" justifyContent="space-between" alignItems="end">
            <Grid container item direction="row" md={8}>
              <TextFieldData
                id="group-name"
                label={t("group_create_update.group_name")}
                value={groupName}
                error={alreadyExists}
                onChange={handleNameChange}
              />
            </Grid>
            <TotalButton
              id="update-group-button"
              text={t("group_create_update.update_group")}
              icon=""
              height={22}
              disabled={!isValid}
              onClick={async () => {
                const sApArr: ScopesAndPermissionsRequest[] = [];
                const users: number[] = selectedUsers.map((x: User) => x.id);
                selectedScopes.forEach((x: ScopeColumn) =>
                  sApArr.push({ scopeId: x.scopeId, permissionId: x.scopePermissionId })
                );
                if (groupsIsPending) return;
                dispatch(clearGroupsError());
                await dispatch(
                  updateGroup({
                    id: group?.id,
                    groupName: groupName.trim(),
                    users: uniq(users),
                    scopesAndPermissions: uniq(sApArr),
                    reports: uniq(selectedReports.map((x: ColumnChecked) => x.reportId)),
                  })
                );
                dispatch(listGroups());
                if (!error) navigate(path);
              }}
            />
          </Grid>
          <CollapsibleCard title={t("group_create_update.1_users_in_group")} sx={{ mt: 3 }}>
            <TotalSearchBar
              value={userSelect}
              blurOnSelect={true}
              label={t("search_bar.user")}
              options={usersList}
              onChange={handleSelect}
              sx={{ mb: 2, width: "50%" }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>
              {selectedUsers.map((x: User, idx: number) => {
                return (
                  <UserField
                    key={x.id + "-" + x.profile.firstName + "-" + x.profile.lastName}
                    className={x.id + "-" + x.profile.firstName + "-" + x.profile.lastName}
                    user={x}
                    identifier={idx}
                    handleValue={handleUserRemove}
                  />
                );
              })}
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("group_create_update.2_scopes_and_permissions")} sx={{ mt: 1 }}>
            <TotalSelect
              label={t("group_create_update.select_scope")}
              items={scopesList.map((x: Scope) => ({ id: x.id, optionName: x.scopeName }))}
              sx={{ minWidth: "350px" }}
              onChange={(e: number) => setSelectedScope(e)}
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
              {selectedScopes.map((x: ScopeColumn, idx: number) => {
                return (
                  <ScopePermissionField
                    key={x.scopeId + "-" + x.scopeName}
                    className={x.scopeId + "-" + x.scopeName}
                    scope={x}
                    scopeIdx={idx}
                    permissionId={x.scopePermissionId}
                    handleValue={(idxa: number, e: number) => handleScopeValueChange(idxa, e)}
                  />
                );
              })}
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("group_create_update.3_report_access")} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
              {selectedScopes.map((x: ScopeColumn) => {
                return (
                  <ReportCollapsibleTable
                    key={`report-table-${x.scopeId}`}
                    title={x.scopeName}
                    tableId={x.scopeId}
                    items={availableReports.filter((report) => report.scopes?.includes(x.scopeId))}
                    checkedItems={selectedReports.filter((c) => c.scopeId === x.scopeId).map((c) => c.reportId)}
                    toggleCheckColumn={(tableId: number, columnId: number) =>
                      toggleCheckSingleReport(tableId, columnId)
                    }
                    toggleCheckAll={(tableId: number) => toggleCheckAllReports(tableId)}
                  />
                );
              })}
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("group_create_update.4_summary")} sx={{ mt: 1 }} isExpanded={true}>
            <SummaryGroup users={selectedUsers} scopes={selectedScopes} reports={selectedReports} />
          </CollapsibleCard>
        </Box>
      )}
    </Grid>
  );
};

export default ViewGroup;
