import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import { getMaintenances } from "@adapters/store/maintenances/slice";
import { listMaintenances } from "@adapters/store/maintenances/thunk";
import { MaintenanceHeadCell } from "@domain/Interfaces/HeadCell.d";
import MaintenanceTable from "@presentation/components/maintenance/MaintenanceTable";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";

const Maintenances = (): React.JSX.Element => {
  const { t } = useTranslation();
  const headCells: MaintenanceHeadCell[] = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: t("see_maintenances.headers.maintenance"),
      enableSorting: true,
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: t("see_maintenances.headers.startDate"),
      enableSorting: true,
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: t("see_maintenances.headers.endDate"),
      enableSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("see_maintenances.headers.status"),
      enableSorting: true,
    },
  ];

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getMaintenances);

  useEffect(() => {
    dispatch(listMaintenances());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("see_maintenances.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("see_maintenances.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
      </Box>
      <MaintenanceTable headCells={headCells} rows={rows} />
    </Grid>
  );
};

export default Maintenances;
