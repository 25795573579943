import { ReactComponent as ExternalLink } from "@assets/icons/external_link_icon.svg";

const ExternalLinkIcon = (props: any): JSX.Element => {
  return (
    <ExternalLink
      fill={props?.sx?.color || props?.sx?.fill}
      height={props?.sx?.height || "16px"}
      width={props?.sx?.width || props?.sx?.height || "16px"}
      {...props}
    />
  );
};

export default ExternalLinkIcon;
