import { rolesServices } from "@adapters/api/services/RolesServices";
import { Role, RoleRequest } from "@domain/entities/Roles";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listRoles = createAsyncThunk("roles/list", async (_, { rejectWithValue }) => {
  try {
    const response = await rolesServices.listRoles();
    return response.data as Role[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteRole = createAsyncThunk("roles/delete", async (roleId: number, { rejectWithValue }) => {
  try {
    const response = await rolesServices.deleteRole(roleId);
    return response.data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createRole = createAsyncThunk("roles/create", async (role: RoleRequest, { rejectWithValue }) => {
  try {
    const response = await rolesServices.createRole(role);
    return response.data as Role;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateRole = createAsyncThunk("roles/update", async (role: RoleRequest, { rejectWithValue }) => {
  try {
    const response = await rolesServices.updateRole(role);
    return response.data as Role;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getRoleById = createAsyncThunk("roles/get", async (roleId: number, { rejectWithValue }) => {
  try {
    const response = await rolesServices.getRoleById(roleId);
    return response.data as Role;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const searchRole = createAsyncThunk(
  "roles/search",
  async (args: { datasetId: number; roleName: string }, { rejectWithValue }) => {
    try {
      const response = await rolesServices.listRoles(args);
      return response.data as Role[];
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
