import { useAppDispatch, useAppSelector } from "@core/store/hook";
import TotalUsersTable from "@presentation/components/users/TotalUsersTable";
import { UserHeadCell } from "@domain/Interfaces/HeadCell.d";
import { getAuthUser, getUsers } from "@adapters/store/users/slice";
import { exportUsers, listUsers } from "@adapters/store/users/thunk";
import { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import TotalButton from "@presentation/components/TotalButton";
import DownloadIcon from "@presentation/components/Icons/DownloadIcon";
import { isAdmin, isUN1 } from "@core/right/Right";
import { User } from "@domain/entities/Users";
import TextField from "@presentation/components/inputs/TextField";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";
import LoopIcon from "@presentation/components/Icons/LoopIcon";
import useStoredPagination from "@presentation/utils/StoredPagination";

const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const headCells: UserHeadCell[] = [
    {
      id: "lastName",
      numeric: false,
      disablePadding: true,
      label: t("manage_users.headers.lastname"),
      enableSorting: true,
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: t("manage_users.headers.firstname"),
      enableSorting: true,
    },
    {
      id: "organisation",
      numeric: false,
      disablePadding: false,
      label: t("manage_users.headers.organization"),
      enableSorting: true,
    },
    {
      id: "scopesLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_users.headers.scopes"),
      enableSorting: true,
    },
    {
      id: "groupsLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_users.headers.groups"),
      enableSorting: true,
    },
    {
      id: "reportsLength",
      numeric: true,
      disablePadding: false,
      label: t("manage_users.headers.reports"),
      enableSorting: true,
    },
  ];

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getUsers);
  const [userList, setUserList] = useState<User[]>(rows);
  const [storedPagination, setStoredPagination] = useStoredPagination("users", {
    page: 0,
    order: "asc",
    orderBy: "lastName",
    search: "",
  });

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    const search = storedPagination.search as string;
    if (search.length >= 1) {
      setUserList(
        rows.filter(
          (user: User) =>
            user.lastName.toLowerCase().includes(search.toLowerCase()) ||
            user.firstName.toLowerCase().includes(search.toLowerCase()) ||
            user.organisation.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase()) ||
            user?.department?.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setUserList(rows);
    }
  }, [rows, storedPagination.search]);

  const handleExport = (): void => {
    dispatch(exportUsers());
  };

  const handleSearch = (value: string): void => {
    setStoredPagination({ ...storedPagination, search: value, page: 0 });
  };

  const authUser = useAppSelector(getAuthUser);
  const haveRightToExport = (right?: string): boolean => isAdmin(right) || isUN1(right);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px", width: "100%" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("manage_users.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("manage_users.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
        {rows.length > 0 && haveRightToExport(authUser?.userRight) && (
          <Box sx={{ marginLeft: "auto", marginRight: "8px", display: "flex" }}>
            <TextField
              label={t("search_bar.search")}
              value={storedPagination.search}
              textPlace="left"
              onChange={handleSearch}
              sx={{ mr: 2 }}
              inputProps={{
                endAdornment: <LoopIcon sx={{ color: theme.custom.colors.brand.primary[500] }} />,
              }}
            />
            <TotalButton
              id="export-users-button"
              text={t("manage_users.export_users")}
              height={14}
              icon={<DownloadIcon />}
              onClick={handleExport}
            ></TotalButton>
          </Box>
        )}
      </Box>
      <TotalUsersTable
        headCells={headCells}
        rows={userList}
        storedPagination={storedPagination}
        setStoredPagination={setStoredPagination}
      />
    </Grid>
  );
};

export default Users;
