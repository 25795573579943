import { SvgIcon, SvgIconProps } from "@mui/material";

const MaintenanceIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5579 2.02799C14.0749 1.97342 13.5839 1.98923 13.1002 2.07736C12.1003 2.25955 11.1796 2.74214 10.4609 3.46084C9.74223 4.17953 9.25963 5.10019 9.07745 6.10012C8.89526 7.10004 9.02213 8.13175 9.44116 9.05774C9.61276 9.43695 9.53153 9.8828 9.23721 10.1771L2.32721 17.0871C2.11692 17.2974 1.99878 17.5826 1.99878 17.88C1.99878 18.1774 2.11692 18.4626 2.32721 18.6729C2.5375 18.8832 2.82271 19.0013 3.1201 19.0013C3.41749 19.0013 3.7027 18.8832 3.91299 18.6729L10.823 11.7629C11.1173 11.4686 11.5632 11.3874 11.9424 11.559C12.8684 11.978 13.9001 12.1049 14.9 11.9227C15.8999 11.7405 16.8206 11.2579 17.5393 10.5392C18.258 9.82049 18.7406 8.89983 18.9228 7.89991C19.0109 7.41625 19.0267 6.92517 18.9721 6.44221L16.4001 9.01419C16.0263 9.38064 15.5236 9.58587 15.0001 9.58587C14.4766 9.58587 13.974 9.38061 13.6001 9.01415L13.593 9.00715L11.9859 7.40005C11.6195 7.02619 11.4142 6.52352 11.4142 6.00001C11.4142 5.4765 11.6195 4.97387 11.986 4.60001L11.993 4.59287L14.5579 2.02799ZM12.7417 0.10975C14.1416 -0.145314 15.586 0.0323095 16.8824 0.618951C17.1815 0.75429 17.3951 1.0278 17.4539 1.35076C17.5127 1.67372 17.4093 2.00499 17.1772 2.23712L13.4143 6.00001L15.0001 7.5858L18.763 3.8229C18.9951 3.59078 19.3264 3.48736 19.6493 3.54621C19.9723 3.60505 20.2458 3.81866 20.3812 4.11774C20.9678 5.41413 21.1454 6.85851 20.8904 8.25841C20.6353 9.6583 19.9597 10.9472 18.9535 11.9534C17.9473 12.9596 16.6584 13.6352 15.2585 13.8903C14.091 14.103 12.8927 14.0148 11.775 13.6394L5.32721 20.0871C4.74185 20.6725 3.94793 21.0013 3.1201 21.0013C2.29227 21.0013 1.49835 20.6725 0.912993 20.0871C0.327632 19.5018 -0.0012207 18.7078 -0.0012207 17.88C-0.0012207 17.0522 0.327632 16.2583 0.912993 15.6729L7.36075 9.22515C6.98534 8.10746 6.89713 6.90906 7.10984 5.74161C7.3649 4.34172 8.04054 3.0528 9.04671 2.04663C10.0529 1.04045 11.3418 0.364814 12.7417 0.10975Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default MaintenanceIcon;
