import { Dialog, DialogProps, Fade, useTheme } from "@mui/material";

interface ModalProps extends DialogProps {
  children: React.ReactNode;
  destructive?: boolean;
}

export const TotalModal = ({ children, destructive = false, sx, ...other }: ModalProps): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Dialog
      TransitionComponent={Fade}
      {...other}
      sx={{
        "& .MuiDialog-paper": {
          border: destructive ? theme.custom.modal.border.destructive : theme.custom.modal.border.neutral,
          boxShadow: theme.custom.shadow.modal,
          borderRadius: "8px",
        },
        ...sx,
      }}
    >
      {children}
    </Dialog>
  );
};
