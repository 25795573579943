import { SvgIcon, SvgIconProps } from "@mui/material";

const CheckboxUncheckedIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="16" height="16" rx="3" fill="white" />
      <rect x="1" y="1" width="16" height="16" rx="3" stroke="#374649" strokeWidth="2" />
    </svg>
  </SvgIcon>
);

export default CheckboxUncheckedIcon;
