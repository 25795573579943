import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActionArea,
  IconButton,
  InputProps,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { User } from "@domain/entities/Users";
import { GroupData } from "@domain/entities/Groups";

interface UserFieldProps extends InputProps {
  user?: User;
  group?: GroupData;
  identifier: number;
  className: string;
  width?: string;
  handleValue: (identifier: number) => void;
}

const UserField = (props: UserFieldProps): JSX.Element => {
  const theme = useTheme();
  const { user, group, identifier, className, handleValue, width } = props;
  return (
    <Box {...(className && { className })} sx={{ width: width || "31%", mt: 2, mx: 1 }}>
      <Card
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.custom?.tabLineColor?.main,
          border: 1,
          borderColor: theme.custom?.lineColor?.main,
          padding: "8px",
          boxShadow: "none",
        }}
      >
        <CardContent sx={{ height: "100%", py: 0 }}>
          <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: "40%",
              }}
            >
              <Typography variant="backButton" noWrap={true} sx={{ fontSize: "14px" }}>
                {user ? user?.profile?.firstName + " " + user?.profile?.lastName : group?.groupName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                gap: "1vw",
              }}
            >
              <Divider orientation="vertical" flexItem />
              <IconButton sx={{ padding: 0 }} onClick={() => handleValue(identifier)}>
                <CancelIcon
                  sx={{
                    color: "#ffffff",
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#7098A7",
                    borderRadius: "15px",
                    border: 0,
                    height: "30px",
                    width: "auto",
                  }}
                  viewBox="1 1 22 22"
                />
              </IconButton>
            </Box>
          </Grid>
        </CardContent>
        <CardActionArea></CardActionArea>
      </Card>
    </Box>
  );
};

export default UserField;
