import { createGroup, deleteGroup, listGroups, updateGroup } from "@adapters/store/groups/thunk";
import { toaster } from "@core/Toaster";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { message, sortAsc } from "@core/utils/StringTools";
import { Group } from "@domain/entities/Groups";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface GroupsState {
  list: Group[];
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: GroupsState = {
  list: [],
  pending: false,
  error: null,
};

const subject = "The group";

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    clearGroupsError: (state: GroupsState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST GROUPS
    builder.addCase(listGroups.fulfilled, (state, action) => {
      state.list = action.payload
        .map((group) => {
          return {
            ...group,
            usersLength: group.users.length,
            reportsLength: group.reports.length,
            scopesLength: group.scopesAndPermissions.map((x) => x.scope).length,
          } as Group;
        })
        .sort((a, b) => sortAsc(a.groupName, b.groupName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listGroups.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listGroups");
      state.pending = false;
    });
    builder.addCase(listGroups.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE GROUP
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      toaster.success(message.success.deleted(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteGroup");
      state.pending = false;
    });
    builder.addCase(deleteGroup.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE GROUP
    builder.addCase(createGroup.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list = state.list.sort((a, b) => sortAsc(a.groupName, b.groupName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createGroup");
      state.pending = false;
    });
    builder.addCase(createGroup.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE GROUP
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      toaster.success(message.success.updated(subject));
      const list = state.list.filter((r) => r.id !== action.meta.arg.id);
      list.push(action.payload);
      state.list = list.sort((a, b) => sortAsc(a.groupName, b.groupName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateGroup.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateGroup");
      state.pending = false;
    });
    builder.addCase(updateGroup.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
export const { clearGroupsError } = groupsSlice.actions;

// Define selectors to be used in UI here
export const getGroupsCount = (state: RootState): number => state.groups.list.length;
export const getGroups = (state: RootState): Group[] => state.groups.list;
export const getGroupsError = (state: RootState): ErrorState | null => state.groups.error;
export const getGroupsIsPending = (state: RootState): boolean => state.groups.pending;
