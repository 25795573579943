interface IZoomOutIconProps extends React.SVGAttributes<SVGElement> {
  sx?: { width?: string; height?: string; color?: string; fill?: string };
  color?: string;
}

const ZoomOutIcon = ({ sx, color, ...other }: IZoomOutIconProps): JSX.Element => {
  return (
    <svg
      width={sx?.width || sx?.height || "18px"}
      height={sx?.height || "18px"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L12.3107 6.75H15C15.4142 6.75 15.75 7.08579 15.75 7.5C15.75 7.91421 15.4142 8.25 15 8.25H10.5C10.0858 8.25 9.75 7.91421 9.75 7.5V3C9.75 2.58579 10.0858 2.25 10.5 2.25C10.9142 2.25 11.25 2.58579 11.25 3V5.68934L15.2197 1.71967ZM2.25 10.5C2.25 10.0858 2.58579 9.75 3 9.75H7.5C7.91421 9.75 8.25 10.0858 8.25 10.5V15C8.25 15.4142 7.91421 15.75 7.5 15.75C7.08579 15.75 6.75 15.4142 6.75 15V12.3107L2.78033 16.2803C2.48744 16.5732 2.01256 16.5732 1.71967 16.2803C1.42678 15.9874 1.42678 15.5126 1.71967 15.2197L5.68934 11.25H3C2.58579 11.25 2.25 10.9142 2.25 10.5Z"
        fill={color || sx?.color || sx?.fill}
      />
    </svg>
  );
};

export default ZoomOutIcon;
