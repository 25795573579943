import { scheduledServices } from "@adapters/api/services/ScheduledServices";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const refreshAll = createAsyncThunk("scheduled/status", async (_, { rejectWithValue }) => {
  try {
    const response = await scheduledServices.refreshAll();
    return response.status;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});
