// import { ReactComponent as ZoomIn } from "@assets/icons/zoomin_icon.svg";

interface IZoomInIconProps extends React.SVGAttributes<SVGElement> {
  sx?: { width?: string; height?: string; color?: string; fill?: string };
  color?: string;
}

const ZoomInIcon = ({ sx, color, ...other }: IZoomInIconProps): JSX.Element => {
  return (
    <svg
      width={sx?.width || sx?.height || "18px"}
      height={sx?.height || "18px"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M16.5 2.25V6.75C16.5 7.1625 16.1625 7.5 15.75 7.5C15.3375 7.5 15 7.1625 15 6.75V4.0575L12.03 7.0275C11.88 7.1775 11.6925 7.245 11.4975 7.245C11.3025 7.245 11.115 7.17 10.965 7.0275C10.6725 6.735 10.6725 6.2625 10.965 5.97L13.935 3H11.2425C10.83 3 10.4925 2.6625 10.4925 2.25C10.4925 1.8375 10.83 1.5 11.2425 1.5H15.7425C16.155 1.5 16.4925 1.8375 16.4925 2.25H16.5ZM6.75 15H4.0575L7.0275 12.03C7.32 11.7375 7.32 11.265 7.0275 10.9725C6.735 10.68 6.2625 10.68 5.97 10.9725L3 13.9425V11.25C3 10.8375 2.6625 10.5 2.25 10.5C1.8375 10.5 1.5 10.8375 1.5 11.25V15.75C1.5 16.1625 1.8375 16.5 2.25 16.5H6.75C7.1625 16.5 7.5 16.1625 7.5 15.75C7.5 15.3375 7.1625 15 6.75 15Z"
        fill={color || sx?.color || sx?.fill}
      />
    </svg>
  );
};

export default ZoomInIcon;
