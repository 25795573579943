import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Collapse,
  Typography,
  Divider,
  useTheme,
  SxProps,
  Theme,
} from "@mui/material";
import { BoxProps } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import ExpandButton from "@presentation/components/ExpandButton";
import { getId } from "@core/utils/Tools";

interface CollapsibleCardProps extends BoxProps {
  title: string | undefined;
  isExpanded?: boolean;
  cardSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  titleCardSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  dividerSx?: SxProps<Theme>;
  dividerAlwaysVisible?: boolean;
  icon?: React.JSX.Element;
  cannotChangeExpand?: boolean;
  cardId?: string;
  onCardExpansionChange?: (cardId: string, isExpanded: boolean) => void;
}

const CollapsibleCard = ({
  title,
  isExpanded,
  sx,
  cardSx,
  titleSx,
  contentSx,
  titleCardSx,
  dividerAlwaysVisible,
  dividerSx,
  icon,
  cardId,
  onCardExpansionChange,
  cannotChangeExpand,
  children,
  ...props
}: CollapsibleCardProps): React.JSX.Element => {
  const theme = useTheme();

  if (!icon)
    icon = <ArrowDownIcon sx={{ color: theme.palette.secondary.main }} style={{ height: "16px", width: "16px" }} />;

  const [mounted, setMounted] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = (): void => {
    if (cannotChangeExpand) return;
    cardId && onCardExpansionChange && onCardExpansionChange(cardId, !expanded);
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (mounted || !isExpanded) return;
    setExpanded(isExpanded);
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%", ...sx }} {...props}>
      <Card sx={{ minHeight: "56px", width: "100%", borderRadius: "8px", ...cardSx }}>
        <CardContent sx={{ height: "100%", ...titleCardSx }} onClick={handleExpandClick}>
          <Grid
            container
            item
            justifyContent="space-between"
            sx={{ minHeight: "32px", justifyItems: "center", alignItems: "center" }}
          >
            <Typography variant="h3" sx={titleSx}>
              {title}
            </Typography>
            {!cannotChangeExpand && (
              <ExpandButton id={getId("expand-button", props?.id)} expand={expanded} onClick={handleExpandClick}>
                {icon}
              </ExpandButton>
            )}
          </Grid>
          {dividerAlwaysVisible && (
            <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main, ...dividerSx }} variant="middle" />
          )}
        </CardContent>
        <Collapse in={expanded}>
          {!dividerAlwaysVisible && (
            <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main, ...dividerSx }} variant="middle" />
          )}
          <CardContent sx={{ overflow: "auto", ...contentSx }}>{children}</CardContent>
          <CardActions></CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default CollapsibleCard;
