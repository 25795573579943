import { Autocomplete, Box, BoxProps, styled, TextField, Typography, useTheme } from "@mui/material";
import LoopIcon from "@presentation/components/Icons/LoopIcon";
import React from "react";

export type TItem = {
  icon?: React.JSX.Element;
  label: string;
  value: string;
};

interface ITotalSearchSelectProps extends BoxProps {
  error?: boolean;
  label: string;
  items: TItem[];
  value: string;
  onValueChange: (value: string) => void;
}

const StyledAutocomplete = styled(Autocomplete<TItem, false, true, false>)(() => {
  const theme = useTheme();
  return {
    ".MuiFormControl-root": {
      border: theme.custom.select.border.default,
      "& .MuiAutocomplete-popupIndicator": {
        color: theme?.palette?.secondary?.main,
        transform: "none",
      },
      "& fieldset": {
        border: 0,
      },
      "& input": {
        padding: "0 !important",
      },
      height: "32px",
      borderRadius: "8px",
    },
  };
});

const StyledTextField = styled(TextField)(() => ({
  ".MuiOutlinedInput-root": {
    height: "32px",
  },
}));

const TotalSearchSelect = ({
  error,
  items,
  label,
  value,
  onValueChange,
  ...props
}: ITotalSearchSelectProps): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Box {...props}>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <StyledAutocomplete
        disableClearable
        value={items.find((item) => item.value === value)}
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, v) => option.value === v.value}
        popupIcon={<LoopIcon />}
        clearOnBlur={true}
        sx={{
          ...(error && { color: theme.palette.secondary.main }),
          borderRadius: theme.custom?.select?.border?.radius,
          border: !error ? theme.custom?.select?.border?.size : 2,
          borderColor: !error ? theme.custom?.select?.border?.color : theme.palette.secondary.main,
          padding: 0,
        }}
        renderInput={(params: any) => <StyledTextField {...params} size="small" />}
        renderOption={(p, option: TItem) => {
          return (
            <Box sx={{ display: "flex", gap: "10px" }} component="li" {...p} key={option.value}>
              <>{option.icon}</>
              <>{option.label}</>
            </Box>
          );
        }}
        onChange={(_e, v) => onValueChange(v?.value || "")}
      />
    </Box>
  );
};

export default TotalSearchSelect;
