import { Link } from "react-router-dom";
import LogoSquareIcon from "@presentation/components/Icons/LogoSquareIcon";

const TotalIcon = (props: any): JSX.Element => {
  return (
    <Link key="home" to="/" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <LogoSquareIcon
        sx={{
          width: "96px",
          m: "6px",
        }}
        alt="TotalEnergies"
        {...props}
      />
    </Link>
  );
};

export default TotalIcon;
