import { httpClient } from "@adapters/api/HttpClient";
import { BooleanResponse } from "@domain/entities/Commun";
import { UserRequest } from "@domain/entities/Users";

export const usersServices = {
  listUsers: async () => {
    return httpClient.get(`/v1/users`);
  },
  createUser: async (user: UserRequest) => {
    return httpClient.post(`/v1/users`, user);
  },
  deleteUser: async (userId: number) => {
    return httpClient.delete(`/v1/users/${userId}`);
  },
  updateUser: async (user: UserRequest) => {
    return httpClient.put(`/v1/users/${user.id}`, user);
  },
  validateUser: async (token: string) => {
    return httpClient.get(`/v1/users/validate`, {
      headers: {
        authorization: token,
      },
    });
  },
  rejectUser: async (token: string) => {
    return httpClient.get(`/v1/users/reject`, {
      headers: {
        authorization: token,
      },
    });
  },
  authenticateUser: async (code: string) => {
    return httpClient.get(`/v1/authentication`, {
      params: {
        code: code,
      },
    });
  },
  existInCustomerPortal: async () => {
    return httpClient.get<BooleanResponse>(`/v1/users/inTCSD`);
  },
  exportUsers: async () => {
    return httpClient.get(`/v1/users/export`);
  },
};
