import { getId } from "@core/utils/Tools";
import { Box, BoxProps, InputProps, SxProps, Theme } from "@mui/material";
import { InputLabel } from "@presentation/components/inputs/atoms/InputLabel";
import { InputTextField } from "@presentation/components/inputs/atoms/InputTextField";

type TextFieldProps = Omit<BoxProps, "onChange"> &
  Pick<InputProps, "disabled" | "error" | "value" | "type" | "endAdornment"> & {
    label?: string;
    width?: string;
    color?: string;
    onChange: (e: string) => void;
    inputProps?: InputProps;
    textPlace?: "top" | "left";
  };

const TextField = ({
  disabled,
  label,
  error,
  placeholder,
  onChange,
  width,
  color = "white",
  sx,
  value,
  type,
  inputProps,
  endAdornment,
  textPlace = "top",
  ...props
}: TextFieldProps): React.JSX.Element => {
  const sxLeft: SxProps<Theme> = {
    my: "auto",
    mr: "10px",
  };
  return (
    <Box
      sx={{ width: width, display: "flex", flexDirection: textPlace === "top" ? "column" : "row", ...sx }}
      {...props}
    >
      <InputLabel
        label={label}
        error={error}
        disabled={disabled}
        sx={{ ...(textPlace === "top" ? undefined : sxLeft) }}
      />
      <InputTextField
        id={getId("input", props?.id)}
        onChangeCustom={onChange}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        color={color}
        value={value}
        type={type}
        endAdornment={endAdornment}
        {...inputProps}
      />
    </Box>
  );
};

export default TextField;
