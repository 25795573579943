import { Box, Typography, BoxProps, useTheme, IconButton, DialogActions, DialogContent } from "@mui/material";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { useState } from "react";
import LegalFilesList from "./LegalFilesList";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { TotalModal } from "@presentation/components/TotalModal";

type LegalFilesModalProps = BoxProps;

const LegalFilesModal = (props: LegalFilesModalProps): JSX.Element => {
  const theme = useTheme();
  const { ref } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <Box>
      <Box ref={ref} sx={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }} onClick={handleOpen}>
        <LibraryBooksOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }} />
        <Typography variant="h6">{t("user_menu.legal_mentions")}</Typography>
      </Box>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              id="alert-dialog-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Typography variant="h1" sx={{ color: theme.palette.primary.main }}>
                {t("legal.mentions_title")}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <LegalFilesList fontSize={"12px"} />
          </DialogActions>
        </Box>
      </TotalModal>
    </Box>
  );
};

export default LegalFilesModal;
