import { Box, BoxProps, useTheme } from "@mui/material";

interface IButtonProps extends BoxProps {
  children: React.ReactNode;
}

const TotalIconButtonSecondary = ({ children, sx }: IButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        padding: "8px",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "43px",
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.custom.select?.border?.color}`,
        background: "white",
        boxShadow: theme.custom.shadow.button,
        ":hover": {
          background: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main}`,
          color: "white",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default TotalIconButtonSecondary;
