import { httpClient } from "@adapters/api/HttpClient";
import { GroupRequest } from "@domain/entities/Groups";

export const groupsServices = {
  listGroups: async () => {
    return httpClient.get("/v1/groups");
  },
  deleteGroup: async (groupId: number) => {
    return httpClient.delete(`/v1/groups/${groupId}`);
  },
  createGroup: async (group: GroupRequest) => {
    return httpClient.post("/v1/groups", group);
  },
  updateGroup: async (group: GroupRequest) => {
    return httpClient.put(`/v1/groups/${group.id}`, group);
  },
};
