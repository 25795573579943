import { Alert, Box, Breadcrumbs, Button, Divider, Grid, Link, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TextField from "@presentation/components/inputs/TextField";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useNavigate, useParams } from "react-router-dom";
import { clearUsersError } from "@adapters/store/users/slice";
import { useTranslation } from "react-i18next";
import { getMaintenances, getMaintenancesError, getMaintenancesIsPending } from "@adapters/store/maintenances/slice";
import { listMaintenances, updateMaintenance } from "@adapters/store/maintenances/thunk";
import DateSelection from "@presentation/components/inputs/DateTimeSelection";
import dayjs from "dayjs";
import { CheckboxRow } from "@presentation/components/inputs/CheckboxRow";
import { Maintenance } from "@domain/entities/Maintenance";

const ViewMaintenance = (): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchParams = useParams();

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [informUser, setInformUser] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [maintenance, setMaintenance] = useState<Maintenance | null>(null);
  const [exist, setExist] = useState<boolean>(true);
  const [mounted, setMounted] = useState<boolean>(false);

  const maintenanceList = useAppSelector(getMaintenances);

  const error = useAppSelector(getMaintenancesError);
  const pending = useAppSelector(getMaintenancesIsPending);

  const path = "/maintenances";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_maintenances")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("maintenances_create_update.update_title")}
    </Link>,
  ];

  const handleTitleChange = (value: string): void => {
    setTitle(value);
  };

  const handleStartDateChange = (value: dayjs.Dayjs | null): void => {
    if (value) setStartDate(value);
  };

  const handleEndDateChange = (value: dayjs.Dayjs | null): void => {
    if (value) setEndDate(value);
  };

  const handleInformUserChange = (value: boolean): void => {
    setInformUser(value);
  };

  const handleSubmit = async (): Promise<void> => {
    if (pending || !canSave) return;
    await dispatch(
      updateMaintenance({
        id: Number(searchParams.id),
        title,
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
        informUser,
      })
    );
    dispatch(listMaintenances());
    if (!error) navigate(path);
  };

  const validateStartDate = (ignoreNull = true): boolean => {
    if (ignoreNull) {
      if (!startDate) return true;
    } else {
      if (!startDate) return false;
    }
    return true;
  };

  const validateEndDate = (ignoreNull = true): boolean => {
    if (ignoreNull) {
      if (!endDate) return true;
    } else {
      if (!endDate) return false;
    }
    return endDate.isAfter(startDate);
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result = !!title.trim() && validateEndDate(false) && validateStartDate(false);
    setIsBusy(false);
    return result;
  };

  const scanErrorForm = (): void => {
    setEndDateError(!validateEndDate());
  };

  useEffect(() => {
    dispatch(listMaintenances());
    setMounted(true);
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.id) {
      setMaintenance(maintenanceList.find((m) => m.id === Number(searchParams.id)) ?? null);
    } else {
      setMaintenance(null);
    }
  }, [maintenanceList, searchParams.id]);

  useEffect(() => {
    if (!maintenance) return;
    setTitle(maintenance.title ?? "");
    setStartDate(maintenance.startDate ? dayjs(maintenance.startDate) : null);
    setEndDate(maintenance.endDate ? dayjs(maintenance.endDate) : null);
    setInformUser(maintenance.informUser ?? false);
  }, [maintenance]);

  useEffect(() => {
    setCanSave(handleFormIsValid());
    scanErrorForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, startDate, endDate, informUser]);

  useEffect(() => {
    if (pending || !mounted) {
      setExist(true);
      return;
    }
    setExist(!pending && !!maintenance);
  }, [pending, maintenance, mounted]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {error && (
        <Alert severity="error" onClose={() => dispatch(clearUsersError())} sx={{ my: 2 }}>
          {error?.message}
        </Alert>
      )}
      {!exist && (
        <Alert severity="error" sx={{ my: 2, width: "100%" }}>
          {"Maintenance does not exist"}
        </Alert>
      )}

      {maintenance && (
        <>
          <Typography sx={{ my: "28px", color: "#374649" }} variant="h2">
            {t("maintenances_create_update.update_title")}
          </Typography>
          <Grid container item direction="row" justifyContent="end" alignItems="end">
            <TotalButton
              id="update-maintenance-button"
              text={t("maintenances_create_update.update_maintenance")}
              icon=""
              height={22}
              disabled={!canSave}
              onClick={handleSubmit}
            />
          </Grid>
          <CollapsibleCard
            title={t("maintenances_create_update.1_maintenance_information")}
            sx={{ mt: "30px" }}
            isExpanded
            cannotChangeExpand
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
              <TextField
                id="title"
                label={t("maintenances_create_update.title_input")}
                value={title}
                error={title.trim().length === 0 && title.length > 0}
                onChange={handleTitleChange}
                width="31%"
              />
              <DateSelection
                label={t("maintenances_create_update.start_date_input")}
                date={startDate}
                onDateChange={handleStartDateChange}
                width="31%"
              />
              <DateSelection
                label={t("maintenances_create_update.end_date_input")}
                date={endDate}
                onDateChange={handleEndDateChange}
                width="31%"
                minDate={startDate}
                error={endDateError}
              />
              <CheckboxRow
                label={t("maintenances_create_update.inform_user_input")}
                checked={informUser}
                onCheckedChange={handleInformUserChange}
                sx={{ width: "31%" }}
                typographyProps={{
                  variant: "body2",
                }}
              />
            </Box>
          </CollapsibleCard>
        </>
      )}
    </Grid>
  );
};

export default ViewMaintenance;
