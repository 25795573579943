import { Dataset, DatasetTables } from "@domain/entities/Datasets";
import { Scope } from "@domain/entities/Scopes";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Chip,
  useTheme,
} from "@mui/material";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { ColumnChecked } from "@presentation/views/CreateRole.view";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SummaryRoleProps {
  selectedDataset?: Dataset;
  roleName: string;
  values: ColumnChecked[];
  tables: DatasetTables[];
  scopes?: Scope[];
}

const SummaryRole = (props: SummaryRoleProps): JSX.Element => {
  const theme = useTheme();
  const { selectedDataset, roleName, values, tables, scopes } = props;

  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 1, py: "15px", justifyContent: "space-between", gap: "10%" }}>
        <Box
          sx={{
            minWidth: "30%",
            overflow: "hidden",
            borderRadius: "8px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "8px 8px 0 0",
              padding: "15px",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("role_create_update.summary.title")}</Typography>
          </Box>
          <Box
            sx={{
              padding: "15px",
              border: 0,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: theme.custom?.lineColor?.main,
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("role_create_update.summary.dataset_with_dot")}</b>{" "}
              {selectedDataset?.datasetName || t("common.none")}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              <b>{t("role_create_update.summary.role_name_with_dot")}</b> {roleName}
            </Typography>
          </Box>
        </Box>

        {scopes && (
          <Box sx={{ flex: 1, borderRadius: "15px", overflow: "hidden", border: "1px solid #B7CBD3" }}>
            <Box
              sx={{
                display: "flex",
                background: theme.custom?.backgroundColor?.title,
                borderRadius: "8px 8px 0 0",
                padding: "15px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {t("role_create_update.summary.scopes_related", { count: scopes.length })}
              </Typography>
            </Box>
            {scopes.length > 0 ? (
              scopes.map((scope) => {
                return (
                  <Box
                    sx={{
                      padding: "15px",
                      border: 0,
                      borderTopWidth: "1px",
                      borderTopStyle: "solid",
                      borderTopColor: theme.custom?.lineColor?.main,
                    }}
                    key={`role_scope_${scope.id}`}
                  >
                    <Link
                      to={`/scopes/${scope.id}`}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: 400, display: "inline-flex", alignContent: "center" }}
                      >
                        {scope.scopeName}
                        <CallMadeOutlinedIcon
                          sx={{
                            color: theme.palette.secondary.main,
                            height: "14px",
                            translate: "-2px 5px",
                          }}
                        />
                      </Typography>
                    </Link>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  padding: "15px",
                  border: 0,
                  borderTopWidth: "1px",
                  borderTopStyle: "solid",
                  borderTopColor: theme.custom?.lineColor?.main,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  {t("role_create_update.summary.no_scopes_related")}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ py: "15px" }}>
        <Box sx={{ minWidth: "30%", borderRadius: "15px", overflow: "hidden", border: "1px solid #B7CBD3" }}>
          <Box
            sx={{
              display: "flex",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "8px 8px 0 0",
              padding: "15px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "16px" }}>{t("role_create_update.summary.values_to_filter")}</Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "16px" }}>
                {t("role_create_update.summary.value_count", { count: values.length })}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {tables.map((table) => {
              const nbColumns = table.columns.filter(
                (c) => values.filter((v) => v.columnId === c.id).length > 0
              ).length;
              return (
                <TableContainer
                  key={table.id}
                  sx={{
                    width: "48%",
                    my: "15px",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.custom?.lineColor?.main,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ borderRadius: "8px 8px 0 0" }}>
                        <TableCell colSpan={2}>
                          <Typography sx={{ fontSize: "16px" }}>{table.tableName}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ background: theme.custom?.backgroundColor?.title }}>
                        <TableCell>
                          <Typography sx={{ fontSize: "14px" }}>
                            {nbColumns} Column{nbColumns > 1 ? "s" : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "14px" }}>
                            <Chip
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "16px",
                                color: "white",
                                height: "18px",
                              }}
                              label={values.filter((v) => v.tableId === table.id).length}
                              component="span"
                            />{" "}
                            {t("role_create_update.summary.table_count", {
                              count: values.filter((v) => v.tableId === table.id).length,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {values
                        .filter((col) => col.tableId === table.id)
                        .map((col) => {
                          return (
                            <TableRow key={col.columnId}>
                              <TableCell>
                                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>{col.columnName}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>{col.value}</Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryRole;
