import { Box, styled, TextField, Typography, useTheme } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import React, { useState } from "react";
import { InputAutocomplete } from "@presentation/components/inputs/atoms/InputAutocomplete";
import { Subscription } from "@domain/entities/Subscriptions";

const StyledTextField = styled(TextField)(() => ({
  ".MuiOutlinedInput-root": {
    height: "32px",
    borderRadius: "8px",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const TotalSubsriptionsSearchBar = (props: any): React.JSX.Element => {
  const theme = useTheme();
  const { error, options, label } = props;
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <InputAutocomplete
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option: Subscription) => (option?.subscriptionName ? option.subscriptionName.trim() : "")}
        isOptionEqualToValue={(option: Subscription, value: Subscription) => option.id === value.id}
        popupIcon={<SearchRoundedIcon />}
        loading={loading}
        clearOnBlur={true}
        sx={{
          ...(error && { color: theme.palette.secondary.main }),
          padding: 0,
        }}
        renderInput={(params: any) => <StyledTextField size="small" {...params} />}
        renderOption={(p, option: Subscription) => {
          return (
            <Box component="li" {...p} key={option.id}>
              {option?.subscriptionName ? option.subscriptionName.trim() : ""}
            </Box>
          );
        }}
        {...props}
      />
    </Box>
  );
};

export default TotalSubsriptionsSearchBar;
