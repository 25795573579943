import React, { useEffect, useState } from "react";
import { models, service } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "powerbi-report-authoring";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Link,
  Alert,
  IconButton,
  MenuItem,
  Menu,
  useTheme,
} from "@mui/material";
import "@core/styles/ReportView.css";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import {
  getReportsIsPending,
  getReportPollingPending,
  getSingleReport,
  getReportsError,
  clearReportsError,
  getReportsUserIsPending,
  getReportsDownloadList,
  ReportDownloadResponse,
} from "@adapters/store/reports/slice";
import { downloadReport, getReport, getReportPolling, initializeDownload } from "@adapters/store/reports/thunk";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import ReportUsersListModal from "@presentation/components/ReportUsersListModal";
import ReportShareUsersModal from "@presentation/components/ReportShareUsersModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { getAuthUser } from "@adapters/store/users/slice";
import { useTranslation } from "react-i18next";
import { isAdmin, isUN1 } from "@core/right/Right";
import TotalZoomIconButton from "@presentation/components/TotalZoomIconButton";
import ZoomInIcon from "@presentation/components/Icons/ZoomInIcon";
import ZoomOutIcon from "@presentation/components/Icons/ZoomOutIcon";

const ViewReport = (): React.JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const [firstLaunched, setFirstLaunched] = useState<boolean>(false);
  const [download, setDownload] = useState<ReportDownloadResponse | undefined>(undefined);
  const editOpen = Boolean(anchorEl);
  const searchParams = useParams();
  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
  const apiReport = useAppSelector(getSingleReport);
  const isPending = useAppSelector(getReportsIsPending);
  const pendingPolling = useAppSelector(getReportPollingPending);
  const userPending = useAppSelector(getReportsUserIsPending);
  const downloadList = useAppSelector(getReportsDownloadList);
  const error = useAppSelector(getReportsError);
  const authUser = useAppSelector(getAuthUser);
  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    id: undefined,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  });
  const [isFullscreen, setIsFullscreen] = useState(false);

  const path = "/reports";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.reports")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("see_reports.view.title")}
    </Link>,
  ];

  const handleFullscreenToggle = (): void => {
    const iframe = document.querySelector(".fullscreenFrame");

    if (iframe) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      } else {
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        }
      }

      setIsFullscreen(!document.fullscreenElement);
    }
  };

  const isDownloading = (): boolean => {
    const status = download?.status ?? "NotStarted";
    return ["Downloading", "Preparing"].includes(status);
  };

  useEffect(() => {
    setDownload(downloadList.find((dl) => dl.reportId === Number(searchParams.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadList]);

  // Polling Embed Token
  useEffect(() => {
    if (!apiReport?.embedToken && !error && apiReport?.pollingId && !pendingPolling && !isPending) {
      setTimeout(() => {
        if (!pendingPolling) apiReport?.pollingId && dispatch(getReportPolling(apiReport.pollingId));
      }, 500);
    }
  }, [apiReport, dispatch, isPending, pendingPolling, error]);

  useEffect(() => {
    const id = Number(searchParams.id);
    if (alreadyLoaded) return;
    dispatch(getReport(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setIsLoaded(apiReport?.id === Number(searchParams.id));
    setAnchorEl(null);
    if (
      isPending === false &&
      userPending === false &&
      alreadyLoaded === false &&
      firstLaunched === true &&
      apiReport?.embedToken &&
      apiReport?.embedUrl
    ) {
      setReportConfig({
        ...reportConfig,
        id: apiReport?.externalId,
        embedUrl: apiReport?.embedUrl,
        accessToken: apiReport?.embedToken,
      });
      setAlreadyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, apiReport]);

  useEffect(() => {
    if (isPending) setFirstLaunched(true);
  }, [isPending]);

  const eventHandlersMap = new Map([
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);
  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }} className="proute">
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        {isLoaded && (
          <>
            <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
            <Box flex={1} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
              <Typography sx={{ color: "#374649" }} variant="h3">
                {apiReport?.reportName}
              </Typography>
              <Box flex={1} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
                {(isAdmin(authUser?.userRight) || isUN1(authUser?.userRight)) && (
                  <>
                    <Divider orientation="vertical" sx={{ mx: 1, height: 30 }} />
                    <ReportUsersListModal report={apiReport} />
                  </>
                )}
              </Box>
            </Box>
            <Box flex={1} justifyContent="flex-end" alignItems="center" sx={{ display: "flex" }}>
              <Box flex={1} justifyContent="flex-end" alignItems="center" sx={{ display: "flex" }}>
                <TotalButton
                  id="download-report-button"
                  text={isDownloading() ? t("see_reports.view.downloading") : t("see_reports.view.download")}
                  icon={isDownloading() ? "load" : "downloadReady"}
                  height={22}
                  disabled={isDownloading()}
                  onClick={() => {
                    if (download?.status === undefined) {
                      if (apiReport?.id) dispatch(initializeDownload(apiReport?.id));
                    } else {
                      if (apiReport?.id && download.downloadId)
                        dispatch(downloadReport({ reportId: apiReport.id, downloadId: download.downloadId }));
                    }
                  }}
                />
                {(isAdmin(authUser?.userRight) || isUN1(authUser?.userRight)) && (
                  <>
                    <Divider orientation="vertical" sx={{ mx: 1, height: 3 }} />
                    <IconButton
                      sx={{
                        color: theme.palette.secondary.main,
                        height: "22px",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreHorizIcon sx={{ cursor: "pointer", color: theme.palette.secondary.main }} />
                    </IconButton>
                  </>
                )}
                {(isAdmin(authUser?.userRight) || isUN1(authUser?.userRight)) && (
                  <Menu
                    open={editOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{ sx: { borderRadius: "8px", border: 1, borderColor: theme.custom?.lineColor?.main } }}
                    MenuListProps={{ sx: { py: 0 } }}
                  >
                    <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
                      <ReportShareUsersModal report={apiReport} />
                    </MenuItem>
                    {apiReport?.reportRedirectionUrl && (
                      <MenuItem sx={{ borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
                        <a
                          href={apiReport.reportRedirectionUrl}
                          style={{ textDecoration: "none", color: theme.palette.primary.main }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Box sx={{ display: "flex" }}>
                            <OpenInNewOutlinedIcon
                              sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }}
                            />
                            <Typography variant="h6">{t("see_reports.view.edit_in_powerbi")}</Typography>
                          </Box>
                        </a>
                      </MenuItem>
                    )}
                  </Menu>
                )}
              </Box>
            </Box>
          </>
        )}
      </Grid>
      <Box flex={1} justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
        {error && (
          <Alert severity="error" onClose={() => dispatch(clearReportsError())} sx={{ my: 2 }}>
            {t(`error.${error?.message}`)}
          </Alert>
        )}
        {isPending || !apiReport?.embedToken ? (
          !error && (
            <Box flex={1} justifyContent="center" alignItems="center" sx={{ display: "flex", height: "100%" }}>
              <CircularProgress />
            </Box>
          )
        ) : (
          <>
            {isLoaded && (
              <Box
                flex={1}
                justifyContent="center"
                alignItems="center"
                className="fullscreenFrame"
                sx={{ mt: 1, height: "100%", position: "relative" }}
              >
                <PowerBIEmbed
                  embedConfig={reportConfig}
                  eventHandlers={eventHandlersMap}
                  cssClassName={"frameContainer"}
                />
                <TotalZoomIconButton
                  sx={{
                    position: "absolute",
                    bottom: "15px",
                    right: "15px",
                  }}
                  onClick={handleFullscreenToggle}
                  size="medium"
                  secondary
                >
                  {isFullscreen ? <ZoomOutIcon /> : <ZoomInIcon />}
                </TotalZoomIconButton>
              </Box>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default ViewReport;
