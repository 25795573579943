import { toaster } from "@core/Toaster";
import { AxiosError } from "axios";
import i18n from "@core/I18n";
import { history } from "@core/utils/History";
import { postError } from "@adapters/store/reporting/thunk";

const { t } = i18n;

type ErrorWithMessage = {
  message: string;
  statusCode?: number;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return typeof (error as ErrorWithMessage)?.message === "string";
};

const checkMaintenance = (status: number, message: string): boolean => {
  const isMaintenance = status === 503 && message === "maintenance";
  if (isMaintenance) {
    if (history?.location?.pathname === "/maintenance") return isMaintenance;
    if (history.navigate) history.navigate("/maintenance");
  }
  return isMaintenance;
};

const ignoreError = (status: number, message: string): boolean => {
  return checkMaintenance(status, message);
};

export const errorHandler = (err: unknown, details?: string, defaultMsg = t(`error.unknown`)): void => {
  let status = 500;
  if (err instanceof AxiosError) {
    defaultMsg = err.response?.data.message || defaultMsg;
    status = err.response?.status ?? 500;
  } else if (isErrorWithMessage(err)) {
    defaultMsg = err.message;
    status = err?.statusCode ?? 500;
  }
  if (ignoreError(status, defaultMsg)) return;
  const translatedMsg = t(`error.${defaultMsg}`, { defaultValue: t(`error.unknown`) });
  if (translatedMsg === t(`error.unknown`)) {
    postError(JSON.stringify({ error: JSON.stringify(err), details }));
  }
  toaster.error(t(`error.${defaultMsg}`, { defaultValue: t(`error.unknown`) }));
};
