import { DialogActions, DialogContent, Divider, Typography, Box, useTheme } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Stack } from "@mui/system";
import TotalButton from "@presentation/components/TotalButton";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { TotalModal } from "@presentation/components/TotalModal";

interface IDialogProps {
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  handleClick(): void;
}

const RemoveUsersReportModal = (props: IDialogProps): React.JSX.Element => {
  const theme = useTheme();
  const { handleClick, user } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleValidate = (): void => {
    handleClick();
    setOpen(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <CloseOutlinedIcon
        sx={{ color: theme.palette.secondary.main, height: "20px", cursor: "pointer" }}
        onClick={handleClickOpen}
      />
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        destructive
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ mt: 3, mx: 5 }}>
            <Box
              id="alert-dialog-description"
              sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
            >
              <Typography variant="h1" sx={{ mb: "28px", color: theme.palette.primary.main }}>
                {t("delete_user_on_report_modal.confirm_title")}
              </Typography>
              <Typography variant="h4" sx={{ mb: "12px", color: theme.palette.primary.main }}>
                {t("common.full_name", { firstName: user.firstName, lastName: user.lastName })}
              </Typography>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                {t("delete_user_on_report_modal.no_longer_access_to")}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <Stack direction="row" spacing="24px">
              <TotalButton
                id="cancel-remove-user-access-button"
                text={t("delete_modal.cancel")}
                icon="arrowLeft"
                height={12}
                iconColor={theme.palette.secondary.main}
                textColor={theme.palette.primary.main}
                buttonColor="white"
                borderColor={theme.custom?.lineColor?.main}
                onClick={handleClose}
              />
              <Divider orientation="vertical" flexItem></Divider>
              <TotalButton
                id="validate-remove-user-access-button"
                text={t("delete_user_on_report_modal.confirm")}
                icon="check"
                height={22}
                onClick={handleValidate}
                primary
              />
            </Stack>
          </DialogActions>
        </Box>
      </TotalModal>
    </>
  );
};

export default RemoveUsersReportModal;
