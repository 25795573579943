import { ReactComponent as ArrowUp } from "@assets/icons/arrow_up_icon.svg";

const ArrowUpIcon = (props: any): JSX.Element => {
  return (
    <ArrowUp
      fill={props?.sx?.color || props?.sx?.fill}
      height={props?.sx?.height || "16px"}
      width={props?.sx?.width || props?.sx?.height || "16px"}
      {...props}
    />
  );
};

export default ArrowUpIcon;
