import { createScope, deleteScope, listScopes, updateScope } from "@adapters/store/scopes/thunk";
import i18n from "@core/I18n";
import { toaster } from "@core/Toaster";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { message, sortAsc } from "@core/utils/StringTools";
import { Scope, ScopeByDatasetGroup } from "@domain/entities/Scopes";
import { createSelector, createSlice } from "@reduxjs/toolkit";

const { t } = i18n;

// Define a type for the slice state
interface ScopesState {
  list: Scope[];
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: ScopesState = {
  list: [],
  pending: false,
  error: null,
};

const subject = "The scope";

export const scopesSlice = createSlice({
  name: "scopes",
  initialState,
  reducers: {
    clearScopesError: (state: ScopesState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST SCOPES
    builder.addCase(listScopes.fulfilled, (state, action) => {
      state.list = action.payload
        .filter((r) => r.id !== 1)
        .map((scope) => {
          return {
            ...scope,
            datasetLength: scope.datasets.length,
            totalRoles: scope.datasets.map((d) => d.roles.length).reduce((a, b) => a + b, 0),
          } as Scope;
        })
        .sort((a, b) => sortAsc(a.scopeName, b.scopeName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listScopes.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listScopes");
      state.pending = false;
    });
    builder.addCase(listScopes.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE SCOPE
    builder.addCase(deleteScope.fulfilled, (state, action) => {
      toaster.success(message.success.deleted(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteScope.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteScope");
      state.pending = false;
    });
    builder.addCase(deleteScope.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE SCOPE
    builder.addCase(createScope.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list = state.list.sort((a, b) => sortAsc(a.scopeName, b.scopeName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createScope.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createScope");
      state.pending = false;
    });
    builder.addCase(createScope.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE SCOPE
    builder.addCase(updateScope.fulfilled, (state, action) => {
      toaster.success(message.success.updated(subject));
      const list = state.list.filter((r) => r.id !== action.meta.arg.id);
      list.push(action.payload);
      state.list = list.sort((a, b) => sortAsc(a.scopeName, b.scopeName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateScope.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateScope");
      state.pending = false;
    });
    builder.addCase(updateScope.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
// export const { scopeAlreadyExists } = scopesSlice.actions;
export const { clearScopesError } = scopesSlice.actions;

// Define selectors to be used in UI here
export const getScopesCount = (state: RootState): number => state.scopes.list.length;
export const getScopes = (state: RootState): Scope[] => state.scopes.list;
export const getScopesError = (state: RootState): ErrorState | null => state.scopes.error;
export const getScopesIsPending = (state: RootState): boolean => state.scopes.pending;
export const getAdminScopes = createSelector([getScopes], (scopes: Scope[]): Scope[] =>
  scopes.filter((s: Scope) => s.userPermission === 1)
);

export const getScopesByDataset = createSelector([getScopes], (scopes?: Scope[]): ScopeByDatasetGroup[] => {
  const result: ScopeByDatasetGroup[] = [];
  if (!scopes) return result;
  scopes.forEach((scope: Scope) => {
    const inResult = result.find(
      (r) => scope.datasets.every((d) => r.datasetIds.includes(d.id)) && scope.datasets.length === r.datasetIds.length
    );
    if (inResult) {
      inResult.scopes.push(scope);
    } else {
      result.push({
        datasetIds: scope.datasets.map((d) => d.id),
        name:
          scope.datasets.length === 0
            ? t("manage_scopes.without_dataset")
            : t("manage_scopes.from_datasets", {
                datasets: scope.datasets
                  .map((d) => d.datasetName)
                  .sort((a, b) => sortAsc(a, b))
                  .join(", "),
              }),
        scopes: [scope],
      });
    }
  });

  return result.sort((a, b) => {
    if (a.datasetIds.length === b.datasetIds.length) return sortAsc(a.name, b.name);
    return a.datasetIds.length > b.datasetIds.length ? 1 : -1;
  });
});
