import { refreshAll } from "@adapters/store/scheduled/thunk";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface ScheduledState {
  status: number;
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: ScheduledState = {
  status: 999,
  pending: false,
  error: null,
};

export const scheduledSlice = createSlice({
  name: "scheduled",
  initialState,
  reducers: {
    clearScheduledError: (state: ScheduledState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    builder.addCase(refreshAll.fulfilled, (state, action) => {
      state.status = action.payload;
      state.error = null;
      state.pending = false;
    });
    builder.addCase(refreshAll.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "refreshAll");
      state.status = 400;
      state.pending = false;
    });
    builder.addCase(refreshAll.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define selectors to be used in UI here
export const { clearScheduledError } = scheduledSlice.actions;

export const getScheduledStatus = (state: RootState): number => state.scheduled.status;
export const getScheduledError = (state: RootState): ErrorState | null => state.scheduled.error;
export const getScheduledIsPending = (state: RootState): boolean => state.scheduled.pending;
