import TotalTableHead from "@presentation/components/scopes/TotalScopesHead";
import TotalTableRow from "@presentation/components/scopes/TotalScopesRow";
import { ScopeHeadCellData } from "@domain/Interfaces/HeadCellData.d";
import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import React, { useEffect, useState } from "react";
import { ScopeHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Scope } from "@domain/entities/Scopes";
import {
  stableSort,
  getComparator,
  calculateNumberOfEmptyRows,
  getPageNumber,
  defaultTableSx,
} from "@presentation/components/table/utils";
import EmptyRows from "@presentation/components/table/EmptyRow";
import useStoredPagination from "@presentation/utils/StoredPagination";
import useStoredRowsPerPage from "@presentation/utils/StoredRowsPerPage";

type ScopeTableProps = {
  headCells: ScopeHeadCell[];
  rows: Scope[];
  storageKey: string;
};

const TotalScopesTable = ({ headCells, rows, storageKey }: ScopeTableProps): React.JSX.Element => {
  const [storedPagination, setStoredPagination] = useStoredPagination(`scopes_${storageKey}`, {
    page: 0,
    order: "asc",
    orderBy: "scopeName",
  });
  const [rowsPerPage, setRowsPerPage] = useStoredRowsPerPage(10);
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));
  if (storedPagination.page > pageNumber) {
    setStoredPagination({ ...storedPagination, page: 0 });
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof ScopeHeadCellData): void => {
    const isAsc = storedPagination.orderBy === property && storedPagination.order === "asc";
    setStoredPagination({ ...storedPagination, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setStoredPagination({ ...storedPagination, page: newPage - 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setStoredPagination({ ...storedPagination, page: 0 });
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <TotalTableHead
            order={storedPagination.order as Order}
            orderBy={storedPagination.orderBy as keyof ScopeHeadCellData}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(
              rows,
              getComparator(storedPagination.order as Order, storedPagination.orderBy as keyof ScopeHeadCellData)
            )
              .slice(storedPagination.page * rowsPerPage, storedPagination.page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TotalTableRow
                    key={`scopes-list-${row.id}`}
                    rows={rows}
                    row={row}
                    highlight={row.datasetLength === 0}
                  />
                );
              })}
            <EmptyRows emptyNumber={calculateNumberOfEmptyRows(storedPagination.page, rowsPerPage, rows.length)} />
          </TableBody>
        </Table>
      </TableContainer>
      <TotalTablePagination
        page={storedPagination.page}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        nbItems={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default TotalScopesTable;
