import { TokenManager } from "@adapters/store/users/TokenManager";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from "axios";

class HttpClient {
  httpClient: AxiosInstance;
  timer: number;

  constructor(config: AxiosRequestConfig, headers: RawAxiosRequestHeaders) {
    this.httpClient = axios.create({
      headers,
      withCredentials: true,
      responseType: "json",
      timeout: 400000,
      ...config,
    });

    this.timer = 0;

    // Setting an interceptor to log the request
    this.httpClient.interceptors.request.use((requestConfig: InternalAxiosRequestConfig) => {
      const conf = { ...requestConfig };
      this.timer = new Date().getTime();
      return conf;
    });

    // Setting an interceptor on response and error
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status === 401) {
          TokenManager.delete();
        }
        const refreshedToken = response.headers["refreshed-token"];
        if (typeof refreshedToken === "string") {
          TokenManager.setToken(refreshedToken);
          window.dispatchEvent(new Event("storage"));
        }
        return response;
      },
      (error: AxiosError) => {
        // On 401 redirect will redirect user to authenticate page
        if (error.response?.status === 401) {
          TokenManager.delete();
          window.location.assign("/authenticate");
        }
        throw error;
      }
    );
  }

  getClient(): AxiosInstance {
    return this.httpClient;
  }

  updateAuthorization(): void {
    this.httpClient.defaults.headers["authorization"] = `Bearer ${TokenManager.getToken() ?? ""}`;
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.httpClient.get(url, config);
  }

  post<T, P>(url: string, body: T, config?: AxiosRequestConfig): Promise<AxiosResponse<P>> {
    return this.httpClient.post(url, body, config);
  }

  patch<T, P>(url: string, body: T, config?: AxiosRequestConfig): Promise<AxiosResponse<P>> {
    return this.httpClient.patch(url, body, config);
  }

  put<T, P>(url: string, body: T, config?: AxiosRequestConfig): Promise<AxiosResponse<P>> {
    return this.httpClient.put(url, body, config);
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.httpClient.delete(url, config);
  }
}

export const getAuthorizationHeader = (): string => `Bearer ${TokenManager.getToken()}`;

const httpClient = new HttpClient(
  {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    responseType: "json",
    timeout: 400000,
  },
  {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
    authorization: `Bearer ${TokenManager.getToken()}`,
  }
);

export { httpClient };
