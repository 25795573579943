import { useState } from "react";

export interface CollapsibleCardExpansions {
  [cardId: string]: boolean;
}

const STORAGE_KEY = "storedCollapsibleCardExpansions";

const useStoredCollapsibleCardExpansions = (
  initialCollapsibleCardExpansions: CollapsibleCardExpansions
): [CollapsibleCardExpansions, (expansions: CollapsibleCardExpansions) => void] => {
  const getInitialCollapsibleCardExpansions = (): CollapsibleCardExpansions => {
    const storedExpansions = sessionStorage.getItem(STORAGE_KEY);
    try {
      return storedExpansions ? JSON.parse(storedExpansions) : initialCollapsibleCardExpansions;
    } catch (error) {
      console.error(`Error parsing storedCollapsibleCardExpansions:`, error);
      return initialCollapsibleCardExpansions;
    }
  };

  const [storedCollapsibleCardExpansions, setStoredCollapsibleCardExpansions] = useState<CollapsibleCardExpansions>(
    getInitialCollapsibleCardExpansions
  );

  const updateStoredCollapsibleCardExpansions = (newExpansions: CollapsibleCardExpansions): void => {
    setStoredCollapsibleCardExpansions(newExpansions);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newExpansions));
  };

  return [storedCollapsibleCardExpansions, updateStoredCollapsibleCardExpansions];
};

export default useStoredCollapsibleCardExpansions;
