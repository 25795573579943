import { MaintenanceHeadCell } from "@domain/Interfaces/HeadCell.d";
import { TableHead, TableRow, TableCell, TableSortLabel, useTheme } from "@mui/material";
import Order from "@domain/Interfaces/Order.d";
import { StyledArrowSortIcon, StyledArrowUpIcon, StyledArrowDownIcon } from "@presentation/components/TotalFilterIcon";
import { useTranslation } from "react-i18next";
import React from "react";
import { MaintenanceHeadCellData } from "@domain/Interfaces/HeadCellData";

interface MaintenanceHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MaintenanceHeadCellData) => void;
  order: Order;
  orderBy: string;
  headCells: MaintenanceHeadCell[];
}

const MaintenanceHead = ({ order, orderBy, onRequestSort, headCells }: MaintenanceHeadProps): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const createSortHandler = (property: keyof MaintenanceHeadCellData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: MaintenanceHeadCell) => {
          let iconComponent = StyledArrowSortIcon;
          if (orderBy === headCell.id && order === "asc") {
            iconComponent = StyledArrowUpIcon;
          } else if (orderBy === headCell.id) {
            iconComponent = StyledArrowDownIcon;
          }
          return (
            <TableCell
              key={String(headCell.id)}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.enableSorting ? (
                <TableSortLabel
                  active
                  sx={{ color: orderBy === headCell.id ? theme.palette.secondary.main : "inherit" }}
                  IconComponent={iconComponent}
                  onClick={
                    headCell.enableSorting
                      ? createSortHandler(headCell.id as keyof MaintenanceHeadCellData)
                      : () => undefined
                  }
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
        <TableCell align="right">{t("manage_groups.headers.actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MaintenanceHead;
