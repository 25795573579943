import TotalTableHead from "@presentation/components/users/TotalUsersHead";
import TotalTableRow from "@presentation/components/users/TotalUsersRow";
import { UserHeadCellData } from "@domain/Interfaces/HeadCellData.d";
import Order from "@domain/Interfaces/Order.d";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserHeadCell } from "@domain/Interfaces/HeadCell.d";
import { User } from "@domain/entities/Users";
import TotalTablePagination from "@presentation/components/TotalTablePagination";
import { calculateNumberOfEmptyRows, defaultTableSx, getPageNumber } from "@presentation/components/table/utils";
import EmptyRows from "@presentation/components/table/EmptyRow";
import { StoredPagination } from "@presentation/utils/StoredPagination";
import useStoredRowsPerPage from "@presentation/utils/StoredRowsPerPage";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface TotalUsersTableProps {
  headCells: UserHeadCell[];
  rows: User[];
  storedPagination: StoredPagination;
  setStoredPagination: (pagination: StoredPagination) => void;
}

const TotalUsersTable = ({
  headCells,
  rows,
  storedPagination,
  setStoredPagination,
}: TotalUsersTableProps): JSX.Element => {
  const [rowsPerPage, setRowsPerPage] = useStoredRowsPerPage(10);
  const [pageNumber, setPageNumber] = useState<number>(getPageNumber(rowsPerPage, rows.length));
  if (storedPagination.page > pageNumber) {
    setStoredPagination({ ...storedPagination, page: 0 });
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof UserHeadCellData): void => {
    const isAsc = storedPagination.orderBy === property && storedPagination.order === "asc";
    setStoredPagination({ ...storedPagination, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setStoredPagination({ ...storedPagination, page: newPage - 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setStoredPagination({ ...storedPagination, page: 0 });
  };

  useEffect(() => {
    setPageNumber(getPageNumber(rowsPerPage, rows.length));
  }, [rowsPerPage, rows]);

  const emptyRows = calculateNumberOfEmptyRows(storedPagination.page, rowsPerPage, rows.length);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={defaultTableSx} aria-labelledby="tableTitle">
          <TotalTableHead
            order={storedPagination.order as Order}
            orderBy={storedPagination.orderBy as keyof UserHeadCellData}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(
              rows,
              getComparator(storedPagination.order as Order, storedPagination.orderBy as keyof UserHeadCellData)
            )
              .slice(storedPagination.page * rowsPerPage, storedPagination.page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TotalTableRow
                    key={`user-list-${row.id}`}
                    row={row}
                    labelId={labelId}
                    // highlight={row.isActive === 0}
                  />
                );
              })}
            <EmptyRows emptyNumber={emptyRows} />
          </TableBody>
        </Table>
        <TotalTablePagination
          page={storedPagination.page}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          nbItems={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default TotalUsersTable;
