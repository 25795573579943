import { TableRow, tableRowClasses, TableRowProps } from "@mui/material";
import React from "react";

interface ITableRowProps extends TableRowProps {
  highlight?: any;
}

const StyledTableRow = ({ children, highlight, sx }: ITableRowProps): React.JSX.Element => {
  return (
    <TableRow
      sx={{
        [`&.${tableRowClasses.root}`]: {
          boxShadow: "0px 1px 1px rgba(183, 203, 211, 0.8), 0px 6px 8px rgba(183, 203, 211, 0.32)",
          borderRadius: "8px",
          backgroundColor: highlight ? "rgba(237, 0, 0, 0.04)" : "white",
          display: "table-row",
        },
        ...sx,
      }}
    >
      {children}
    </TableRow>
  );
};

export default StyledTableRow;
