import { Box, BoxProps, useTheme } from "@mui/material";

interface TooltipIconTriggerProps extends BoxProps {
  tooltipState: boolean;
}

const TooltipIconTrigger = ({ sx, children, tooltipState, ...props }: TooltipIconTriggerProps): React.JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "inline-flex",
        padding: "8px",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "50%",
        backgroundColor: tooltipState ? theme.custom.colors.hover.lightRed : "transparent",
        boxShadow: tooltipState ? theme.custom.shadow.buttonInset : "none",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default TooltipIconTrigger;
