import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActionArea,
  IconButton,
  InputProps,
  useTheme,
} from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import TotalSelect from "@presentation/components/TotalSelect";
import { Group } from "@domain/entities/Groups";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { ScopeColumn } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";

interface UserFieldProps extends InputProps {
  scope: ScopeColumn;
  scopeIdx: number;
  permissionId: number;
  className: string;
  width?: string;
  handleValue: (idx: number, value: number) => void;
  group?: Group;
}

const ScopePermissionField = (props: UserFieldProps): JSX.Element => {
  const theme = useTheme();
  const { scope, scopeIdx, className, permissionId, width, handleValue, group } = props;
  const { t } = useTranslation();
  const items = [
    { id: 1, optionName: t("rights.admin") },
    { id: 2, optionName: t("rights.read_and_edit") },
    { id: 3, optionName: t("rights.read_only") },
  ];

  return (
    <TotalTooltip
      placement="bottom"
      title={group ? t("scope_permission_field.from_group", { groupName: group.groupName }) : ""}
    >
      <Box {...(className && { className })} sx={{ width: width || "48%", mt: 2, mx: 1 }}>
        <Card
          sx={{
            borderRadius: "16px",
            backgroundColor: theme.custom?.tabLineColor?.main,
            border: 1,
            borderColor: theme.custom?.lineColor?.main,
            padding: "8px",
          }}
        >
          <CardContent sx={{ height: "100%", py: 0 }}>
            <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  maxWidth: "40%",
                }}
              >
                <Typography variant="backButton" noWrap={true} sx={{ fontSize: "14px" }}>
                  {scope.scopeName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "1vw",
                }}
              >
                <TotalSelect
                  key="column-value"
                  value={permissionId}
                  items={items}
                  disabled={!!group}
                  onChange={(val: number) => {
                    handleValue(scopeIdx, val);
                  }}
                  placeholder={t("placeholder.requested_value")}
                />
                {!group && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <IconButton sx={{ padding: 0 }} onClick={() => handleValue(scopeIdx, -1)}>
                      <CancelIcon
                        sx={{
                          color: "#ffffff",
                          boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                          backgroundColor: "#7098A7",
                          borderRadius: "15px",
                          border: 0,
                          height: "30px",
                          width: "auto",
                        }}
                        viewBox="1 1 22 22"
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            </Grid>
          </CardContent>
          <CardActionArea></CardActionArea>
        </Card>
      </Box>
    </TotalTooltip>
  );
};

export default ScopePermissionField;
