import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import DeleteModal from "@presentation/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import TableDivider from "@presentation/components/table/TableDivider";
import { Maintenance } from "@domain/entities/Maintenance";
import { getMaintenancesIsPending } from "@adapters/store/maintenances/slice";
import { useState } from "react";
import { deleteMaintenance } from "@adapters/store/maintenances/thunk";

interface MaintenanceRowProps extends TableRowProps {
  row: Maintenance;
}

const MaintenanceRow = ({ row }: MaintenanceRowProps): React.JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [timeFormat] = useState("DD/MM/YYYY HH:mm");

  const isPending = useAppSelector(getMaintenancesIsPending);

  const navigate = useNavigate();

  return (
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <b>{row.title}</b>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.startDate.format(timeFormat)}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.endDate.format(timeFormat)}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.status}</b>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 20px 5px 20px",
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.secondary.main,
                height: "22px",
                "&:hover": {
                  background: "none",
                },
              }}
              onClick={() => navigate("/maintenances/" + row.id)}
            >
              <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </IconButton>
          </Box>
          <TableDivider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 5px 5px 20px",
            }}
          >
            <DeleteModal
              type="maintenance"
              handleClick={async () => {
                await dispatch(deleteMaintenance(row.id));
              }}
              pending={isPending}
            />
          </Box>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default MaintenanceRow;
