import AddIcon from "@presentation/components/Icons/AddIcon";
import AddUserIcon from "@presentation/components/Icons/AddUserIcon";
import AddUserReportIcon from "@presentation/components/Icons/AddUserReportIcon";
import AdminIcon from "@presentation/components/Icons/AdminIcon";
import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import ArrowSortIcon from "@presentation/components/Icons/ArrowSortIcon";
import ArrowUpIcon from "@presentation/components/Icons/ArrowUpIcon";
import ChartIcon from "@presentation/components/Icons/ChartIcon";
import ImportIcon from "@presentation/components/Icons/ImportIcon";
import UserIcon from "@presentation/components/Icons/UserIcon";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

interface IIconList {
  name: string;
  icon: React.JSX.Element;
}

interface IButtonProps extends ButtonProps {
  text?: string;
  icon?: string | React.JSX.Element;
  link?: string;
  target?: string;
  width?: string;
  disabled?: boolean;
  buttonColor?: string;
  iconColor?: string;
  textColor?: string;
  borderColor?: string;
  height: number;
  primary?: boolean;
}

const TotalButton = ({
  text,
  icon,
  link,
  target,
  width,
  buttonColor,
  iconColor,
  height,
  textColor,
  borderColor,
  onClick,
  disabled,
  primary = false,
  ...props
}: IButtonProps): React.JSX.Element => {
  const theme = useTheme();
  if (!link && !text && !icon) return <></>;

  const iconSx = { color: iconColor };
  const iconStyle = { height: `${height}px`, width: `${height}px` };

  // Must be deleted when all icons are replaced by SvgIcon version (see ArrowDownIcon.tsx or DownloadIcon.tsx for example)
  const iconList: Array<IIconList> = [
    {
      name: "add",
      icon: <AddIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "addUser",
      icon: <AddUserIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "addUserReport",
      icon: <AddUserReportIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "admin",
      icon: <AdminIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "arrowDown",
      icon: <ArrowDownIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "arrowLeft",
      icon: <ArrowLeftIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "arrowSort",
      icon: <ArrowSortIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "arrowUp",
      icon: <ArrowUpIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "chart",
      icon: <ChartIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "check",
      icon: <CheckOutlinedIcon sx={iconSx} style={iconStyle} />,
    },
    { name: "download", icon: <CloudDownloadOutlinedIcon /> },
    { name: "downloadReady", icon: <DownloadOutlinedIcon /> },
    {
      name: "import",
      icon: <ImportIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "load",
      icon: (
        <LoopIcon
          sx={{
            animation: "spin 2s linear infinite",
            "@keyframes spin": {
              "0%": {
                transform: "rotate(360deg)",
              },
              "100%": {
                transform: "rotate(0deg)",
              },
            },
          }}
        />
      ),
    },
    { name: "logIn", icon: <LoginOutlinedIcon /> },
    { name: "logOut", icon: <LogoutOutlinedIcon /> },
    {
      name: "sync",
      icon: <CloudSyncOutlinedIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "user",
      icon: <UserIcon sx={iconSx} style={iconStyle} />,
    },
    {
      name: "users",
      icon: <PeopleAltOutlinedIcon sx={{ ...iconSx, cursor: "pointer" }} style={iconStyle} />,
    },
  ];

  const getIcon = (i: string | React.JSX.Element): React.JSX.Element => {
    if (typeof i === "string") {
      return iconList.find((x) => x.name === i)?.icon || <></>;
    } else {
      return i;
    }
  };

  const getHoverBackground = (isPrimary: boolean, buttonColorR?: string): string => {
    if (isPrimary) return theme.custom.colors.hover.brand.primary[500];
    if (buttonColorR) return buttonColorR;
    return theme.custom.tertiary.main;
  };

  const getBackground = (isPrimary: boolean, buttonColorR?: string): string => {
    if (isPrimary) return theme.palette.secondary.main;
    if (buttonColorR) return buttonColorR;
    return theme.custom.tertiary.main;
  };

  const styledButton = (
    <Button
      sx={{
        "&.MuiButton-text": {
          "&:hover": {
            color: textColor ?? theme.custom.button.textColor,
            backgroundColor: getHoverBackground(primary, buttonColor),
            borderColor: getHoverBackground(primary, buttonColor),
          },
          "&.Mui-disabled": {
            borderColor: theme.custom.lineColor.main,
            backgroundColor: theme.custom.lineColor.main,
          },
        },
        display: "flex",
        height: "32px",
        borderRadius: "43px",
        border: 1,
        borderColor: getBackground(primary, buttonColor),
        background: getBackground(primary, buttonColor),
        color: textColor ?? theme.custom.button.textColor,
        px: 2,
        boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
        width: width,
        zIndex: 2,
      }}
      startIcon={icon && getIcon(icon)}
      onClick={onClick}
      disabled={disabled}
      disableElevation={disabled}
      {...props}
    >
      {text && (
        <Typography variant="button" noWrap={true}>
          {text}
        </Typography>
      )}
    </Button>
  );

  if (link)
    return (
      <Link to={link} target={target} style={{ textDecoration: "none", display: "inline-flex" }}>
        {styledButton}
      </Link>
    );
  return styledButton;
};

export default TotalButton;
