import styled from "@emotion/styled";
import { IconButton, IconButtonProps, useTheme } from "@mui/material";

interface ExpandButtonProps extends IconButtonProps {
  expand: boolean;
}

const ExpandButton = styled((props: ExpandButtonProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => {
  const theme = useTheme();
  return {
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    ":hover": {
      background: "none",
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  };
});

export default ExpandButton;
