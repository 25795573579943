import { Box, IconButton, MenuItem, Menu, Grid, TableRowProps, useTheme } from "@mui/material";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Dataset } from "@domain/entities/Roles";
import { Report } from "@domain/entities/Reports";
import { User } from "@domain/entities/Users";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import ReportShareUsersModal from "@presentation/components/ReportShareUsersModal";
import ReportUsersListMenuModal from "@presentation/components/ReportUsersListMenuModal";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import TableDivider from "@presentation/components/table/TableDivider";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getFavoriteReports } from "@adapters/store/reports/slice";
import { addFavorite, removeFavorite } from "@adapters/store/reports/thunk";
import { StarIcon } from "@presentation/components/Icons/StarIcon";
import { StarFilledIcon } from "@presentation/components/Icons/StartFilledIcon";
import ReportManageSubscriptionsModal from "@presentation/components/ReportManageSubscriptionsModal";

interface ReportRowProps extends TableRowProps {
  apiReport: Report;
  highlight: boolean;
  labelId: string;
  datasets: Dataset[];
  users: User[];
}

const TotalTableRow = ({ apiReport, highlight, datasets, users }: ReportRowProps): React.JSX.Element => {
  const theme = useTheme();
  const reportRoles = apiReport.roles ?? [];
  const favorite = useAppSelector(getFavoriteReports);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const editOpen = Boolean(anchorEl);

  const preventDefault = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    preventDefault(e);
    setAnchorEl(e.currentTarget);
  };

  const handleFavoriteChange = (isFavorite: boolean): void => {
    if (!isFavorite) {
      dispatch(addFavorite(apiReport.id));
    } else {
      dispatch(removeFavorite(apiReport.id));
    }
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const isFavorite = (favoritesReports: number[], reportId: number): boolean => {
    return favoritesReports.includes(reportId);
  };

  const [hasRoles, setHasRoles] = useState(reportRoles.length > 0);
  const [canAddUsers, setCanAddUsers] = useState(users.length > 0);

  useEffect(() => {
    setHasRoles(reportRoles.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiReport]);

  useEffect(() => {
    setCanAddUsers(users.length > 0);
  }, [users]);

  const { t } = useTranslation();

  return (
    /***************/
    /* Report name */
    /***************/
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {highlight && (
            <WarningAmberOutlinedIcon
              sx={{ color: theme.palette.secondary.main, height: "22px", marginRight: "5px" }}
            />
          )}
          <strong>{apiReport.reportName}</strong>
        </Box>
      </StyledTableCell>
      {/************/}
      {/* Datasets */}
      {/************/}
      <StyledTableCell align="center">
        {apiReport.datasetId ? (
          <Box sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
            {datasets.find((x: Dataset) => x.id === apiReport.datasetId)?.datasetName}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                background: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #7098A7",
                boxShadow: "0px 1px 1px rgba(183, 203, 211, 0.8), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                borderRadius: "8px",
                padding: "5px",
              }}
            >
              <InsertLinkOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </Box>
          </Box>
        )}
      </StyledTableCell>
      {/***************/}
      {/* Report Type */}
      {/***************/}
      <StyledTableCell align="center">
        <Box sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>{apiReport.reportType}</Box>
      </StyledTableCell>
      {/***********/}
      {/* Actions */}
      {/***********/}
      <StyledTableCell align="right">
        <Grid container item justifyContent="flex-end" alignItems="right">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                padding: "5px 20px 5px 20px",
              }}
            >
              <IconButton
                sx={{
                  color: theme.palette.secondary.main,
                  height: "22px",
                  "&:hover": {
                    background: "none",
                  },
                }}
                onClick={() => handleFavoriteChange(isFavorite(favorite, apiReport.id))}
              >
                {isFavorite(favorite, apiReport.id) ? (
                  <StarFilledIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
                ) : (
                  <StarIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
                )}
              </IconButton>
              {hasRoles ? (
                <IconButton
                  sx={{
                    color: theme.palette.secondary.main,
                    height: "22px",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={() => navigate("/reports/" + apiReport.id)}
                >
                  <VisibilityOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: theme.custom?.disabled?.main,
                    height: "22px",
                    "&:hover": {
                      background: "none",
                    },
                    cursor: "default",
                  }}
                >
                  <TotalTooltip title={t("see_reports.tooltip_not_alowed_to_see")}>
                    <VisibilityOutlinedIcon sx={{ height: "22px" }} />
                  </TotalTooltip>
                </IconButton>
              )}
            </Box>

            {canAddUsers && (
              <>
                <TableDivider />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    padding: "5px 5px 5px 20px",
                  }}
                >
                  <IconButton
                    sx={{
                      color: theme.palette.secondary.main,
                      height: "22px",
                      "&:hover": {
                        background: "none",
                      },
                    }}
                    onClick={(e) => {
                      handleMenuClick(e);
                    }}
                  >
                    <MoreHorizIcon sx={{ color: theme.palette.secondary.main, cursor: "pointer" }} />
                  </IconButton>
                  <Menu
                    open={editOpen}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    sx={{
                      "& .MuiPaper-root": {
                        borderRadius: "8px",
                        border: 1,
                        borderColor: theme.custom?.lineColor?.main,
                      },
                    }}
                    MenuListProps={{ sx: { py: 0 } }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
                      <ReportShareUsersModal report={apiReport} onClose={handleMenuClose} />
                    </MenuItem>
                    <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
                      <ReportUsersListMenuModal report={apiReport} onClose={handleMenuClose} />
                    </MenuItem>
                    <MenuItem sx={{ py: "12px" }}>
                      <ReportManageSubscriptionsModal report={apiReport} onClose={handleMenuClose} />
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TotalTableRow;
