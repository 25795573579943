import {
  authenticateUser,
  createUser,
  deleteUser,
  existInCustomerPortal,
  listUsers,
  rejectUser,
  updateUser,
  validateUser,
  exportUsers,
} from "@adapters/store/users/thunk";
import { RootState, ErrorState } from "@core/store/store";
import { User } from "@domain/entities/Users";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { httpClient } from "@adapters/api/HttpClient";
import { errorHandler } from "@core/utils/ErrorHandler";
import { toaster } from "@core/Toaster";
import { message, sortAsc } from "@core/utils/StringTools";
import { DecodedToken, TokenManager } from "@adapters/store/users/TokenManager";
import i18n from "@core/I18n";
dayjs.extend(utc);

const { t } = i18n;

const subject = "The user";

interface AuthUser {
  user: DecodedToken | null;
  token: string | null;
  existInCustomerPortal: boolean;
}
// Define a type for the slice state
interface UsersState {
  list: User[];
  pending: boolean;
  redirection: string | null;
  error: ErrorState | null;
  authUser: AuthUser;
}

// Define the initial state using that type
const initialState: UsersState = {
  list: [],
  pending: false,
  redirection: null,
  error: null,
  authUser: {
    token: TokenManager.getToken(),
    user: TokenManager.getUser(),
    existInCustomerPortal: false,
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsersError: (state: UsersState) => {
      state.error = null;
    },
    setAuthUser: (_state: UsersState, action) => {
      TokenManager.setToken(action.payload);
    },
    refreshUser: (state: UsersState) => {
      state.authUser.token = TokenManager.getToken();
      state.authUser.user = TokenManager.getUser();
      httpClient.updateAuthorization();
    },
    deleteAuthUser: () => {
      TokenManager.delete();
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST USERS
    builder.addCase(listUsers.fulfilled, (state, action) => {
      state.list = action.payload
        .map((user) => {
          return {
            ...user,
            firstName: user.profile.firstName,
            lastName: user.profile.lastName,
            organisation: user.profile.organisation,
            department: user.profile.department,
            scopesLength: user.scopes.length,
            reportsLength: user.reports.length,
            groupsLength: user.groups.length,
          } as User;
        })
        .sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listUsers.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listUsers");
      state.pending = false;
    });
    builder.addCase(listUsers.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE USER
    builder.addCase(createUser.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list = state.list.sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createUser");
      state.pending = false;
    });
    builder.addCase(createUser.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE USER
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.list = state.list.filter((user) => user.id !== action.payload);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteUser");
      state.pending = false;
    });
    builder.addCase(deleteUser.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE USER
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const list = state.list.filter((r) => r.id !== action.meta.arg.id);
      list.push(action.payload);
      toaster.success(message.success.updated(subject));
      state.list = list.sort((a, b) => sortAsc(`${a.lastName}${a.firstName}`, `${b.lastName}${b.firstName}`));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateUser");
      state.pending = false;
    });
    builder.addCase(updateUser.pending, (state, _action) => {
      state.pending = true;
    });
    // VALIDATE USER
    builder.addCase(validateUser.fulfilled, (state, action) => {
      toaster.success("Account successfully actived");
      state.redirection = action.payload.redirection;
      state.error = null;
      state.pending = false;
    });
    builder.addCase(validateUser.rejected, (state, action) => {
      state.redirection = null;
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "validateUser");
      state.pending = false;
    });
    builder.addCase(validateUser.pending, (state, _action) => {
      state.pending = true;
    });
    // REJECT USER
    builder.addCase(rejectUser.fulfilled, (state, action) => {
      toaster.info("Account successfully deleted");
      state.redirection = action.payload.redirection;
      state.error = null;
      state.pending = false;
    });
    builder.addCase(rejectUser.rejected, (state, action) => {
      state.redirection = null;
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "rejectUser");
      state.pending = false;
    });
    builder.addCase(rejectUser.pending, (state, _action) => {
      state.pending = true;
    });
    // EXPORT USER
    builder.addCase(exportUsers.fulfilled, (state, { payload }) => {
      fetch(payload.url)
        .then((response) => {
          // TODO: Add translation file and test on dev ;)
          if (/20\d/.test(response.status.toString())) {
            return response.blob().then((b) => {
              const anchor = document.createElement("a");
              anchor.download = payload.fileName || "export.pdf";
              anchor.href = URL.createObjectURL(b);
              anchor.click();
            });
          } else {
            console.error("Issue with the url provided by the backend response");
            throw new Error("Network response was not ok");
          }
        })
        .catch(() => {
          toaster.error(t("error.cannot_download_users_export"));
        });
      state.error = null;
      state.pending = false;
    });
    builder.addCase(exportUsers.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "exportUsers");
      state.pending = false;
    });
    builder.addCase(exportUsers.pending, (state, _action) => {
      state.pending = true;
    });
    // AUTHENTICATE USER
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      TokenManager.setToken(action.payload.token);
      const decodedToken = TokenManager.getUser();
      if (decodedToken) {
        state.authUser.token = action.payload.token;
        state.authUser.user = decodedToken;
        httpClient.updateAuthorization();
      }
      state.error = null;
      state.pending = false;
    });
    builder.addCase(authenticateUser.rejected, (state, action) => {
      state.authUser.token = null;
      state.authUser.user = null;
      TokenManager.delete();
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "authenticateUser");
      state.pending = false;
    });
    builder.addCase(authenticateUser.pending, (state, _action) => {
      state.pending = true;
    });
    builder.addCase(existInCustomerPortal.fulfilled, (state, action) => {
      state.pending = false;
      state.authUser.existInCustomerPortal = action.payload;
    });
    builder.addCase(existInCustomerPortal.rejected, (state, _action) => {
      state.pending = false;
      state.authUser.existInCustomerPortal = false;
    });
    builder.addCase(existInCustomerPortal.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
// export const { scopeAlreadyExists } = scopesSlice.actions;
export const { clearUsersError, setAuthUser, deleteAuthUser, refreshUser } = usersSlice.actions;

// Define selectors to be used in UI here
export const getUsersCount = (state: RootState): number => state.users.list.length;
export const getUsers = (state: RootState): User[] => state.users.list;
export const getUsersError = (state: RootState): ErrorState | null => state.users.error;
export const getUsersIsPending = (state: RootState): boolean => state.users.pending;
export const getUsersRedirection = (state: RootState): string | null => state.users.redirection;
export const getAuthUserToken = (state: RootState): string | null => state.users.authUser.token;
export const getAuthUser = (state: RootState): DecodedToken | null => state.users.authUser.user;
export const getIsAuthenticated = (state: RootState): boolean => {
  return state.users.authUser.token !== null && state.users.authUser.user;
};
export const getExistInCustomerPortal = (state: RootState): boolean => state.users.authUser.existInCustomerPortal;
