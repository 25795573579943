import ReactDOM from "react-dom/client";
import reportWebVitals from "src/reportWebVitals";
import { store } from "@core/store/store";

import "@assets/style/index.css";
import "react-toastify/dist/ReactToastify.min.css";
import "animate.css";
import "@assets/style/toaster.css";
import { refreshUser } from "@adapters/store/users/slice";
import "@core/I18n";
import AppContainer from "@core/AppContainer";

const storageFunction = (): void => {
  // Refresh the Token in store when storage change
  store.dispatch(refreshUser());
};

window.onstorage = storageFunction;
window.addEventListener("storage", storageFunction);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<AppContainer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
