import { User } from "@domain/entities/Users";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Chip,
  useTheme,
} from "@mui/material";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import UserRoleTag from "@presentation/components/UserRoleTag";
import { Link } from "react-router-dom";
import { ColumnChecked, ScopeColumn } from "@presentation/utils/UserUtils";
import { useTranslation } from "react-i18next";

interface SummaryGroupProps {
  users: User[];
  scopes: ScopeColumn[];
  reports: ColumnChecked[];
}

const SummaryGroup = (props: SummaryGroupProps): JSX.Element => {
  const theme = useTheme();
  const { users, scopes, reports } = props;

  const { t } = useTranslation();

  const reportsLength = [...new Map(reports.filter(Boolean).map((x) => [x.reportId, x])).values()].length;
  const scopesLength = [...new Map(scopes.filter(Boolean).map((x) => [x.scopeId, x])).values()].length;

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 1, py: "15px", justifyContent: "space-between", gap: "10%" }}>
        <Box
          sx={{
            minWidth: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>{t("group_create_update.summary.users_description")}</Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {t("group_create_update.summary.user_count", { count: users.length })}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", padding: "15px", gap: "20px" }}>
            {users.map((x: User) => {
              return (
                <Typography key={x.id} variant="body2">
                  {x.profile.lastName + " " + x.profile.firstName}
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: "15px" }}>
        <Box
          sx={{
            minWidth: "30%",
            overflow: "hidden",
            borderRadius: "16px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.custom?.lineColor?.main,
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              background: theme.custom?.backgroundColor?.title,
              borderRadius: "16px 16px 0 0",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.custom?.lineColor?.main,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "16px" }}>
              {t("group_create_update.summary.scopes_and_reports_included")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  pr: 2,
                  borderRightWidth: "1px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.custom?.lineColor?.main,
                }}
              >
                {t("group_create_update.summary.scope_count", { count: scopesLength })}
              </Typography>
              <Typography sx={{ fontSize: "16px", pl: 2 }}>
                {t("group_create_update.summary.report_count", { count: reportsLength })}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {scopes.map((scope, idx) => {
              const nbReports = [
                ...new Map(
                  reports
                    .filter((x) => x.scopeId === scope.scopeId)
                    .filter(Boolean)
                    .map((x) => [x.reportId, x])
                ).values(),
              ].length;
              return (
                <TableContainer
                  key={scope.scopeId + "-" + idx}
                  sx={{
                    width: "48%",
                    my: "15px",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.custom?.lineColor?.main,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          background: theme.custom?.backgroundColor?.title,
                          borderRadius: "8px 8px 0 0",
                        }}
                      >
                        <TableCell sx={{ pr: 0 }} align="left">
                          <Link
                            key={`group_scope_${scope.scopeId}`}
                            to={`/scopes/${scope.scopeId}`}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Typography
                              variant="summaryTableHead"
                              sx={{
                                display: "inline-flex",
                                alignContent: "center",
                              }}
                            >
                              {scope.scopeName}
                              <CallMadeOutlinedIcon
                                sx={{
                                  color: theme.palette.secondary.main,
                                  height: "14px",
                                  translate: "-2px 5px",
                                }}
                              />
                            </Typography>
                          </Link>
                          {scope.scopePermissionId && scope.scopePermission && (
                            <UserRoleTag
                              role={scope.scopePermissionId}
                              user={scope.scopePermission}
                              sx={{ font: theme.typography.summaryChip, height: "25px" }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: "14px" }}>
                            <Chip
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "16px",
                                color: "white",
                                height: "18px",
                              }}
                              label={nbReports}
                              component="span"
                            />{" "}
                            {t("group_create_update.summary.report_selected_count", { count: nbReports })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports
                        .filter((report: ColumnChecked, _idxa: number) => report.scopeId === scope.scopeId)
                        .map((report: ColumnChecked, idxa: number) => {
                          return (
                            <TableRow key={idxa}>
                              <TableCell colSpan={3}>
                                <Link
                                  key={`group_report_${report.reportId}`}
                                  to={`/reports/${report.reportId}`}
                                  style={{ textDecoration: "none" }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      display: "inline-flex",
                                      alignContent: "center",
                                    }}
                                  >
                                    {report.reportName}
                                    <CallMadeOutlinedIcon
                                      sx={{
                                        color: theme.palette.secondary.main,
                                        height: "14px",
                                        translate: "-2px 5px",
                                      }}
                                    />
                                  </Typography>
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryGroup;
