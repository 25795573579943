import { createSlice } from "@reduxjs/toolkit";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { Subscription } from "@domain/entities/Subscriptions";
import {
  listSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  updateReportLinkedSubscriptions,
} from "@adapters/store/subscriptions/thunk";
import { toaster } from "@core/Toaster";
import { message, sortAsc } from "@core/utils/StringTools";

// Define a type for the slice state
interface SubscriptionsState {
  list: Subscription[];
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: SubscriptionsState = {
  list: [],
  pending: false,
  error: null,
};

const subject = "The subscription";

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    clearSubscriptionsError: (state: SubscriptionsState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST SUBSCRIPTIONS
    builder.addCase(listSubscriptions.fulfilled, (state, action) => {
      state.list = action.payload;
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listSubscriptions.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listSubscriptions");
      state.pending = false;
    });
    builder.addCase(listSubscriptions.pending, (state, _action) => {
      state.pending = true;
    });
    // CREATE SUBSCRIPTION
    builder.addCase(createSubscription.fulfilled, (state, action) => {
      toaster.success(message.success.created(subject));
      state.list.push(action.payload);
      state.list = state.list.sort((a, b) => sortAsc(a.subscriptionName, b.subscriptionName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(createSubscription.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "createSubscription");
    });
    builder.addCase(createSubscription.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE SUBSCRIPTION
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      toaster.success(message.success.updated(subject));
      const list = state.list.filter((r) => r.id !== action.meta.arg.id);
      list.push(action.payload);
      state.list = list.sort((a, b) => sortAsc(a.subscriptionName, b.subscriptionName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateSubscription");
    });
    builder.addCase(updateSubscription.pending, (state, _action) => {
      state.pending = true;
    });
    // DELETE SUBSCRIPTION
    builder.addCase(deleteSubscription.fulfilled, (state, action) => {
      toaster.success(message.success.deleted(subject));
      state.list = state.list.filter((r) => r.id !== action.meta.arg);
      state.error = null;
      state.pending = false;
    });
    builder.addCase(deleteSubscription.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "deleteSubscription");
    });
    builder.addCase(deleteSubscription.pending, (state, _action) => {
      state.pending = true;
    });
    // UPDATE REPORT LINKED SUBSCRIPTIONS
    builder.addCase(updateReportLinkedSubscriptions.fulfilled, (state, _action) => {
      toaster.success("Subscriptions have been successfully updated");
      state.error = null;
      state.pending = false;
    });
    builder.addCase(updateReportLinkedSubscriptions.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "updateReportLinkedSubscriptions");
    });
    builder.addCase(updateReportLinkedSubscriptions.pending, (state, _action) => {
      state.pending = true;
    });
  },
});

// Define actions to be used in UI here
export const { clearSubscriptionsError } = subscriptionsSlice.actions;

// Define selectors to be used in UI here
export const getSubscriptions = (state: RootState): Subscription[] => state.subscriptions.list;
export const getSubscriptionsError = (state: RootState): ErrorState | null => state.subscriptions.error;
export const getSubscriptionsIsPending = (state: RootState): boolean => state.subscriptions.pending;
