import { getAuthUserToken, getUsersError, getUsersIsPending } from "@adapters/store/users/slice";
import { authenticateUser } from "@adapters/store/users/thunk";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";
import TotalButton from "@presentation/components/TotalButton";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, Link } from "react-router-dom";

const AuthenticationCallback = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string | null>(null);
  const pending = useAppSelector(getUsersIsPending);
  const token = useAppSelector(getAuthUserToken);
  const error = useAppSelector(getUsersError);

  useEffect(() => {
    if (code !== null) {
      dispatch(authenticateUser(code));
    }
  }, [dispatch, code]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cde = queryParams.get("code");
    setCode(cde);
  }, [location, setCode]);

  useEffect(() => {
    if (!pending && token !== null) {
      navigate("/");
    }
  }, [dispatch, navigate, pending, token]);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          width: "425px",
          borderRadius: "16px",
          mt: "20px",
        }}
        elevation={10}
      >
        <Box sx={{ mx: "40px", py: "40px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "40px" }}>
            <TotalIcon />
          </Box>
          {pending && !error && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", mb: "16px", gap: "8px" }}>
                <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                  {t("authentication.callback.wait_verification_message")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "8px" }}>
                <CircularProgress size={20} />
              </Box>
            </>
          )}
          {!pending && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", mb: "16px", gap: "8px" }}>
                {!error ? (
                  <>
                    {!token ? (
                      <>
                        <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                          {t("authentication.callback.logout_success")}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                          {t("authentication.callback.authentication_success")}
                        </Typography>
                        <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                          {t("authentication.callback.wait_redirection_message")}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "8px" }}>
                          <CircularProgress size={20} />
                        </Box>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: "24px",
                        columnGap: "16px",
                      }}
                    >
                      <Link
                        key="home"
                        to="/"
                        style={{ display: "inline-flex", alignItems: "center", textDecoration: "none" }}
                      >
                        <TotalButton
                          id="back-to-kiosk-button"
                          text={t("authentication.callback.back_to_kiosk")}
                          icon="arrowLeft"
                          height={12}
                          iconColor="white"
                          disabled={false}
                        ></TotalButton>
                      </Link>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                      {t("authentication.callback.authentication_failed")}
                    </Typography>
                    <Typography align="center" variant="menu1" sx={{ mb: "8px" }}>
                      {t("authentication.callback.contact_administrator_at")}{" "}
                      <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} style={{ textDecoration: "none" }}>
                        {process.env.REACT_APP_CONTACT_EMAIL}
                      </a>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: "24px",
                        columnGap: "16px",
                      }}
                    >
                      <a
                        href={`${process.env.REACT_APP_CGN_LOGOUT_URL}&client_id=${process.env.REACT_APP_CGN_CLIENT_ID}&scope=${process.env.REACT_APP_CGN_SCOPE}&redirect_uri=${process.env.REACT_APP_CGN_REDIRECT_URI}`}
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <TotalButton
                          id="retry-connection-button"
                          text={t("authentication.callback.retry")}
                          icon="logOut"
                          height={22}
                          disabled={false}
                        ></TotalButton>
                      </a>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default AuthenticationCallback;
