import { useState } from "react";

const STORAGE_KEY = "storedRowsPerPage";

const useStoredRowsPerPage = (initialRowsPerPage: number): [number, (rowsPerPage: number) => void] => {
  const getInitialRowsPerPage = (): number => {
    const storedRowsPerPage = sessionStorage.getItem(STORAGE_KEY);
    try {
      return storedRowsPerPage ? parseInt(storedRowsPerPage) : initialRowsPerPage;
    } catch (error) {
      console.error(`Error parsing storedRowsPerPage:`, error);
      return initialRowsPerPage;
    }
  };

  const [storedRowsPerPage, setStoredRowsPerPage] = useState<number>(getInitialRowsPerPage);

  const updateStoredRowsPerPage = (newRowsPerPage: number): void => {
    setStoredRowsPerPage(newRowsPerPage);
    sessionStorage.setItem(STORAGE_KEY, String(newRowsPerPage));
  };

  return [storedRowsPerPage, updateStoredRowsPerPage];
};

export default useStoredRowsPerPage;
