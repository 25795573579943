import { Autocomplete, styled, useTheme } from "@mui/material";

export type AutocompleteOptionType = {
  title: string;
  onClick?: () => void;
};

export const InputAutocomplete = styled(
  Autocomplete<AutocompleteOptionType, boolean | undefined, boolean | undefined, boolean | undefined>
)(() => {
  const theme = useTheme();
  return {
    ".MuiFormControl-root": {
      border: theme.custom.select.border.default,
      "& .MuiAutocomplete-popupIndicator": {
        color: theme?.palette?.secondary?.main,
        transform: "none",
      },
      "& fieldset": {
        border: 0,
      },
      "& input": {
        padding: "0 !important",
      },
      height: "32px",
      borderRadius: "8px",
    },
  };
});

export default InputAutocomplete;
