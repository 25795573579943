import { Box, Card, Typography } from "@mui/material";
import TotalIcon from "@presentation/components/Icons/TotalIcon";
import { useTranslation } from "react-i18next";

const Rejected = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          width: "425px",
          borderRadius: "16px",
          mt: "20px",
        }}
        elevation={10}
      >
        <Box sx={{ mx: "40px", py: "40px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "60px" }}>
            <TotalIcon />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="menu1">{t("rejected_view.access_rejected")}</Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Rejected;
