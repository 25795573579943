import TotalIcon from "@presentation/components/Icons/TotalIcon";
import NavbarButton from "@presentation/components/NavbarButton";
import TotalButton from "@presentation/components/TotalButton";
import UserMenu from "@presentation/components/UserMenu";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getAuthUser, getExistInCustomerPortal } from "@adapters/store/users/slice";
import { isAdmin, isUN1, isUN1B } from "@core/right/Right";
import ScopeIcon from "@presentation/components/Icons/ScopeIcon";
import GroupIcon from "@presentation/components/Icons/GroupIcon";
import { getScheduledIsPending, getScheduledStatus } from "@adapters/store/scheduled/slice";
import { refreshAll } from "@adapters/store/scheduled/thunk";
import { toaster } from "@core/Toaster";
import { existInCustomerPortal } from "@adapters/store/users/thunk";
import TotalButtonSecondary from "@presentation/components/TotalButtonSecondary";
import SwitchIcon from "@presentation/components/Icons/SwitchIcon";
import SwitchModal from "@presentation/components/navbar/SwitchModal";
import { useTranslation } from "react-i18next";
import MaintenanceIcon from "@presentation/components/Icons/MaintenanceIcon";
import ReportIcon from "./Icons/ReportIcon";
import SubscriptionIcon from "./Icons/SubscriptionIcon";

const Navbar = (): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [buttonsList] = useState<{ label: string; route: string }[]>([
    { label: t("navbar.datasets"), route: "/datasets" },
    { label: t("navbar.datasets"), route: "/roles" },
    { label: t("navbar.reports"), route: "/reports" },
    { label: t("navbar.reports"), route: "/subscriptions" },
    { label: t("navbar.managment"), route: "/management" },
    { label: t("navbar.managment"), route: "/users" },
    { label: t("navbar.managment"), route: "/scopes" },
    { label: t("navbar.managment"), route: "/groups" },
  ]);

  const [createButtonList] = useState<
    {
      id: string;
      label: string;
      route: string;
      disabled: boolean;
      redirection: string;
      icon: string;
      size?: number;
      color?: string;
    }[]
  >([
    {
      id: "create-new-maintenance-button",
      label: t("navbar.create_button.maintenance"),
      route: "/maintenances",
      disabled: false,
      redirection: "/maintenances/create",
      icon: "add",
      size: 14,
      color: "white",
    },
    {
      id: "create-new-user-button",
      label: t("navbar.create_button.user"),
      route: "/users",
      disabled: false,
      redirection: "/users/create",
      icon: "addUser",
      size: 18,
      color: "white",
    },
    {
      id: "create-new-role-button",
      label: t("navbar.create_button.role"),
      route: "/roles",
      disabled: false,
      redirection: "/roles/create",
      icon: "add",
      size: 14,
      color: "white",
    },
    {
      id: "create-new-group-button",
      label: t("navbar.create_button.group"),
      route: "/groups",
      disabled: false,
      redirection: "/groups/create",
      icon: "add",
      size: 14,
      color: "white",
    },
    {
      id: "create-new-scope-button",
      label: t("navbar.create_button.scope"),
      route: "/scopes",
      disabled: false,
      redirection: "/scopes/create",
      icon: "add",
      size: 14,
      color: "white",
    },
    {
      id: "create-new-subscription-button",
      label: t("navbar.create_button.subscription"),
      route: "/subscriptions",
      disabled: false,
      redirection: "/subscriptions/create",
      icon: "add",
      size: 14,
      color: "white",
    },
    {
      id: "create-new-user-button",
      label: t("navbar.create_button.user"),
      route: "/users/create",
      disabled: true,
      redirection: "/users/create",
      icon: "addUser",
      size: 18,
      color: "#FFF",
      // color: "#00000042",
    },
    {
      id: "create-new-role-button",
      label: t("navbar.create_button.role"),
      route: "/roles/create",
      disabled: true,
      redirection: "/roles/create",
      icon: "add",
      size: 14,
      color: "#00000042",
    },
    {
      id: "create-new-group-button",
      label: t("navbar.create_button.group"),
      route: "/groups/create",
      disabled: true,
      redirection: "/groups/create",
      icon: "add",
      size: 14,
      color: "#00000042",
    },
    {
      id: "create-new-scope-button",
      label: t("navbar.create_button.scope"),
      route: "/scopes/create",
      disabled: true,
      redirection: "/scopes/create",
      icon: "add",
      size: 14,
      color: "#00000042",
    },
    {
      id: "create-new-subscription-button",
      label: t("navbar.create_button.subscription"),
      route: "/subscriptions/create",
      disabled: true,
      redirection: "/subscriptions/create",
      icon: "add",
      size: 14,
      color: "#00000042",
    },
  ]);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const actualCreationButton = createButtonList?.find(
    (x: { label: string; route: string; disabled: boolean; redirection: string }) => location.pathname === x.route
  );
  const actualPath = buttonsList?.find((x: { label: string; route: string }) => location.pathname.includes(x.route));
  const actualRoute = buttonsList?.find((x: { label: string; route: string }) =>
    actualPath?.label.toLocaleLowerCase().includes(x.route.replace("/", "").trim().toLocaleLowerCase())
  );
  const [selected, setSelected] = useState(actualRoute?.label.trim().toLocaleLowerCase());

  const authUser = useAppSelector(getAuthUser);
  const refreshStatus = useAppSelector(getScheduledStatus);
  const refreshIsPending = useAppSelector(getScheduledIsPending);
  const existInTCSD = useAppSelector(getExistInCustomerPortal);

  const handleRefresh = (): void => {
    if (refreshIsPending || !isAdmin(authUser?.userRight)) return;
    dispatch(refreshAll());
  };

  useEffect(() => {
    if (!isAdmin(authUser?.userRight)) return;
    if (refreshIsPending) {
      toaster.info(t("refresh.started"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIsPending, dispatch]);

  useEffect(() => {
    if (!isAdmin(authUser?.userRight)) return;
    if (refreshStatus >= 200 && refreshStatus < 300) {
      toaster.success(t("refresh.success"));
    } else if (refreshStatus !== 999) {
      toaster.warn(t("refresh.failed", { refreshStatus }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshStatus, dispatch]);

  useEffect(() => {
    if (authUser) {
      dispatch(existInCustomerPortal());
    }
  }, [authUser, dispatch]);

  const [open, setOpen] = useState(false);

  return (
    <Grid
      className="NavbarWrapper"
      container
      flexDirection="row"
      sx={{
        borderBottom: 1,
        borderColor: "#C0CCD8",
        flexWrap: "nowrap",
      }}
    >
      <Grid
        className="Logo"
        item
        container
        flexDirection="row"
        md={2.5}
        lg={1.5}
        justifyContent="center"
        textAlign="center"
      >
        <TotalIcon />
      </Grid>
      <Grid
        className="Menu"
        item
        flex={1}
        md={6.5}
        lg={7.5}
        container
        flexDirection="column"
        sx={{ flexWrap: "nowrap" }}
      >
        <Grid
          className="Title"
          item
          flex={1}
          justifyContent="flex-start"
          alignItems="flex-end"
          textAlign="center"
          sx={{ display: "flex", alignItems: "center", paddingTop: "20px" }}
        >
          <Typography
            variant="body1"
            noWrap={true}
            sx={{ color: theme.palette.secondary.main, fontWeight: "normal", marginLeft: "8px" }}
          >
            {t("navbar.title")}
          </Typography>
          {existInTCSD && (
            <Box sx={{ marginLeft: "12px" }}>
              <TotalButtonSecondary
                text={t("switch.button_title")}
                startIcon={<SwitchIcon sx={{ color: theme.palette.secondary.main }} />}
                onClick={() => setOpen(true)}
              ></TotalButtonSecondary>
            </Box>
          )}
          <SwitchModal open={open} setOpen={setOpen} />
        </Grid>
        <Grid item container flex={1} justifyContent="flex-start" alignItems="flex-end" sx={{ flexWrap: "nowrap" }}>
          {/* Only if UN1 or UN1B */}
          {(isUN1B(authUser?.userRight) || isUN1(authUser?.userRight)) && (
            <NavbarButton
              key="navbar1b"
              id="menu-1b"
              label={t("navbar.datasets")}
              route="/datasets"
              selected={selected}
              onClick={() => setSelected("datasets")}
            />
          )}
          {isAdmin(authUser?.userRight) && (
            <NavbarButton key="navbar1" id="menu-1" label={t("navbar.datasets")} route="/datasets" selected={selected}>
              <Link
                key="dropdown1"
                to="/datasets"
                style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                onClick={() => setSelected("datasets")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <OpenInNewOutlinedIcon fontSize="small" sx={{ color: theme.palette.secondary.main }} />{" "}
                  {t("navbar.datasets")}
                </Box>
              </Link>
              <Link
                id="manage-roles-link"
                key="dropdown2"
                to="/roles"
                style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                onClick={() => setSelected("datasets")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <StyleOutlinedIcon fontSize="small" sx={{ color: theme.palette.secondary.main }} />{" "}
                  {t("navbar.roles")}
                </Box>
              </Link>
            </NavbarButton>
          )}
          <NavbarButton key="navbar2" id="menu-2" label={t("navbar.reports")} route="/reports" selected={selected}>
            <Link
              key="dropdown7"
              to="/reports"
              style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
              onClick={() => setSelected("reports")}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ReportIcon
                  fontSize="small"
                  sx={{ color: theme.palette.secondary.main }}
                  style={{ height: "18px", width: "18px", marginRight: "12px" }}
                />
                {t("navbar.reports")}
              </Box>
            </Link>
            <Link
              key="dropdown8"
              to="/subscriptions"
              style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
              onClick={() => setSelected("reports")}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SubscriptionIcon
                  fontSize="small"
                  sx={{ color: theme.palette.secondary.main }}
                  style={{ height: "18px", width: "18px", marginRight: "12px" }}
                />
                {t("navbar.manage_subscriptions")}
              </Box>
            </Link>
          </NavbarButton>
          {(isAdmin(authUser?.userRight) || isUN1(authUser?.userRight)) && (
            <NavbarButton
              key="navbar3"
              id="menu-3"
              label={t("navbar.managment")}
              route="/management"
              selected={selected}
            >
              <Link
                key="dropdown3"
                to="/users"
                style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                onClick={() => setSelected("management")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccountCircleOutlinedIcon
                    sx={{ color: theme.palette.secondary.main }}
                    style={{ fontSize: "19px", marginRight: "11px" }}
                  />{" "}
                  {t("navbar.manage_users")}
                </Box>
              </Link>
              <Link
                key="dropdown4"
                to="/scopes"
                style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                onClick={() => setSelected("management")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ScopeIcon
                    fontSize="small"
                    sx={{ color: theme.palette.secondary.main }}
                    style={{ height: "18px", width: "18px", marginRight: "12px" }}
                  />{" "}
                  {t("navbar.manage_scopes")}
                </Box>
              </Link>
              <Link
                key="dropdown5"
                to="/groups"
                style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                onClick={() => setSelected("management")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <GroupIcon
                    fontSize="small"
                    sx={{ color: theme.palette.secondary.main }}
                    style={{ height: "18px", width: "18px", marginRight: "12px" }}
                  />{" "}
                  {t("navbar.manage_groups")}
                </Box>
              </Link>
              {isAdmin(authUser?.userRight) && (
                <Link
                  key="dropdown6"
                  to="/maintenances"
                  style={{ textDecoration: "none", color: theme.palette.primary.main, width: "100%" }}
                  onClick={() => setSelected("management")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MaintenanceIcon
                      fontSize="small"
                      sx={{ color: theme.palette.secondary.main }}
                      style={{ height: "18px", width: "18px", marginRight: "12px" }}
                    />{" "}
                    {t("navbar.manage_maintenances")}
                  </Box>
                </Link>
              )}
            </NavbarButton>
          )}
        </Grid>
      </Grid>
      <Grid className="NavbarRight" item container md={3} sx={{ background: "white" }} flexDirection="column">
        <UserMenu user={authUser} className="User" />
        <Grid className="Action" container item flex={1} alignItems="center" justifyContent="flex-end" px={1}>
          {isAdmin(authUser?.userRight) && (
            <Box sx={{ m: 0, p: 0, mr: 1 }}>
              <TotalButton
                id="run-manual-synchro-button"
                text={t("navbar.manual_synchro")}
                icon="sync"
                height={14}
                buttonColor={theme.palette.secondary.main}
                onClick={handleRefresh}
                disabled={!!refreshIsPending}
                primary
              />
            </Box>
          )}
          {actualCreationButton && (
            <TotalButton
              id={actualCreationButton.id}
              text={actualCreationButton.label}
              icon={actualCreationButton.icon}
              iconColor={actualCreationButton.color}
              height={actualCreationButton.size ?? 22}
              link={actualCreationButton.redirection}
              disabled={actualCreationButton.disabled}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navbar;
