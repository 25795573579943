import styled from "@emotion/styled";
import { Box, ToggleButton, ToggleButtonGroup, ToggleButtonProps, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";

interface Button {
  value: string;
  label: string;
  selected?: boolean;
}

interface IToggleButtonProps extends ToggleButtonProps {
  label?: string;
  buttons: Button[];
  error?: boolean;
  helpText?: string;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => {
  const theme = useTheme();
  return {
    "& .MuiToggleButtonGroup-grouped": {
      border: 0,
      boxSizing: "border-box",
      "&:hover": {
        color: theme.custom?.tertiary?.main,
        borderColor: theme.custom?.tertiary?.main,
      },
      "&.Mui-selected": {
        color: theme.custom?.button?.textColor,
        backgroundColor: theme.custom?.tertiary?.main,
        "&.Mui-disabled": {
          color: theme.palette.primary.main,
          backgroundColor: theme.custom?.disabled?.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
  };
});

const TotalToggle = (props: IToggleButtonProps): JSX.Element => {
  const theme = useTheme();
  const { label, buttons, disabled, id, error, helpText, value, onChange } = props;

  return (
    <Box>
      {label && (
        <Typography
          variant="body2"
          sx={{
            mb: "8px",
            cursor: "default",
            ...(error && { color: theme.palette.secondary.main, fontWeight: 600 }),
            ...(disabled && { color: theme.custom?.disabled, fontStyle: "italic", cursor: "unset" }),
          }}
        >
          {label}
        </Typography>
      )}
      <StyledToggleButtonGroup
        id={id}
        value={value || "false"}
        orientation="horizontal"
        color="primary"
        onChange={onChange}
        exclusive
        disabled={disabled}
        sx={{
          mb: "8px",
          cursor: "default",
          backgroundColor: theme.custom?.button?.background,
          borderRadius: theme.custom?.select?.border?.radius,
          border: !error ? theme.custom?.select?.border?.size : 2,
          borderColor: !error ? theme.custom?.select?.border?.color : theme.palette.secondary.main,
          height: "30px",
          ...(error && { color: theme.palette.secondary.main, fontWeight: 600 }),
          ...(disabled && { color: theme.custom?.disabled, fontStyle: "italic", cursor: "unset" }),
        }}
      >
        {buttons.map((button) => {
          return (
            <ToggleButton key={button.value} value={button.value} selected={button.selected} disabled={disabled}>
              {button.label}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
      {helpText && (
        <Box sx={{ display: "inline-flex", verticalAlign: "middle" }}>
          <TotalTooltip title={helpText}>
            <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
          </TotalTooltip>
        </Box>
      )}
    </Box>
  );
};

export default TotalToggle;
