import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import DeleteModal from "@presentation/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import { deleteGroup } from "@adapters/store/groups/thunk";
import { getGroupsIsPending } from "@adapters/store/groups/slice";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import { Group } from "@domain/entities/Groups";
import TableDivider from "@presentation/components/table/TableDivider";

interface ITotalGroupsRow extends TableRowProps {
  row: Group;
  highlight: boolean;
}

const TotalTableRow = ({ row, highlight }: ITotalGroupsRow): React.JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isPending = useAppSelector(getGroupsIsPending);

  const navigate = useNavigate();

  return (
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {highlight && (
            <WarningAmberOutlinedIcon
              sx={{ color: theme.palette.secondary.main, height: "22px", marginRight: "5px" }}
            />
          )}
          <b>{row.groupName}</b>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.users.length}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.scopesLength}</b>
      </StyledTableCell>
      <StyledTableCell align="center">
        <b>{row.reportsLength}</b>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 20px 5px 20px",
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.secondary.main,
                height: "22px",
                "&:hover": {
                  background: "none",
                },
              }}
              onClick={() => navigate("/groups/" + row.id)}
            >
              <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </IconButton>
          </Box>
          <TableDivider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 5px 5px 20px",
            }}
          >
            <DeleteModal
              type="group"
              handleClick={async () => {
                await dispatch(deleteGroup(row.id));
              }}
              pending={isPending}
            />
          </Box>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TotalTableRow;
