import { ReactComponent as Scope } from "@assets/icons/scope_icon.svg";

const ScopeIcon = (props: any): JSX.Element => {
  return (
    <Scope
      fill={props?.sx?.color || props?.sx?.fill}
      height={props?.sx?.height || "16px"}
      width={props?.sx?.width || props?.sx?.height || "16px"}
      {...props}
    />
  );
};

export default ScopeIcon;
