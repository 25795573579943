import { Tooltip, TooltipProps, Zoom, useTheme } from "@mui/material";
import React from "react";

interface TotalTooltipProps extends TooltipProps {
  warning?: boolean;
  setOpenState?: (actualState: boolean) => void;
}

const TotalTooltip = ({
  children,
  title,
  placement,
  warning = false,
  setOpenState,
  ...props
}: TotalTooltipProps): React.JSX.Element => {
  const theme = useTheme();

  return (
    <Tooltip
      placement={placement ?? "bottom-start"}
      arrow
      TransitionComponent={Zoom}
      onClose={() => {
        if (setOpenState) setOpenState(false);
      }}
      onOpen={() => {
        if (setOpenState) setOpenState(true);
      }}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: warning ? theme.custom.tooltip.border.warning : theme.custom.tooltip.border.default,
            borderRadius: "8px",
            color: theme.palette.primary.main,
            backgroundColor: "white",
            boxShadow: theme.custom.shadow.tooltip,
            fontSize: "14px",
          },
          "& .MuiTooltip-arrow:before": {
            border: warning ? theme.custom.tooltip.border.warning : theme.custom.tooltip.border.default,
            color: warning ? theme.custom.colors.brand.primary[500] : theme.custom.neutral[80],
          },
        },
      }}
      title={title}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default TotalTooltip;
