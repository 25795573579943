import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import TotalButton from "@presentation/components/TotalButton";
import TotalSelect from "@presentation/components/TotalSelect";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getDatasets, getDatasetTables } from "@adapters/store/datasets/slice";
import { getRoles } from "@adapters/store/roles/slice";
import { listRoles } from "@adapters/store/roles/thunk";
import { throttle } from "throttle-debounce";
import { createScope, listScopes } from "@adapters/store/scopes/thunk";
import { clearScopesError, getAdminScopes, getScopesError, getScopesIsPending } from "@adapters/store/scopes/slice";
import { Dataset } from "@domain/entities/Datasets";
import { Role } from "@domain/entities/Roles";
import { Scope } from "@domain/entities/Scopes";
import ScopesCollapsibleRolesTable from "@presentation/components/ScopesCollapsibleRolesTable";
import SummaryScope from "@presentation/components/scopes/SummaryScopeCreateUpdate";
import { useNavigate } from "react-router-dom";
import { ItemList, ParentScopeRolesProps, RoleByDataset } from "@presentation/views/ViewScope.view";
import { listDatasets } from "@adapters/store/datasets/thunk";
import { uniq } from "@core/utils/ArrayTools";
import { useTranslation } from "react-i18next";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";

export interface ColumnChecked {
  datasetId: number;
  datasetName: string;
  roleId: number;
  roleName: string | undefined;
}

const CreateScope = (): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [scopeName, setScopeName] = useState<string>("");
  const [checked, setChecked] = useState<ColumnChecked[]>([]);
  const [scopeType] = useState<string>("parent");
  const [parentScope, setParentScope] = useState<number>(0);
  const [provider] = useState<number>(1);
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);
  const [itemsList, setItemsList] = useState<ItemList[]>([]);
  const [parentRolesByDatasets, setParentRolesByDatasets] = useState<RoleByDataset[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const error = useAppSelector(getScopesError);
  const scopesList = useAppSelector(getAdminScopes);
  const datasets = useAppSelector(getDatasets);
  const isPending = useAppSelector(getScopesIsPending);
  const datasetTables = useAppSelector(getDatasetTables);
  const rolesList = useAppSelector(getRoles);

  const path = "/scopes";

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_scopes")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("scope_create_update.create_title")}
    </Link>,
  ];

  const handleNameChange = (value: string): void => {
    setScopeName(value);
  };

  const toggleCheckColumn = (datasetId: number, roleId: number): void => {
    const dataset = datasets.find((d) => d.id === datasetId);
    if (dataset) {
      let newArr;
      if (checked.some((check) => check.datasetId === datasetId && check.roleId === roleId)) {
        newArr = checked.filter((check) => check.roleId !== roleId);
      } else {
        newArr = [...checked];
        newArr.push({
          datasetId: dataset.id,
          datasetName: dataset.datasetName,
          roleId: roleId,
          roleName: rolesList.find((c) => c.id === roleId)?.roleName,
        });
      }
      setChecked(newArr);
    }
  };

  const toggleCheckAllColumns = (datasetId: number): void => {
    const dataset = datasets.find((d) => d.id === datasetId);
    if (dataset) {
      let newArr;
      if (checked.some((check) => check.datasetId === dataset.id)) {
        newArr = checked.filter((check) => check.datasetId !== datasetId);
      } else {
        newArr = [...checked];
        const roles = rolesList.filter((x: Role) => x.dataset.id === datasetId);
        roles.forEach((x) => {
          newArr.push({
            datasetId: datasetId,
            datasetName: x.datasetName,
            roleId: x.id,
            roleName: x.roleName,
          });
        });
      }
      setChecked(newArr);
    }
  };

  const checkAlreadyExists = (value: string): boolean => {
    return scopesList.some((x: Scope) => x.scopeName === value.trim());
  };

  useEffect(() => {
    setChecked([]);
  }, [datasetTables]);

  useEffect(() => {
    dispatch(listDatasets());
    dispatch(listRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchThrottled = useCallback(
    throttle(1500, () => {
      if (scopeName !== null && scopeType === "child") {
        dispatch(listScopes());
      }
    }),
    [dispatch, scopeName]
  );

  useEffect(() => {
    const itemListTmp: ItemList[] = [];
    itemListTmp.push({ id: -1, optionName: t("common.none") });
    scopesList?.map((x: Scope) => itemListTmp.push({ id: x.id, optionName: x.scopeName }));
    setItemsList(itemListTmp);
    const rb: RoleByDataset[] = [];
    setParentRolesByDatasets(
      findAllParentsRoles(
        rb,
        scopesList?.find((x: Scope) => parentScope === x.id)
      )
    );
    const newRb = [...new Set(rb)];
    setParentRolesByDatasets(newRb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    const rb: RoleByDataset[] = [];
    setParentRolesByDatasets(
      findAllParentsRoles(
        rb,
        scopesList?.find((x: Scope) => parentScope === x.id)
      )
    );
    const newRb = [...new Set(rb)];
    setParentRolesByDatasets(newRb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentScope]);

  useEffect(() => {
    if (scopeName.length < 2) return;
    searchThrottled();
    setAlreadyExists(checkAlreadyExists(scopeName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeName, searchThrottled]);

  const findAllParentsRoles = (rb: RoleByDataset[], pScope: Scope | undefined): any[] => {
    if (pScope) {
      pScope.datasets.forEach((x: RoleByDataset) => {
        const dup = rb.find((y: RoleByDataset) => y.datasetName === x.datasetName);
        if (dup) {
          dup.roles = [...x.roles, ...dup.roles].filter(
            (e, idx, array) => array.findIndex((b) => b.id === e.id) === idx
          );
        } else rb.push({ id: x.id, datasetName: x.datasetName, roles: x.roles });
      });
      if (pScope?.parentScopeId && pScope.parentScopeId > 0)
        return findAllParentsRoles(
          rb,
          scopesList?.find((x: Scope) => pScope.parentScopeId === x.id)
        );
    }
    return rb;
  };

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result =
      !!scopeName &&
      !checkAlreadyExists(scopeName) &&
      scopesList.find((x) => x.id === parentScope)?.scopeName !== scopeName;
    setIsBusy(false);
    return result;
  };

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeName, checked, scopeType, parentScope, provider, itemsList, parentRolesByDatasets]);

  const ParentScopeRoles = (props: ParentScopeRolesProps): JSX.Element => {
    const { pScope } = props;

    return (
      <>
        {pScope?.map((table, idx) => {
          return (
            <TableContainer key={idx} sx={{ width: "49%", my: "15px" }}>
              <Table
                key={idx + "-table"}
                sx={{ border: 1, borderColor: theme.custom?.lineColor?.main, borderRadius: "8px" }}
              >
                <TableHead>
                  <TableRow key={table.id + "-row"} sx={{ background: theme.custom?.backgroundColor?.title }}>
                    <TableCell colSpan={2}>
                      <Typography sx={{ fontSize: "16px" }}>{table.datasetName}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={{ fontSize: "14px" }}>{table.roles.length} roles selected</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={"body-" + idx}>
                  {table.roles.map((col, idxa) => {
                    return (
                      <TableRow key={idx + "-" + idxa}>
                        <TableCell colSpan={2}>
                          <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>{col.roleName}</Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </>
    );
  };

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {!isPending ? (
        <Box sx={{ width: "100%", mt: "70px" }}>
          {error && (
            <Alert severity="error" onClose={() => dispatch(clearScopesError())} sx={{ my: 2 }}>
              {error?.message}
            </Alert>
          )}
          <Grid container item direction="row" justifyContent="space-between" alignItems="end">
            <Grid container item direction="row" md={8} alignItems="flex-end">
              <TextFieldData
                id="scope-name"
                label={t("scope_create_update.scope_name")}
                value={scopeName}
                error={alreadyExists}
                onChange={handleNameChange}
                width="300px"
              />
              <TotalSelect
                label={t("total_select.provider")}
                value={provider}
                items={[{ id: 1, optionName: "PowerBI" }]}
                disabled
                sx={{ minWidth: "150px", ml: "40px" }}
              />
            </Grid>
            <TotalButton
              id="save-new-scope-button"
              text={t("scope_create_update.save_new_scope")}
              icon=""
              height={22}
              disabled={!isValid}
              onClick={async () => {
                await dispatch(
                  createScope({
                    scopeName: scopeName.trim(),
                    parentScopeId: parentScope <= 0 ? 0 : parentScope,
                    vendorId: provider,
                    roles: uniq(checked.map((x) => x.roleId)),
                  })
                );
                dispatch(listScopes());
                if (!error) navigate(path);
              }}
            />
          </Grid>
          <CollapsibleCard title={t("scope_create_update.1_select_parent_scope")} sx={{ mt: "30px" }} isExpanded={true}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mb: parentRolesByDatasets.length > 0 ? "16px" : "0px",
                border: "1px solid #B7CBD3",
                borderRadius: "8px",
                padding: "8px",
                backgroundColor: "#0082FF29",
              }}
            >
              <TotalSelect
                label={t("scope_create_update.retrieve_scopes")}
                value={parentScope}
                items={itemsList}
                sx={{ minWidth: "320px" }}
                onChange={(e: number) => setParentScope(e)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <ParentScopeRoles pScope={parentRolesByDatasets} />
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("scope_create_update.2_assigned_roles")} sx={{ mt: "8px" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", rowGap: "8px" }}>
              {datasets
                .filter((d: Dataset, _idx: number) => {
                  const roles = rolesList.filter((x: Role) => x.datasetName === d.datasetName);
                  return roles.length > 0;
                })
                .map((d: Dataset, idx: number) => {
                  const roles = rolesList.filter((x: Role) => x.datasetName === d.datasetName);
                  return (
                    <ScopesCollapsibleRolesTable
                      key={"dataset-table-" + idx}
                      title={d.datasetName}
                      datasetId={d.id}
                      items={roles}
                      parentRoles={parentRolesByDatasets}
                      checkedItems={checked.filter((c) => c.datasetId === d.id).map((c) => c.roleId)}
                      toggleCheckColumn={toggleCheckColumn}
                      toggleCheckAll={toggleCheckAllColumns}
                    />
                  );
                })}
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title={t("scope_create_update.3_scope_summary")} sx={{ mt: "8px" }}>
            <SummaryScope
              parentScope={scopesList.find((x) => x.id === parentScope)?.scopeName}
              providerType={t("common.powerbi")}
              scopeName={scopeName}
              datasets={datasets}
              parentRoles={parentRolesByDatasets}
              values={checked}
            />
          </CollapsibleCard>
        </Box>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CreateScope;
