import { SvgIcon, SvgIconProps } from "@mui/material";

export const StarIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C12.3806 1 12.7283 1.2161 12.8967 1.55743L15.8969 7.6364L22.6054 8.61121C22.9821 8.66594 23.295 8.92978 23.4127 9.2918C23.5303 9.65381 23.4322 10.0512 23.1596 10.3169L18.3053 15.0487L19.4512 21.7301C19.5156 22.1053 19.3613 22.4845 19.0534 22.7082C18.7455 22.9319 18.3372 22.9614 18.0003 22.7843L12 19.6298L5.99968 22.7843C5.66277 22.9614 5.2545 22.9319 4.94656 22.7082C4.63861 22.4845 4.48439 22.1053 4.54873 21.7301L5.69469 15.0487L0.840347 10.3169C0.567775 10.0512 0.469678 9.65381 0.587303 9.2918C0.704928 8.92978 1.01787 8.66594 1.39456 8.61121L8.1031 7.6364L11.1032 1.55743C11.2717 1.2161 11.6193 1 12 1ZM12 4.25955L9.6639 8.99297C9.51824 9.28812 9.23667 9.49269 8.91096 9.54001L3.68732 10.2991L7.46718 13.9835C7.70287 14.2132 7.81042 14.5442 7.75478 14.8686L6.86247 20.0712L11.5346 17.6149C11.826 17.4617 12.174 17.4617 12.4653 17.6149L17.1375 20.0712L16.2452 14.8686C16.1895 14.5442 16.2971 14.2132 16.5328 13.9835L20.3126 10.2991L15.089 9.54001C14.7633 9.49269 14.4817 9.28812 14.3361 8.99297L12 4.25955Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default StarIcon;
