import { Box, Grid, Typography, InputProps, Menu, MenuItem, useTheme } from "@mui/material";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React, { useState } from "react";
import { deleteAuthUser } from "@adapters/store/users/slice";
import LegalFilesModal from "@presentation/components/LegalFilesModal";
import ProfileButton from "@presentation/components/ProfileButton";
import { useAppDispatch } from "@core/store/hook";
import { DecodedToken } from "@adapters/store/users/TokenManager";
import { useTranslation } from "react-i18next";
import LanguageModal from "@presentation/components/navbar/LanguageModal";
import { inProduction } from "@core/utils/Tools";

interface UserMenuProps extends InputProps {
  user: DecodedToken | null;
  className?: string;
}

const UserMenu = (props: UserMenuProps): JSX.Element => {
  const theme = useTheme();
  const { user, className } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const editOpen = !!anchorEl;
  const dispatch = useAppDispatch();

  return (
    <Grid
      {...(className && { className })}
      container
      item
      flex={1}
      alignItems="center"
      justifyContent="flex-end"
      px={1}
    >
      <Box>
        <ProfileButton
          text={user ? user?.firstName + " " + user?.lastName : ""}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          menuOpen={editOpen}
        />
      </Box>
      <Menu
        open={editOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            border: 1,
            borderColor: theme.custom?.lineColor?.main,
            minWidth: "170px",
            mt: 0.5,
            padding: "2px",
          },
        }}
        MenuListProps={{ sx: { py: 0 } }}
      >
        {/* To enable Language selection in production, remove this condition */}
        {!inProduction() && (
          <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
            <LanguageModal />
          </MenuItem>
        )}
        <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
          <LegalFilesModal />
        </MenuItem>
        <MenuItem sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}>
          <a
            href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
            style={{ textDecoration: "none", color: theme.palette.primary.main }}
          >
            <Box sx={{ display: "flex" }}>
              <ForwardToInboxOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }} />
              <Typography variant="h6">{t("user_menu.contact_support")}</Typography>
            </Box>
          </a>
        </MenuItem>
        <MenuItem
          sx={{ borderBottom: 1, borderColor: theme.custom?.lineColor?.main, py: "12px" }}
          onClick={async (event) => {
            event.stopPropagation();
            await dispatch(deleteAuthUser());
            window.location.replace(
              `${process.env.REACT_APP_CGN_LOGOUT_URL}&client_id=${process.env.REACT_APP_CGN_CLIENT_ID}&scope=${process.env.REACT_APP_CGN_SCOPE}&redirect_uri=${process.env.REACT_APP_CGN_REDIRECT_URI}`
            );
          }}
        >
          <Box sx={{ display: "flex" }}>
            <LogoutOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "18px", mr: 1 }} />
            <Typography variant="h6">{t("user_menu.logout")}</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default UserMenu;
