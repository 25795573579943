import jwtDecode from "jwt-decode";

export interface DecodedToken {
  userId: number;
  exp: number;
  iat: number;
  firstName: string;
  lastName: string;
  email: string;
  userRight: string;
}

export class TokenManager {
  static setToken(newToken: string): void {
    localStorage.setItem("token", newToken);
  }

  static getToken(): string | null {
    return localStorage.getItem("token");
  }

  static getUser(): DecodedToken | null {
    const token = this.getToken();
    try {
      return typeof token === "string" ? jwtDecode(token) : null;
    } catch (e) {
      return null;
    }
  }

  static delete(): void {
    localStorage.removeItem("token");
  }
}
