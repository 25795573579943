import { TableRow, TableCell } from "@mui/material";
import React from "react";

type EmptyRowProps = {
  emptyNumber: number;
  headerNumber?: number;
  dense?: boolean;
  gap?: number;
  denseRowHeight?: number;
  regularRowHeight?: number;
};

/**
 * Generates one or more empty table rows based on the provided number of empty slots.
 * The height of the rows can be adjusted based on the 'dense' flag and the provided row heights.
 * Extra vertical space (gap) can be added between the rows.
 *
 * @param {Object} props The props object for the component.
 * @param {number} props.emptyNumber The number of empty rows to be generated.
 * @param {number} [props.headerNumber=6] The number of header cells to span across in the table row.
 * @param {boolean} [props.dense=false] Determines if the row should have a smaller height (`denseRowHeight`).
 * @param {number} [props.gap=16] The gap (in pixels) to be applied between the empty rows.
 * @param {number} [props.denseRowHeight=33] The height of the row when `dense` is `true`.
 * @param {number} [props.regularRowHeight=60] The height of the row when `dense` is `false`.
 * @returns {React.ReactElement} A React Element representing the empty table rows to be rendered.
 */
const EmptyRows = ({
  emptyNumber,
  headerNumber = 6,
  dense = false,
  gap = 16,
  denseRowHeight = 33,
  regularRowHeight = 60,
}: EmptyRowProps): React.JSX.Element => {
  return emptyNumber > 0 ? (
    <TableRow
      style={{
        height: (dense ? denseRowHeight : regularRowHeight) * emptyNumber + (emptyNumber - 1) * gap,
      }}
    >
      <TableCell colSpan={headerNumber} />
    </TableRow>
  ) : (
    <></>
  );
};

export default EmptyRows;
