import { sortAsc } from "@core/utils/StringTools";

export interface ColumnChecked {
  scopeId: number;
  scopeName: string;
  reportId: number;
  reportName: string | undefined;
}

export interface ScopeColumn {
  scopeId: number;
  scopeName: string;
  scopePermission: string;
  scopePermissionId: number;
}

export const getSimulatedScopesRights = (scopes: ScopeColumn[]): ScopeColumn[] =>
  [...scopes]
    .sort((scopeA, scopeB) => {
      return sortAsc(scopeA.scopeName, scopeB.scopeName) || scopeA.scopePermissionId - scopeB.scopePermissionId;
    })
    .filter(
      (scope, idx, arr) => idx === arr.findIndex((s) => scope.scopeId === s.scopeId) && scope.scopePermissionId !== 0
    );

export const getReportsWithoutDuplicates = (reports: number[]): number[] =>
  reports.filter((v, i, arr) => arr.findIndex((_v) => v === _v) === i);
