import { Box, Typography, DialogContent, IconButton, DialogActions, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CountryIcon from "@presentation/components/Icons/CountryIcon";
import LanguageSelection from "@presentation/components/navbar/LanguageSelection";
import CloseIcon from "@mui/icons-material/Close";
import { TotalModal } from "@presentation/components/TotalModal";

const LanguageModal = (): React.JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { t, i18n } = useTranslation();

  const langList = [
    {
      code: "en",
      label: t("language.list.en"),
    },
    {
      code: "fr",
      label: t("language.list.fr"),
    },
    {
      code: "de",
      label: t("language.list.de"),
    },
  ];

  return (
    <Box>
      <Box
        sx={{ display: "flex", verticalAlign: "center", justifyContent: "flex-start", cursor: "pointer" }}
        onClick={handleOpen}
      >
        <Box sx={{ display: "flex", my: "auto", mr: "10px", borderRadius: "15%", overflow: "hidden" }}>
          <CountryIcon countryCode={i18n.language} />
        </Box>
        <Typography variant="h6">{t("user_menu.language")}</Typography>
      </Box>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ m: "24px", position: "relative" }}>
          <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                alignSelf: "stretch",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  color: theme.palette.secondary.main,
                  padding: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              id="alert-dialog-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Typography variant="h1" sx={{ color: theme.palette.primary.main }}>
                {t("language.language_list_title")}
              </Typography>
              <Typography variant="navButtonSelected" sx={{ color: theme.palette.primary.main }}>
                {t("language.actual_language", { language: t(`language.list.${i18n.language}`) })}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center", my: "40px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "75%",
                gap: "24px",
                flexWrap: "wrap",
              }}
            >
              {langList.map(({ code, label }) => (
                <LanguageSelection key={`modal-${code}`} label={label} code={code} />
              ))}
            </Box>
          </DialogActions>
        </Box>
      </TotalModal>
    </Box>
  );
};

export default LanguageModal;
