import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { deleteRole } from "@adapters/store/roles/thunk";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useNavigate } from "react-router-dom";
import DeleteModal from "@presentation/components/DeleteModal";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { getRolesIsPending } from "@adapters/store/roles/slice";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import { Role } from "@domain/entities/Roles";
import { useTranslation } from "react-i18next";
import TableDivider from "@presentation/components/table/TableDivider";

interface ITotalRolesRow extends TableRowProps {
  row: Role;
  highlight?: boolean;
}

const TotalTableRow = ({ row, highlight }: ITotalRolesRow): JSX.Element => {
  const theme = useTheme();
  const isPending = useAppSelector(getRolesIsPending);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex" }}>
          {highlight && (
            <WarningAmberOutlinedIcon
              sx={{ color: theme.palette.secondary.main, height: "22px", marginRight: "5px" }}
            />
          )}
          <b>{row.roleName}</b>
        </Box>
      </StyledTableCell>
      <StyledTableCell>{row.dataset.datasetName}</StyledTableCell>
      <StyledTableCell>
        {row.scopes.length > 0 ? (
          <Box sx={{ display: "flex" }}>
            <InsertLinkOutlinedIcon sx={{ height: "22px", marginRight: "15px" }} /> <b>{row.scopes.length}</b>
          </Box>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                background: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #7098A7",
                boxShadow: "0px 1px 1px rgba(183, 203, 211, 0.8), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                borderRadius: "8px",
                padding: "5px",
              }}
            >
              <TotalTooltip
                placement="bottom"
                warning
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <WarningAmberOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "11px" }} />
                    {t("datasets_roles.tooltip_warning_not_related_to_any_scope")}
                  </Box>
                }
              >
                <InsertLinkOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
              </TotalTooltip>
            </Box>
          </Box>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <b>{row.filters.length}</b>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "auto",
              marginBottom: "auto",
              padding: "5px 20px 5px 20px",
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.secondary.main,
                height: "22px",
                "&:hover": {
                  background: "none",
                },
              }}
              className="EditButton"
              onClick={() => navigate("/roles/" + row.id)}
            >
              <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </IconButton>
          </Box>
          <TableDivider />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              padding: "5px 5px 5px 20px",
            }}
          >
            <DeleteModal
              type="role"
              handleClick={async () => {
                await dispatch(deleteRole(row.id));
              }}
              pending={isPending}
            />
          </Box>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TotalTableRow;
