import { maintenancesServices } from "@adapters/api/services/MaintenancesServices";
import { MaintenanceResponseMapper } from "@adapters/store/maintenances/mapper";
import { MaintenanceCreateRequest, MaintenanceUpdateRequest } from "@domain/entities/Maintenance";
import { createAsyncThunk } from "@reduxjs/toolkit";
// Define actions with API here
export const listMaintenances = createAsyncThunk("maintenances/list", async (_, { rejectWithValue }) => {
  try {
    const response = await maintenancesServices.list();

    return response.data.map((m) => MaintenanceResponseMapper.toEntity(m));
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteMaintenance = createAsyncThunk("maintenances/delete", async (id: number, { rejectWithValue }) => {
  try {
    const response = await maintenancesServices.delete(id);
    return response.data.response;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createMaintenance = createAsyncThunk(
  "maintenances/create",
  async (maintenance: MaintenanceCreateRequest, { rejectWithValue }) => {
    try {
      const response = await maintenancesServices.create(maintenance);
      return MaintenanceResponseMapper.toEntity(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const actualMaintenance = createAsyncThunk("maintenances/actual", async (_, { rejectWithValue }) => {
  try {
    const response = await maintenancesServices.actual();
    if (response.status === 204) return null;
    return MaintenanceResponseMapper.toEntity(response.data);
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateMaintenance = createAsyncThunk(
  "maintenances/update",
  async (maintenance: MaintenanceUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await maintenancesServices.update(maintenance);
      return MaintenanceResponseMapper.toEntity(response.data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
