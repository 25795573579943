// import { TableRow, TableCell, Box, IconButton, Collapse, Typography, TableRowProps } from "@mui/material";
import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { deleteScope } from "@adapters/store/scopes/thunk";
import { getScopesIsPending } from "@adapters/store/scopes/slice";
import { Scope } from "@domain/entities/Scopes";
import DeleteModal from "@presentation/components/DeleteModal";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import { getAuthUser } from "@adapters/store/users/slice";
import TableDivider from "@presentation/components/table/TableDivider";
// import ArrowDownIcon from "@presentation/components/Icons/ArrowDownIcon";
// import ExpandButton from "@presentation/components/ExpandButton";

interface ITotalScopesRow extends TableRowProps {
  rows: Scope[];
  row: Scope;
  highlight: boolean;
}

const TotalTableRow = ({ rows, row, highlight }: ITotalScopesRow): React.JSX.Element => {
  const theme = useTheme();
  const [expanded] = useState<boolean>(false);
  // const [expanded, setExpanded] = useState<boolean>(false);

  const isPending = useAppSelector(getScopesIsPending);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authUser = useAppSelector(getAuthUser);

  // const handleExpandClick = (): void => {
  //   setExpanded(!expanded);
  // };

  return (
    <>
      <StyledTableRow className="Row" hover sx={{ marginBottom: !expanded ? "16px" : "0px" }}>
        <StyledTableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {highlight && (
              <WarningAmberOutlinedIcon
                sx={{ color: theme.palette.secondary.main, height: "22px", marginRight: "5px" }}
              />
            )}
            {row.parentScopeId && row.parentScopeId > 1 && (
              <SubdirectoryArrowRightIcon
                sx={{ height: "16px", mr: 1, color: theme.palette.primary.main, transform: { scaleX: -1, scaleY: -1 } }}
              />
            )}
            <b>{row.scopeName}</b>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          {row.datasets.length > 0 ? (
            <Box sx={{ display: "flex" }}>
              <InsertLinkOutlinedIcon sx={{ height: "22px", marginRight: "15px" }} /> <b>{row.datasets.length}</b>
            </Box>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  background: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #7098A7",
                  boxShadow: "0px 1px 1px rgba(183, 203, 211, 0.8), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                  borderRadius: "8px",
                  padding: "5px",
                }}
              >
                <InsertLinkOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
              </Box>
            </Box>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <b>{row.totalRoles}</b>
        </StyledTableCell>
        <StyledTableCell align="right">
          {rows.some((s) => s.id === row.parentScopeId && s.userPermission === 1) || authUser?.userRight === "ADMIN" ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 20px 5px 20px",
                }}
              >
                <IconButton
                  sx={{
                    color: theme.palette.secondary.main,
                    height: "22px",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={() => navigate("/scopes/" + row.id)}
                >
                  <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
                </IconButton>
              </Box>
              <TableDivider />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 5px 5px 20px",
                  // padding: "5px 20px 5px 20px",
                  // borderRight: "1px solid rgba(183, 203, 211, 0.7)",
                }}
              >
                <DeleteModal
                  type="scope"
                  handleClick={async () => {
                    await dispatch(deleteScope(row.id));
                  }}
                  pending={isPending}
                />
              </Box>
              {/* TODO show scopes details in list */}
              {/* <Box
              sx={{
                display: "flex",
                padding: "5px 5px 5px 20px",
                alignItems: "center",
              }}
            >
              <ExpandButton expand={expanded} onClick={handleExpandClick}>
                <ArrowDownIcon sx={{ color: theme.palette.secondary.main }} style={{ height: "16px", width: "16px" }} />
              </ExpandButton>
            </Box> */}
            </Box>
          ) : (
            <></>
          )}
        </StyledTableCell>
      </StyledTableRow>
      {/* TODO show scopes details in list */}
      {/* {expanded && (
        <TableRow>
          <TableCell style={{ backgroundColor: "white" }} colSpan={12}>
            <Collapse in={expanded}>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h1">Test</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )} */}
    </>
  );
};

export default TotalTableRow;
