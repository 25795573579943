import { listDatasets, listDatasetTables } from "@adapters/store/datasets/thunk";
import { RootState, ErrorState } from "@core/store/store";
import { errorHandler } from "@core/utils/ErrorHandler";
import { sortAsc } from "@core/utils/StringTools";
import { Dataset, DatasetTables } from "@domain/entities/Datasets";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface DatasetsState {
  list: Dataset[];
  tables: {
    pending: boolean;
    datasetId: number | null;
    list: DatasetTables[];
  };
  pending: boolean;
  error: ErrorState | null;
}

// Define the initial state using that type
const initialState: DatasetsState = {
  list: [],
  tables: {
    pending: false,
    datasetId: null,
    list: [],
  },
  pending: false,
  error: null,
};

export const datasetsSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    clearDatasetsError: (state: DatasetsState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for async actions with API here
    // LIST DATASETS
    builder.addCase(listDatasets.fulfilled, (state, action) => {
      state.list = action.payload.sort((a, b) => sortAsc(a.datasetName, b.datasetName));
      state.error = null;
      state.pending = false;
    });
    builder.addCase(listDatasets.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listDatasets");
      state.pending = false;
    });
    builder.addCase(listDatasets.pending, (state, _action) => {
      state.pending = true;
    });
    // LIST DATASET TABLES
    builder.addCase(listDatasetTables.fulfilled, (state, action) => {
      state.tables = {
        pending: false,
        datasetId: action.meta.arg,
        list: action.payload
          .sort((a, b) => sortAsc(a.tableName, b.tableName))
          .map((datasetTable) => ({
            ...datasetTable,
            columns: datasetTable.columns.sort((a, b) => sortAsc(a.columnName, b.columnName)),
          })),
      };
      state.error = null;
    });
    builder.addCase(listDatasetTables.rejected, (state, action) => {
      const error = action.payload as ErrorState;
      state.error = error;
      errorHandler(error, "listDatasetTables");
      state.tables.pending = false;
    });
    builder.addCase(listDatasetTables.pending, (state, _action) => {
      state.tables.pending = true;
    });
  },
});

// Define actions to be used in UI here
// export const { datasetAlreadyExists } = datasetsSlice.actions;

// Define selectors to be used in UI here
export const { clearDatasetsError } = datasetsSlice.actions;

export const getDatasetsCount = (state: RootState): number => state.datasets.list.length;
export const getDatasets = (state: RootState): Dataset[] => state.datasets.list;
export const getDatasetsError = (state: RootState): ErrorState | null => state.datasets.error;
export const getDatasetIsPending = (state: RootState): boolean => state.datasets.pending;
export const getDatasetTablesCount = (state: RootState): number => state.datasets.tables.list.length;
export const getDatasetTables = (state: RootState): DatasetTables[] => state.datasets.tables.list;
export const getDatasetTableIsPending = (state: RootState): boolean => state.datasets.tables.pending;
