import { httpClient } from "@adapters/api/HttpClient";
import { ScopeRequest } from "@domain/entities/Scopes";

export const scopesServices = {
  listScopes: async () => {
    return httpClient.get("/v1/scopes");
  },
  deleteScope: async (scopeId: number) => {
    return httpClient.delete(`/v1/scopes/${scopeId}`);
  },
  createScope: async (scope: ScopeRequest) => {
    return httpClient.post("/v1/scopes", scope);
  },
  updateScope: async (scope: ScopeRequest) => {
    return httpClient.put(`/v1/scopes/${scope.id}`, scope);
  },
};
