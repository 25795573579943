import { Maintenance, MaintenanceResponse } from "@domain/entities/Maintenance";
import dayjs from "dayjs";

export class MaintenanceResponseMapper {
  static toEntity({ endDate, id, informUser, startDate, status, title }: MaintenanceResponse): Maintenance {
    return {
      id,
      title,
      informUser,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      status,
    };
  }
}
