import { Box, DialogActions, DialogContent, Divider, IconButton, IconProps, Typography, useTheme } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import TotalButton from "@presentation/components/TotalButton";
import { useTranslation } from "react-i18next";
import { TotalModal } from "@presentation/components/TotalModal";

interface DialogProps extends IconProps {
  type: string;
  handleClick(): void;
  pending?: boolean;
  text?: string;
  disabled?: boolean;
}

const DeleteModal = ({ handleClick, text, type, sx, pending, disabled }: DialogProps): React.JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = (): void => {
    if (!pending) setOpen(true);
  };

  const handleValidate = (): void => {
    handleClick();
    setOpen(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const [iconColor, setIconColor] = useState(theme.palette.secondary.main);
  const [cursor, setCursor] = useState("pointer");
  useEffect(() => {
    setIconColor(!pending && !disabled ? theme.palette.secondary.main : "#8a9091");
    setCursor(!pending ? "pointer" : "wait");
  }, [pending, disabled, theme.palette.secondary.main]);

  const { t } = useTranslation();

  return (
    <Box>
      <IconButton
        sx={{ display: "flex", alignItems: "center" }}
        onClick={handleClickOpen}
        disabled={disabled}
        className="DeleteButton"
      >
        <DeleteOutlineOutlinedIcon sx={{ color: iconColor, height: "22px", cursor, ...sx }} />
        {text && <Typography variant="h6">{t("delete_modal.title", { type })}</Typography>}
      </IconButton>
      <TotalModal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        destructive
      >
        <DialogContent sx={{ mt: 3, mx: 5 }}>
          <Box
            id="alert-dialog-description"
            sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
          >
            <Typography variant="h1">{t("delete_modal.message", { type })}</Typography>
            <Typography variant="navButtonSelected" sx={{ color: theme.palette.secondary.main, mt: "16px" }}>
              {t("delete_modal.warning_message")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <Stack direction="row" spacing="24px">
            <TotalButton
              id="cancel-button"
              text={t("delete_modal.cancel")}
              icon="arrowLeft"
              height={12}
              iconColor={theme.palette.secondary.main}
              textColor={theme.palette.primary.main}
              buttonColor="white"
              borderColor={theme.custom?.lineColor?.main}
              onClick={handleClose}
              disabled={false}
            />
            <Divider orientation="vertical" flexItem></Divider>
            <TotalButton
              id="validate-button"
              text={t("delete_modal.confirm")}
              icon={!pending ? "check" : "load"}
              height={18}
              onClick={handleValidate}
              disabled={pending}
              primary
            />
          </Stack>
        </DialogActions>
      </TotalModal>
    </Box>
  );
};

export default DeleteModal;
