import Navbar from "@presentation/components/Navbar";
import { Box, Grid, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getIsAuthenticated } from "@adapters/store/users/slice";
import { useAppSelector } from "@core/store/hook";
import { history } from "@core/utils/History";
import React from "react";

const App = (): React.JSX.Element => {
  const loggedIn = useAppSelector(getIsAuthenticated);
  const location = useLocation();
  const theme = useTheme();

  history.navigate = useNavigate();
  history.location = location;

  return (
    <Box className="App">
      {loggedIn ? (
        <Box sx={{ display: "flex", flex: 1, minHeight: "100vh", flexDirection: "column" }}>
          <Navbar />
          <Grid className="ContentWrapper" container flex={1} flexDirection="row">
            <Grid
              className="ContentRight"
              item
              container
              md={9.5}
              lg={10.5}
              sx={{ background: theme.custom.colors.primary.neutral[10], minWidth: "100%" }}
            >
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default App;
