import { datasetsServices } from "@adapters/api/services/DatasetsServices";
import { Dataset, DatasetTables } from "@domain/entities/Datasets";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Define actions with API here
export const listDatasets = createAsyncThunk("datasets/list", async (_, { rejectWithValue }) => {
  try {
    const response = await datasetsServices.listDatasets();
    return response.data as Dataset[];
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const listDatasetTables = createAsyncThunk(
  "datasets/tables/list",
  async (datasetId: number, { rejectWithValue }) => {
    try {
      const response = await datasetsServices.listDatasetTables(datasetId);
      return response.data as DatasetTables[];
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
