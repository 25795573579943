import { store } from "@core/store/store";

export const adminRights = ["ADMIN"];
export const Un1Rights = ["UN1"];
export const Un1bRights = ["UN1B"];
export const Un2Rights = ["UN2"];

export const Un2AuthorizedPath = ["/home", "/authenticate", "/auth/callback", "/reports"];
export const Un1bAuthorizedPath = [...Un2AuthorizedPath, "/datasets"];

const defaultRight = "UN2";

const getUserRight = (): string | undefined => store.getState()?.users?.authUser?.user?.userRight;

export const isAdmin = (right?: string): boolean => {
  right = right ?? getUserRight();
  return adminRights.includes((right ?? defaultRight).toUpperCase());
};

export const isUN1 = (right?: string): boolean => {
  right = right ?? getUserRight();
  return Un1Rights.includes((right ?? defaultRight).toUpperCase());
};

export const isUN1B = (right?: string): boolean => {
  right = right ?? getUserRight();
  return Un1bRights.includes((right ?? defaultRight).toUpperCase());
};

export const isUN2 = (right?: string): boolean => {
  right = right ?? getUserRight();
  return Un2Rights.includes((right ?? defaultRight).toUpperCase());
};

export const isUriAuthorized = (pathname: string, right?: string): boolean => {
  if (isUN2(right)) {
    return Un2AuthorizedPath.some((v) => pathname.includes(v));
  } else if (isUN1B(right)) {
    return Un1bAuthorizedPath.some((v) => pathname.includes(v));
  }
  return true;
};
