import Order from "@domain/Interfaces/Order.d";
import { useState } from "react";

export interface StoredPagination {
  page: number;
  order?: Order;
  orderBy?: keyof any;
  search?: string;
  onlyFavorite?: boolean;
}

const STORAGE_KEY_PREFIX = "storedPagination_";

const useStoredPagination = (
  pageKey: string,
  initialPagination: StoredPagination
): [StoredPagination, (pagination: StoredPagination) => void] => {
  const getInitialPagination = (): StoredPagination => {
    const storedPagination = sessionStorage.getItem(`${STORAGE_KEY_PREFIX}${pageKey}`);
    try {
      return storedPagination ? JSON.parse(storedPagination) : initialPagination;
    } catch (error) {
      console.error(`Error parsing stored pagination for ${pageKey}:`, error);
      return initialPagination;
    }
  };

  const [storedPagination, setStoredPagination] = useState<StoredPagination>(getInitialPagination);

  const updateStoredPagination = (newPagination: StoredPagination): void => {
    setStoredPagination(newPagination);
    sessionStorage.setItem(`${STORAGE_KEY_PREFIX}${pageKey}`, JSON.stringify(newPagination));
  };

  return [storedPagination, updateStoredPagination];
};

export default useStoredPagination;
