import TotalRolesTable from "@presentation/components/roles/TotalRolesTable";
import { RoleHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getRoles } from "@adapters/store/roles/slice";
import { useEffect, useState } from "react";
import { listRoles } from "@adapters/store/roles/thunk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";

const Roles = (): JSX.Element => {
  const { t } = useTranslation();
  const headCells: RoleHeadCell[] = [
    {
      id: "roleName",
      numeric: false,
      disablePadding: true,
      label: t("datasets_roles.headers.role"),
      enableSorting: true,
    },
    {
      id: "datasetName",
      numeric: false,
      disablePadding: false,
      label: t("datasets_roles.headers.dataset"),
      enableSorting: true,
    },
    {
      id: "scopesLength",
      numeric: true,
      disablePadding: false,
      label: t("datasets_roles.headers.scopes"),
      enableSorting: true,
    },
    {
      id: "filtersLength",
      numeric: true,
      disablePadding: false,
      label: t("datasets_roles.headers.values"),
      enableSorting: false,
    },
  ];

  const dispatch = useAppDispatch();

  const rows = useAppSelector(getRoles);

  useEffect(() => {
    dispatch(listRoles());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("datasets_roles.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("datasets_roles.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
      </Box>

      <TotalRolesTable headCells={headCells} rows={rows}></TotalRolesTable>
    </Grid>
  );
};

export default Roles;
