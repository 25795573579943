import { useEffect, useState } from "react";
import CollapsibleCard from "@presentation/components/CollapsibleCard";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Link,
  Breadcrumbs,
  useTheme,
  InputAdornment,
  Alert,
} from "@mui/material";
import TextField from "@presentation/components/inputs/TextField";
import ArrowLeftIcon from "@presentation/components/Icons/ArrowLeftIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { useTranslation } from "react-i18next";
import { TextFieldData } from "@presentation/components/inputs/TextFieldData";
import DateSelection from "@presentation/components/inputs/DateSelection";
import dayjs from "dayjs";
import { StarFilledIcon } from "@presentation/components/Icons/StartFilledIcon";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import TotalTooltip from "@presentation/components/TotalTooltip";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Report } from "@domain/entities/Reports";
import { getFavoriteReports, getReports, getReportsIsPending } from "@adapters/store/reports/slice";
import { getFavorite, listReports } from "@adapters/store/reports/thunk";
import { stringComparatorWithFormatter } from "@core/utils/StringTools";
import TotalButton from "@presentation/components/TotalButton";
import { uniq } from "@core/utils/ArrayTools";
import { listSubscriptions, updateSubscription } from "@adapters/store/subscriptions/thunk";
import {
  getSubscriptions,
  getSubscriptionsError,
  getSubscriptionsIsPending,
} from "@adapters/store/subscriptions/slice";
import SummarySubscription from "@presentation/components/subscriptions/SummarySubscriptionCreateUpdate";
import { Subscription } from "@domain/entities/Subscriptions";
import { clearUsersError } from "@adapters/store/users/slice";
import TotalToggle from "@presentation/components/TotalToggle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ViewSubscription = (): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State variables
  const [subscriptionName, setSubscriptionName] = useState("");
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().add(6, "month"));
  const [regularity, setRegularity] = useState<string>("");
  const [searchReport, setSearchReport] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<number[]>([]);
  const reportsIsPending = useAppSelector(getReportsIsPending);
  const reportsList = useAppSelector(getReports);
  const favorite = useAppSelector(getFavoriteReports);
  const [filteredReports, setFilteredReports] = useState<Report[]>(reportsList);
  const error = useAppSelector(getSubscriptionsError);

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const subscriptionsList = useAppSelector(getSubscriptions);
  const searchParams = useParams();
  const [mounted, setMounted] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(true);
  const pending = useAppSelector(getSubscriptionsIsPending);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const path = "/subscriptions";

  const maxEndDate = dayjs().add(6, "month").endOf("day");
  const maxStartDate = maxEndDate.subtract(1, "day");

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit">
      {t("navbar.managment")}
    </Link>,
    <Link underline="hover" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
      {t("navbar.manage_subscriptions")}
    </Link>,
    <Link underline="none" key="1" color="inherit">
      {t("subscription_create_update.create_title")}
    </Link>,
  ];

  // Handlers for state changes
  const handleSubscriptionNameChange = (value: string): void => {
    setSubscriptionName(value);
  };

  const handleStartDateChange = (value: dayjs.Dayjs | null): void => {
    if (value) setStartDate(value);
  };

  const handleEndDateChange = (value: dayjs.Dayjs | null): void => {
    if (value) setEndDate(value);
  };

  const handleRegularityChange = (_event: React.MouseEvent<HTMLElement>, value: string | null): void => {
    if (value) {
      setRegularity(value);
    }
  };

  const handleSearchReportChange = (value: string): void => {
    setSearchReport(value);
  };

  const toggleCheckSingleColumn = (columnId: number): void => {
    let newArr;
    if (selectedReports.includes(columnId)) {
      newArr = selectedReports.filter((id) => id !== columnId);
    } else {
      newArr = [...selectedReports];
      newArr.push(columnId);
    }
    setSelectedReports(newArr);
  };

  const handleSubmit = async (): Promise<void> => {
    if (reportsIsPending) return;
    await dispatch(
      updateSubscription({
        id: Number(searchParams.id),
        subscriptionName: subscriptionName.trim(),
        startDate: startDate ? startDate.startOf("day").toISOString() : "",
        endDate: endDate ? endDate.endOf("day").toISOString() : "",
        regularity,
        reports: uniq(selectedReports),
      })
    );
    dispatch(listSubscriptions());
    if (!error) navigate(path);
  };

  useEffect(() => {
    if (searchParams.id) {
      setSubscription(subscriptionsList.find((s) => s.id === Number(searchParams.id)) ?? null);
    } else {
      setSubscription(null);
    }
  }, [subscriptionsList, searchParams.id]);

  useEffect(() => {
    dispatch(listReports());
    dispatch(getFavorite());
    dispatch(listSubscriptions());
    setFilteredReports(reportsList);
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (reportsIsPending) return;
    const filteredRows = reportsList.filter((row) => stringComparatorWithFormatter(row.reportName, searchReport));
    setFilteredReports(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsList, searchReport]);

  useEffect(() => {
    if (!subscription) return;
    setSubscriptionName(subscription.subscriptionName);
    setStartDate(subscription.startDate);
    setEndDate(subscription.endDate);
    setRegularity(subscription.regularity);
    setSelectedReports(subscription.reports.map((r) => r.id));
  }, [subscription]);

  useEffect(() => {
    if (pending || !mounted) {
      setExist(true);
      return;
    }
    setExist(!!subscription);
  }, [pending, subscription, mounted]);

  const handleFormIsValid = (): boolean => {
    if (isBusy) return false;
    setIsBusy(true);
    const result =
      !!subscriptionName.trim() &&
      startDate <= maxStartDate &&
      endDate <= maxEndDate &&
      startDate <= endDate &&
      (regularity === "EVERY_WEEK" || regularity === "EVERY_OTHER_WEEK" || regularity === "EVERY_MONTH");
    setIsBusy(false);
    return result;
  };

  useEffect(() => {
    setIsValid(handleFormIsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionName, startDate, endDate, regularity, selectedReports]);

  return (
    <Grid container direction="column" alignItems="start" sx={{ px: 2, mt: 1, mb: 2 }}>
      <Grid container item alignItems="center" direction="row">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon sx={{ color: theme.palette.secondary.main }} />}
          onClick={() => navigate(path)}
        >
          <Typography variant="backButton" noWrap={true} sx={{ color: theme.palette.secondary.main }}>
            {t("common.back")}
          </Typography>
        </Button>
        <Divider orientation="vertical" sx={{ mx: 1, mr: 2, height: 16 }} />
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      {error && (
        <Alert severity="error" onClose={() => dispatch(clearUsersError())} sx={{ my: 2 }}>
          {error?.message}
        </Alert>
      )}
      {!exist && (
        <Alert severity="error" sx={{ my: 2, width: "100%" }}>
          {"Subscription does not exist"}
        </Alert>
      )}

      {subscription && (
        <>
          <Typography sx={{ my: "28px", color: "#374649" }} variant="h2">
            {t("subscription_create_update.update_title")}
          </Typography>

          <Grid container item direction="row" justifyContent="end" alignItems="end">
            <TotalButton
              id="save-new-subscription-button"
              text={t("subscription_create_update.update_subscription")}
              icon=""
              height={22}
              disabled={!isValid}
              onClick={handleSubmit}
            />
          </Grid>

          {/* Subscription Name */}
          <TextFieldData
            id="subscription-name"
            label={t("subscription_create_update.subscription_name")}
            value={subscriptionName}
            error={!subscriptionName.trim()}
            onChange={handleSubscriptionNameChange}
            width="300px"
          />

          {/* Subscription Duration and Frequency */}
          <CollapsibleCard
            title={t("subscription_create_update.1_duration_frequency")}
            sx={{ mt: 2 }}
            isExpanded={true}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
              {/* Start Date */}
              <DateSelection
                label={t("subscription_create_update.start_date_input")}
                date={startDate}
                maxDate={maxStartDate}
                error={startDate > endDate || startDate > maxStartDate}
                onDateChange={handleStartDateChange}
                width="31%"
              />

              {/* End Date */}
              <DateSelection
                label={t("subscription_create_update.end_date_input")}
                date={endDate}
                minDate={startDate}
                maxDate={maxEndDate}
                error={endDate < startDate || endDate > maxEndDate}
                onDateChange={handleEndDateChange}
                width="31%"
                /*error={endDateError}*/
              />

              {/* Frequency */}
              <TotalToggle
                id="regularity"
                label={t("subscription_create_update.regularity_input")}
                buttons={[
                  {
                    value: "EVERY_WEEK",
                    label: t("subscription_create_update.regularity_every_week"),
                    selected: regularity === "EVERY_WEEK",
                  },
                  {
                    value: "EVERY_OTHER_WEEK",
                    label: t("subscription_create_update.regularity_every_other_week"),
                    selected: regularity === "EVERY_OTHER_WEEK",
                  },
                  {
                    value: "EVERY_MONTH",
                    label: t("subscription_create_update.regularity_every_month"),
                    selected: regularity === "EVERY_MONTH",
                  },
                ]}
                onChange={handleRegularityChange}
                value={regularity}
                helpText={t("subscription_create_update.regularity_tooltip")}
              />
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "8px" }}>
              <InfoOutlinedIcon
                sx={{ height: "16px", color: endDate > maxEndDate || startDate > maxStartDate ? "red" : undefined }}
              />
              <Typography
                variant="body2"
                sx={{ color: endDate > maxEndDate || startDate > maxStartDate ? "red" : undefined }}
              >
                {t("subscription_create_update.info_end_date")}
              </Typography>
            </Box>
          </CollapsibleCard>

          {/* Reports included in subscription */}
          <CollapsibleCard title={t("subscription_create_update.2_reports_linked")} sx={{ mt: "8px" }}>
            <TextField
              id="search-report"
              label={t("subscription_create_update.2_description")}
              value={searchReport}
              onChange={handleSearchReportChange}
              width="30%"
              endAdornment={
                <InputAdornment position="end">
                  <SearchRoundedIcon color="secondary" />
                </InputAdornment>
              }
            />
            {filteredReports && (
              <Box sx={{ display: "flex", flexWrap: "wrap", maxHeight: "300px", justifyContent: "space-between" }}>
                {filteredReports.map((report: Report) => {
                  return (
                    <TotalTooltip key={report.id} title="" placement="bottom">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "48%",
                          mt: 2,
                          borderBottom: 1,
                          height: "39px",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleCheckSingleColumn(report.id)}
                      >
                        <Box>
                          <TotalCheckbox
                            checked={selectedReports.includes(report.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": report.id + "" }}
                          />
                        </Box>
                        {favorite.includes(report.id) && (
                          <StarFilledIcon
                            sx={{
                              color: theme.custom.colors.brand.primary[500],
                              height: "14px",
                              my: "auto",
                              mr: "4px",
                              display: "flex",
                            }}
                          />
                        )}
                        <Typography variant="h6" sx={{ userSelect: "none", transform: "translateY(1px)" }}>
                          {report.reportName}
                        </Typography>
                      </Box>
                    </TotalTooltip>
                  );
                })}
              </Box>
            )}
          </CollapsibleCard>

          {/* Summary - New subscription settings */}
          <CollapsibleCard title="3 / Summary - New subscription settings" sx={{ mt: 2 }}>
            <SummarySubscription
              startDate={startDate as dayjs.Dayjs}
              endDate={endDate as dayjs.Dayjs}
              regularity={regularity}
              selectedReports={selectedReports}
              reportsList={reportsList}
            />
          </CollapsibleCard>
        </>
      )}
    </Grid>
  );
};

export default ViewSubscription;
