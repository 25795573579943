import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Fade,
  IconButton,
  IconProps,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SwitchButton from "@presentation/components/navbar/SwitchButton";
import { useTranslation } from "react-i18next";

interface DialogProps extends IconProps {
  pending?: boolean;
  text?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchModal = (props: DialogProps): JSX.Element => {
  const theme = useTheme();
  const { open, setOpen } = props;

  const { t } = useTranslation();

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Fade}
      maxWidth="md"
      fullWidth
    >
      <Box sx={{ m: "24px", position: "relative" }}>
        <DialogTitle sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              alignSelf: "stretch",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: theme.palette.secondary.main,
                padding: "0px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            id="alert-dialog-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Typography variant="h1">{t("switch.select_module_to_switch")}</Typography>
            <Typography variant="navButtonSelected" sx={{ color: theme.palette.primary.main }}>
              {t("switch.module_openning_action")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogActions sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              maxWidth: "75%",
              gap: "24px",
            }}
          >
            <SwitchButton platformName={t("switch.kiosk.name")} current={true} tooltip={t("switch.kiosk.tooltip")} />
            <SwitchButton
              platformName={t("switch.customer_portal.name")}
              current={false}
              tooltip={t("switch.customer_portal.tooltip")}
              url={process.env.REACT_APP_COCKPIT_URL}
            />
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SwitchModal;
