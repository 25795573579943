import { Box, IconButton, TableRowProps, useTheme } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteModal from "@presentation/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { deleteUser } from "@adapters/store/users/thunk";
import { getUsersIsPending } from "@adapters/store/users/slice";
import StyledTableRow from "@presentation/components/StyledTableRow";
import StyledTableCell from "@presentation/components/StyledTableCell";
import { User } from "@domain/entities/Users";
import TableDivider from "@presentation/components/table/TableDivider";

interface ITotalUsersRow extends TableRowProps {
  row: User;
  highlight?: boolean;
  labelId: string;
}

const TotalTableRow = ({ row, highlight }: ITotalUsersRow): JSX.Element => {
  const theme = useTheme();

  const isPending = useAppSelector(getUsersIsPending);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <StyledTableRow className="Row" hover>
      <StyledTableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {highlight && (
            <WarningAmberOutlinedIcon
              sx={{ color: theme.palette.secondary.main, height: "22px", marginRight: "5px" }}
            />
          )}
          <b>{row.profile.lastName}</b>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">{row.profile.firstName}</StyledTableCell>
      <StyledTableCell align="center">{row.profile.organisation}</StyledTableCell>
      <StyledTableCell align="center">{row.scopesLength ? row.scopesLength : "--"}</StyledTableCell>
      <StyledTableCell align="center">{row.groupsLength ? row.groupsLength : "--"}</StyledTableCell>
      <StyledTableCell align="center">{row.reportsLength ? row.reportsLength : "--"}</StyledTableCell>
      <StyledTableCell align="right">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 20px 5px 20px",
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.secondary.main,
                height: "22px",
                "&:hover": {
                  background: "none",
                },
              }}
              onClick={() => navigate("/users/" + row.id)}
            >
              <EditOutlinedIcon sx={{ color: theme.palette.secondary.main, height: "22px" }} />
            </IconButton>
          </Box>
          <TableDivider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 5px 5px 20px",
            }}
          >
            <DeleteModal
              type="user"
              handleClick={async () => {
                await dispatch(deleteUser(row.id));
              }}
              pending={isPending}
              disabled={!row.canDelete}
            />
          </Box>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TotalTableRow;
