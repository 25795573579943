import { Box, Card, CardActions, CardContent, Grid, Collapse, Typography, Divider, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";
import React, { useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Report } from "@domain/entities/Reports";
import ExpandButton from "@presentation/components/ExpandButton";
import { useTranslation } from "react-i18next";
import TotalCheckbox from "@presentation/components/TotalCheckbox";
import { getFavoriteReports } from "@adapters/store/reports/slice";
import { StarFilledIcon } from "@presentation/components/Icons/StartFilledIcon";
import { useAppSelector } from "@core/store/hook";

interface DatasetCollapsibleTableProps extends BoxProps {
  tableId: number;
  title: string | undefined;
  items: Report[];
  checkedItems: number[];
  toggleCheckColumn: (tableId: number, columnId: number) => void;
  toggleCheckAll: (tableId: number) => void;
}

const ReportCollapsibleTable = (props: DatasetCollapsibleTableProps): React.JSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const favorite = useAppSelector(getFavoriteReports);
  const { title, items, tableId, checkedItems, toggleCheckColumn, toggleCheckAll } = props;

  const { t } = useTranslation();

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ width: "48%", mt: 2, mx: 1 }}>
      <Card
        sx={{
          borderRadius: "16px",
          backgroundColor: theme.custom?.tabLineColor?.main,
          border: 1,
          borderColor: theme.custom?.lineColor?.main,
        }}
      >
        <CardContent sx={{ height: "100%", p: 0 }}>
          <Grid container item>
            <Grid container item alignItems="center" direction="row">
              <TotalCheckbox
                sx={{ padding: "12px" }}
                checked={checkedItems.length > 0 && items.filter((i) => !checkedItems.includes(i.id)).length === 0}
                onClick={() => toggleCheckAll(tableId)}
                indeterminate={
                  checkedItems.length > 0 &&
                  items.filter((i) => checkedItems.includes(i.id)).length > 0 &&
                  items.filter((i) => checkedItems.includes(i.id)).length !== items.length
                }
                disabled={items.length === 0}
              />
              <Typography variant="h6">
                {t("report_collapsible.reports_from_dataset", { datasetName: title })}
              </Typography>
              <ExpandButton expand={expanded} onClick={handleExpandClick}>
                <ExpandCircleDownIcon
                  sx={{
                    color: "#ffffff",
                    boxShadow: "0px 1px 1px rgba(112, 152, 167, 0.64), 0px 6px 8px rgba(183, 203, 211, 0.32)",
                    backgroundColor: "#7098A7",
                    borderRadius: "15px",
                    border: 0,
                    height: "25px",
                    width: "auto",
                  }}
                  viewBox="1 1 22 22"
                />
              </ExpandButton>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded}>
          <Divider sx={{ bgcolor: theme?.custom?.lineColor?.main }} variant="middle" />
          <CardContent>
            {items.length > 0 ? (
              items.map((report: Report) => {
                const labelId = `transfer-list-all-item-${report.id}-label`;
                return (
                  <Grid container direction="row" key={report.id + "-" + report.reportName}>
                    <Grid
                      container
                      direction="row"
                      item
                      alignItems="center"
                      role="listitem"
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      onClick={() => toggleCheckColumn(tableId, report.id)}
                    >
                      <Box>
                        <TotalCheckbox
                          checked={checkedItems.length > 0 && checkedItems.includes(report.id)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </Box>
                      {favorite.includes(report.id) && (
                        <StarFilledIcon
                          sx={{
                            color: theme.custom.colors.brand.primary[500],
                            height: "14px",
                            my: "auto",
                            mr: "4px",
                            display: "flex",
                          }}
                        />
                      )}
                      <Typography id={labelId} variant="body2" sx={{ transform: "translateY(1px)" }}>
                        {report.reportName}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid container direction="row">
                <Grid container direction="row" item alignItems="center" role="listitem">
                  <Box>
                    <TotalCheckbox disabled={true} disableRipple />
                  </Box>
                  <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                    {t("report_collapsible.no_report_available")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
          <CardActions></CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default ReportCollapsibleTable;
