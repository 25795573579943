import Edit from "@presentation/components/Icons/AdminIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Chip, Typography, useTheme } from "@mui/material";

const UserRoleTag = (props: any): JSX.Element => {
  const theme = useTheme();
  const { role, user } = props;
  const permissions = [
    { role: 4, icon: <PersonOutlinedIcon />, color: theme.custom?.chip?.user?.color },
    { role: 3, icon: <VisibilityOutlinedIcon />, color: theme.custom?.chip?.readOnly?.color },
    { role: 2, icon: <Edit />, color: theme.custom?.chip?.readAndEdit?.color },
    { role: 1, icon: <SettingsOutlinedIcon />, color: theme.custom?.chip?.admin?.color },
  ];
  return (
    <Chip
      icon={permissions.find((x) => role === x.role)?.icon}
      sx={{
        backgroundColor: permissions.find((x) => role === x.role)?.color,
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        flexWrap: "nowrap",
        py: "4px",
        px: "8px",
        ...props?.sx,
      }}
      label={<Typography variant="h6">{user}</Typography>}
    />
  );
};

export default UserRoleTag;
