import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CheckIcon from "@presentation/components/Icons/CheckIcon";
import CountryIcon from "@presentation/components/Icons/CountryIcon";
import { useTranslation } from "react-i18next";

type LanguageSelectionProps = {
  code: string;
  label: string;
};

const LanguageSelection = (props: LanguageSelectionProps): JSX.Element => {
  const theme = useTheme();
  const { code, label } = props;
  const { i18n, t } = useTranslation();

  const current = i18n.language === code;

  const onClick = (): void => {
    i18n.changeLanguage(code);
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        background: "white",
        color: theme.palette?.primary?.main,
        border: 1,
        borderColor: current ? "white" : "#7098A7",
        px: "8px",
        marginTop: "8px",
        py: "8px",
        width: "167px",
        backgroundColor: current ? "#EFF4F5" : "white",
        cursor: !current ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "flex-start",
          alignSelf: "stretch",
          padding: "8px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", my: "auto" }}>
          {!current && (
            <Typography variant="h6" noWrap={true}>
              {t("language.set_language_to")}
            </Typography>
          )}
          <Typography
            variant="navButtonSelected"
            noWrap={true}
            sx={{ color: current ? theme.palette.primary.main : theme.palette.secondary.main }}
          >
            {label}
          </Typography>
        </Box>
        <IconButton sx={{ p: 0, marginLeft: "auto", display: "flex", my: "auto" }} disabled={current}>
          {current ? (
            <CheckIcon sx={{ height: "32px" }} />
          ) : (
            <Box
              sx={{
                border: theme.custom.modal.border.flagLangage,
                borderRadius: "12%",
                height: "28px",
                width: "40px",
                overflow: "hidden",
              }}
            >
              <CountryIcon countryCode={code} height="28.6px" width="40px" />
            </Box>
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default LanguageSelection;
