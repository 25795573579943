import { Box, BoxProps, Divider, IconButton, Typography, useTheme } from "@mui/material";
import CheckIcon from "@presentation/components/Icons/CheckIcon";
import ExternalLinkIcon from "@presentation/components/Icons/ExternalLinkIcon";
import TotalTooltip from "@presentation/components/TotalTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ISwitchButtonProps extends BoxProps {
  platformName: string;
  current: boolean;
  url?: string;
  tooltip: string;
}

const SwitchButton = (props: ISwitchButtonProps): JSX.Element => {
  const theme = useTheme();
  const { platformName, current, tooltip, url, ...otherButtonsProps } = props;

  const redirection = (): void => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        background: "white",
        color: theme.palette?.primary?.main,
        border: 1,
        borderColor: current ? "white" : "#7098A7",
        px: "8px",
        marginTop: "8px",
        py: "8px",
        width: "167px",
        backgroundColor: current ? "#EFF4F5" : "white",
        cursor: !current ? "pointer" : "default",
      }}
      onClick={redirection}
      {...otherButtonsProps}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "flex-start",
          alignSelf: "stretch",
          padding: "8px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", alignItems: "flex-start" }}>
          <Typography variant="h6" noWrap={true}>
            {current ? "Currently on" : "Switch to"}
          </Typography>
          <Typography
            variant="navButtonSelected"
            noWrap={true}
            sx={{ color: current ? theme.palette.primary.main : theme.palette.secondary.main }}
          >
            {platformName}
          </Typography>
        </Box>
        <IconButton sx={{ p: 0, marginLeft: "auto", display: "flex" }} disabled={current}>
          {current ? <CheckIcon sx={{ height: "32px" }} /> : <ExternalLinkIcon sx={{ height: "32px" }} />}
        </IconButton>
      </Box>
      <Box
        sx={{ mt: "8px", px: "8px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px" }}
      >
        <Divider color="#B7CBD3" sx={{ height: "1px", width: "100%" }} />
        <TotalTooltip title={tooltip}>
          <Typography
            variant="h6"
            sx={{ textDecorationLine: "underline", display: "flex", flexDirection: "row", gap: "4px" }}
          >
            <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            <Box sx={{ display: "flex", my: "auto" }}>{`What is ${platformName} for ?`}</Box>
          </Typography>
        </TotalTooltip>
      </Box>
    </Box>
  );
};

export default SwitchButton;
