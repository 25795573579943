import { toast, ToastOptions, TypeOptions } from "react-toastify";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type TShowToastOpts = {
  opts: ToastOptions;
  type: TypeOptions;
};

/**
 * Will add props only if not exist on base object
 * @param object
 * @param toAdd
 * @returns
 */
const addProps = (object: any, toAdd: any): any => {
  return Object.assign(object, { ...toAdd, ...object });
};

/**
 * Will show a toast
 * @param msg
 * @param options optional, contains toaster options like type, style and more... The type "info" is considered as "default".
 */
export const showToast = (msg: string, options: Partial<TShowToastOpts> = {}): void => {
  const { type, opts } = {
    type: options.type ?? "default",
    opts: {
      className: "toast",
      ...options.opts,
    },
  } as TShowToastOpts;

  switch (type) {
    case "warning":
      addProps(opts, {
        icon: <WarningOutlinedIcon />,
        bodyClassName: "toast-body toast-warning",
      });
      break;
    case "error":
      addProps(opts, {
        icon: <ErrorOutlineIcon />,
        bodyClassName: "toast-body toast-error",
        autoClose: 5000,
      });
      break;
    case "success":
      addProps(opts, {
        icon: <CheckCircleIcon />,
        bodyClassName: "toast-body toast-success",
      });
      break;
    case "default":
      addProps(opts, {
        icon: <InfoOutlinedIcon />,
        bodyClassName: "toast-body",
      });
      break;
  }

  toast(<div className="toast-text">{msg}</div>, opts);
};

/**
 * Short call for showToast
 */
export const toaster = {
  success: (msg: string): void => {
    showToast(msg, { type: "success" });
  },
  error: (msg: string): void => {
    showToast(msg, { type: "error" });
  },
  warn: (msg: string): void => {
    showToast(msg, { type: "warning" });
  },
  info: (msg: string): void => {
    showToast(msg);
  },
};
