import { SvgIcon, SvgIconProps } from "@mui/material";

const CheckboxCheckedIcon = (props: SvgIconProps): React.JSX.Element => (
  <SvgIcon {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="16" height="16" rx="3" fill="white" />
      <path
        d="M5 9.1L7.28571 11.5L13 5.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="1" width="16" height="16" rx="3" stroke="currentColor" strokeWidth="2" />
    </svg>
  </SvgIcon>
);

export default CheckboxCheckedIcon;
