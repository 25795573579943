import { InputProps, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LegalFilesListProps extends InputProps {
  fontSize: string;
}

const LegalFilesList = (props: LegalFilesListProps): JSX.Element => {
  const { fontSize } = props;

  const { t } = useTranslation();

  const cguList = [
    { href: t("legal.gtcu_path"), itemName: t("legal.gtcu_title") },
    { href: t("legal.pdcc_path"), itemName: t("legal.pdcc_title") },
  ];

  return (
    <List dense>
      {cguList.map(({ href, itemName }) => (
        <ListItem dense component="a" key={itemName} href={href} target="_blank" sx={{ fontSize: fontSize || "12px" }}>
          {`- ${itemName}`}
        </ListItem>
      ))}
    </List>
  );
};

export default LegalFilesList;
