import { httpClient } from "@adapters/api/HttpClient";
import {
  SubscriptionCreateRequest,
  SubscriptionIdsList,
  SubscriptionResponse,
  SubscriptionUpdateRequest,
} from "@domain/entities/Subscriptions";
import { AxiosResponse } from "axios";

export const subscriptionsServices = {
  listSubscriptions: async (): Promise<AxiosResponse<SubscriptionResponse[]>> => {
    return httpClient.get("/v1/subscriptions");
  },
  getSubscription: async (subscriptionId: number): Promise<AxiosResponse<SubscriptionResponse>> => {
    return httpClient.get(`/v1/subscriptions/${subscriptionId}`);
  },
  createSubscription: async (subscription: SubscriptionCreateRequest): Promise<AxiosResponse<SubscriptionResponse>> => {
    return httpClient.post<SubscriptionCreateRequest, SubscriptionResponse>("/v1/subscriptions", subscription);
  },
  updateSubscription: async (subscription: SubscriptionUpdateRequest): Promise<AxiosResponse<SubscriptionResponse>> => {
    return httpClient.put<SubscriptionUpdateRequest, SubscriptionResponse>(
      `/v1/subscriptions/${subscription.id}`,
      subscription
    );
  },
  deleteSubscription: async (subscriptionId: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete(`/v1/subscriptions/${subscriptionId}`);
  },
  updateReportLinkedSubscriptions: async (
    reportId: number,
    subscriptionIds: number[]
  ): Promise<AxiosResponse<void>> => {
    return httpClient.post<SubscriptionIdsList, void>(`/v1/reports/${reportId}/updateSubscriptions`, {
      subscriptionIds: subscriptionIds,
    });
  },
};
