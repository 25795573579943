import AppRouter from "@core/router/Router";
import { store } from "@core/store/store";
import light from "@core/themes/light";
import { ThemeProvider } from "@emotion/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { StoreContext } from "redux-react-hook";

const AppContainer = (): React.JSX.Element => {
  const theme = light;
  return (
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={store}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <AppRouter />
              <ToastContainer
                position="bottom-left"
                transition={cssTransition({
                  collapse: false,
                  enter: "animate__superfast animate__animated animate__backInLeft",
                  exit: "animate__superfast animate__animated animate__backOutLeft",
                })}
                autoClose={4500}
                limit={5}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                closeButton={false}
              />
            </BrowserRouter>
          </LocalizationProvider>
        </Provider>
      </StoreContext.Provider>
    </ThemeProvider>
  );
};

export default AppContainer;
